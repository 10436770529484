import { defineMessages } from "react-intl";

export const navigation = defineMessages({
  account: {
    id: "navigation.consumer.account",
    defaultMessage: "Profilo",
  },
  actionForm: {
    id: "navigation.actionForm",
    defaultMessage: "Elaborazione QRcode",
  },
  appConsumer: {
    id: "navigation.appConsumer",
    defaultMessage: "Accedi come consumatore",
  },
  actionMerchant: {
    id: "navigation.consumer.actionMerchant",
    defaultMessage: "Interazioni",
  },
  appManager: {
    id: "navigation.appManager",
    defaultMessage: "Accedi come punto vendita",
  },
  assistance: {
    id: "navigation.consumer.assistance",
    defaultMessage: "Assistenza",
  },
  backToCampaigns: {
    id: "navigation.backToCampaigns",
    defaultMessage: "Torna alle campagne",
  },
  bookTour: {
    id: "navigation.bookTour",
    defaultMessage: "Prenota una visita",
  },
  campaigns: {
    id: "navigation.manager.campaigns",
    defaultMessage: "Campagne",
  },
  campfireFaq: {
    id: "navigation.consumer.campfireFaq",
    defaultMessage: "Campfire FAQ",
  },
  campfireStats: {
    id: "navigation.manager.campfireStats",
    defaultMessage: "Campfire stats",
  },
  checkin: {
    id: "navigation.checkin",
    defaultMessage: "Checkin",
  },
  consentManagement: {
    id: "navigation.manager.consentManagement",
    defaultMessage: "Gestione Consensi",
  },
  dashboard: {
    id: "navigation.manager.dashboard",
    defaultMessage: "Cruscotto",
  },
  distributionList: {
    id: "navigation.manager.distributionList",
    defaultMessage: "Liste di distribuzione",
  },
  employeeAccess: {
    id: "component.manager.employeeAccess",
    defaultMessage: "Autorizzazioni dipendenti",
  },
  enter: {
    id: "component.signin.title",
    defaultMessage: "Accedi",
  },
  eProducts: {
    id: "navigation.products",
    defaultMessage: "Prodotti ordinabili",
  },
  export: {
    id: "navigation.manager.export",
    defaultMessage: "Esporta",
  },
  events: {
    id: "navigation.manager.events",
    defaultMessage: "Eventi",
  },
  fair: {
    id: "common.fair",
    defaultMessage: "Fiere",
  },
  faq: {
    id: "common.faq",
    defaultMessage: "FAQ",
  },
  friendCode: {
    id: "common.friendCode",
    defaultMessage: "Invita un amico",
  },
  goHome: {
    id: "common.goHome",
    defaultMessage: "Vai alla home",
  },
  home: {
    id: "navigation.consumer.home",
    defaultMessage: "Home",
  },
  locations: {
    id: "navigation.manager.locations",
    defaultMessage: "Luoghi",
  },
  logout: {
    id: "navigation.logout",
    defaultMessage: "Logout",
  },
  loops: {
    id: "navigation.manager.loops",
    defaultMessage: "Circuiti",
  },
  lots: {
    id: "navigation.manager.lots",
    defaultMessage: "Assegnazione lotti",
  },
  myLocation: {
    id: "navigation.myLocation",
    defaultMessage: "Intorno a me",
  },
  newScan: {
    id: "component.formManager.newScan",
    defaultMessage: "Nuova scansione",
  },
  notifications: {
    id: "navigation.notifications",
    defaultMessage: "Notifiche",
  },
  operator: {
    id: "navigation.operator",
    defaultMessage: "Dati account",
  },
  userData: {
    id: "navigation.userData",
    defaultMessage: "Dati utente",
  },
  prizes: {
    id: "navigation.manager.prizes",
    defaultMessage: "Assegnazione premi",
  },
  pushNotifications: {
    id: "navigation.pushNotifications",
    defaultMessage: "Notifiche Push",
  },
  qrcode: {
    id: "navigation.consumer.qrcode",
    defaultMessage: "Il mio QR Code",
  },
  reporting: {
    id: "navigation.manager.reporting",
    defaultMessage: "Rendicontazione",
  },
  reportingOrders: {
    id: "navigation.manager.reportingOrders",
    defaultMessage: "Rendicontazione Ordini",
  },
  reservations: {
    id: "navigation.manager.reservations",
    defaultMessage: "Prenotazioni",
  },
  visitReservation: {
    id: "navigation.manager.visitReservation",
    defaultMessage: "Prenota una visita",
  },
  reviews: {
    id: "navigation.manager.reviews",
    defaultMessage: "Gestione Recensioni",
  },
  scanner: {
    id: "navigation.manager.scanner",
    defaultMessage: "Scansiona QR Code",
  },
  sendPoints: {
    id: "navigation.manager.sendPoints",
    defaultMessage: "Invia punti",
  },
  settings: {
    id: "navigation.manager.settings",
    defaultMessage: "Impostazioni",
  },
  statistics: {
    id: "navigation.manager.statistics",
    defaultMessage: "Statistiche",
  },
  stores: {
    id: "navigation.manager.stores",
    defaultMessage: "Negozi",
  },
  museums: {
    id: "navigation.manager.museums",
    defaultMessage: "Impost. accesso/fruizione",
  },
  subscribe: {
    id: "navigation.manager.subscribe",
    defaultMessage: "Abbonati",
  },
  tenantSettings: {
    id: "navigation.manager.tenantSettings",
    defaultMessage: "Tenant settings",
  },
  viewAllCampaigns: {
    id: "common.viewAll",
    defaultMessage: "Vedi tutte",
  },
  voidRequest: {
    id: "common.voidRequest",
    defaultMessage: "Richiesta oblio",
  },
  poi: {
    id: "navigation.poi",
    defaultMessage: "Punto di interesse",
  },
  pois: {
    id: "navigation.pois",
    defaultMessage: "Punti di interesse",
  },
  tours: {
    id: "navigation.tour",
    defaultMessage: "Percorsi",
  },
  glossary: {
    id: "navigation.glossary",
    defaultMessage: "Glossario",
  },
  orders: {
    id: "navigation.orders",
    defaultMessage: "Ordini",
  },
  orderConfig: {
    id: "navigation.orderConfig",
    defaultMessage: "Configurazione ordini",
  },
  userInspection: {
    id: "navigation.user-inspection",
    defaultMessage: "Ispezione Utenti",
  },
  manageTest: {
    id: "navigation.manager.manageTest",
    defaultMessage: "Test",
  },
  adminSubscriptions: {
    id: "navigation.manager.manageTenants",
    defaultMessage: "Gestione abbonamenti",
  },
  reservationsConfig: {
    id: "navigation.reservationsConfig",
    defaultMessage: "Configura prenotazioni",
  },
  contentManagement: {
    id: "navigation.manager.contentManagement",
    defaultMessage: "Gestione Contenuti",
  },
  certificates: {
    id: "navigation.manager.certificates",
    defaultMessage: "Attestati",
  },
  checkinEvents: {
    id: "navigation.manager.checkinEvents",
    defaultMessage: "Estrazioni checkin",
  },
});
