import React from "react";
import { useIntl } from "react-intl";

import { FormControl, FormControlLabel, Switch } from "@material-ui/core";

import { useBmapi } from "../../../utils/bmapi-context";
import { form } from "../../../messages";

export default function Demo({ values, handleChange, campaign, performance }) {
  const { bmapi } = useBmapi();
  const intl = useIntl();
  if (!bmapi.settings.demoConfig) return null;
  return (
    <FormControl margin="normal">
      <FormControlLabel
        control={
          <Switch
            checked={values.demo}
            color="primary"
            onChange={handleChange("demo")}
            key="demo"
            name="demo"
            disabled={
              (performance?.issued_qty > 0 && campaign?.demo) ||
              bmapi.settings.demo
            }
          />
        }
        label={intl.formatMessage(form.demoCampaign)}
      />
    </FormControl>
  );
}
