export const MULTIPLE_TENANTS = JSON.parse(
  process.env.REACT_APP_MULTIPLE_TENANTS
);
// SUBSCRIPTIONS now depends also on the domain config
//export const SUBSCRIPTIONS = JSON.parse(process.env.REACT_APP_SUBSCRIPTIONS);

export const DEV_SUBSCRIPTION = "-";

export const ENVS = {
  DEVELOPMENT: "development",
  PRODUCTION: "production",
};

export const TENANTS = {
  ANCOT: "ancot",
  CSP: "csp",
  DEMO: "demo",
  EKOM: "ekom",
  LUCCA: "lcng",
  MYSARCA: "mysarca",
  SELF: "selfservice",
  APPS: "self-service-apps",
};

export const SKINS = {
  EDUCATION: "education",
  MUSEUM: "museum",
};

export const DEFAULT_TENANT_MAP = {
  "campfire.demo3.mediaus.online": TENANTS.LUCCA,
};

export const DEFAULT_TENANT = process.env.REACT_APP_DEFAULT_TENANT;
export const LOAD_DEFAULT_TENANT = process.env.REACT_APP_LOAD_DEFAULT_TENANT;

export const DOMAIN_WILDCARD = "@liberacta.";
export const API_ERROR = "ApiError";
export const BMAPI_ERROR = "BmapiError";

export const DEFAULT_MANIFEST = {
  background_color: "#fafafa",
  display: "standalone",
  icons: [
    {
      src: "/static/tenants/demo/icon.png",
      sizes: "500x500",
      type: "image/png",
    },
  ],
  name: `BMarkEn by Liberacta`,
  short_name: "BMarkEn",
  theme_color: "#e60",
};

export const POSITIVE_FLOATING_NUMBER = /^(?:\d+|0)+(?:\.\d{0,2})?$/gm;
export const UUID_LENGTH = 36;
export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
export const UUID_REGEX_PREFIX = /(.*)([0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12})$/i;

export const EVENT_PREFIX = "EVT@";
export const LOCATION_PREFIX = "LOC@";
export const POI_PREFIX = "POI@";
export const TOUR_PREFIX = "TOUR@";
export const USER_PREFIX = "UID@";

export const HTTP = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
};

export const LINK_TYPE = {
  ACTION: "action",
  PAGE: "page",
};

export const ACTIONS = {
  LOGGED: "logged",
};

export const FIREBASE_ACTIONS = {
  RECOVER_EMAIL: "recoverEmail",
  VERIFY_EMAIL: "verifyEmail",
  RESET_PASSWORD: "resetPassword",
};

export const CAMPAIGN_ACTION = {
  CLONE: "clone",
  DELETE: "delete",
  DELETE_PRODUCTS: "deleteProducts",
  EDIT: "edit",
  ISSUE: "issue",
  JOIN: "join",
  LINK: "link",
  NOTIFICATIONS: "notifications",
  MOREINFO: "moreInfo",
  SHARE: "share",
  START: "start",
  STOP: "stop",
  UNJOIN: "unjoin",
  WAITINGLIST: "waitingList",
  SETJOINTYPE: "setJoinType",
};

export const ERRORS = {
  APP_NOT_CONFIGURED: "APP_NOT_CONFIGURED",
  CLIENTID_NOT_FOUND: "CLIENTID_NOT_FOUND",
  COOKIES_NOT_FOUND: "COOKIES_NOT_FOUND",
  MANAGER_ONLY: "MANAGER_ONLY",
  MISS_SUBSCRIPTION: "MISS_SUBSCRIPTION",
  NOT_FOUND: "NOT_FOUND",
  ONLY_MANAGER_ENABLED: "ONLY_MANAGER_ENABLED",
  TOKEN_NOT_VALID: "TOKEN_NOT_VALID",
  USER_NOT_VERIFIED: "USER_NOT_VERIFIED",
  VERIFICATION_TIME_EXPIRED: "VERIFICATION_TIME_EXPIRED",
  WRONG_SUBSCRIPTION: "WRONG_SUBSCRIPTION",
  GLOBAL_TENANT_MISSING: "GLOBAL_TENANT_MISSING",
  FRIENDLY_URL_MISSING: "FRIENDLY_URL_MISSING",
};

export const EMPTY_USER = {
  language: "",
  user_id: "",
  user_name: "",
  role: "",
  business: false,
  token_info: {},
  firebase_token_id: "",
};

export const ROLES = {
  USER: "U",
  STORE_MANAGER: "M",
  TENANT_MANAGER: "TM",
};

export const USER_STATUS = {
  ANONYMOUS: "anonymous",
  ACTIVATION: "activation",
  LOGGED: "logged",
};

export const MESSAGE_STATUS = {
  UNREAD: 0,
  READ: 1,
};

export const USER_EVENT_STATUS = {
  ABSENT: "ABSENT",
  PRESENT: "PRESENT",
  OUT: "OUT",
};

export const USER_ROLES = {
  ASSISTANT: 27,
  BUSINESS_MANAGER: 63,
  CASHIER: 7,
  EDITOR: 19,
  REPORTER: 67,
  SUBSCRIPTION_MANAGER: 255,
  SUBSCRIPTION_PLAN_MANAGER: 256,
  SUBSCRIPTION_STAMP_MANAGER: 579,
};

export const PERMISSIONS = {
  ISSUE_BUSINESS_PRODUCTS: "IssueBusinessProducts",
  MANAGE_BUSINESS_CAMPAIGN: "ManageBusinessCampaign",
  MANAGE_SUBSCRIPTION: "ManageSubscription",
  BUSINESS_MANAGER: "BusinessManagerPermission",
  USE_BUSINESS_PRODUCTS: "UseBusinessProducts",
  VIEW_BUSINESS_CAMPAIGN: "ViewBusinessCampaign",
  VIEW_BUSINESS_STATISTICS: "ViewBusinessStatistics",
  VIEW_CAMPAIGN_STATISTICS: "ViewCampaignStatistics",
  VIEW_REPORTS: "ViewBusinessReports",
};

export const RESOURCE_TYPES = {
  LOCATION: "L",
  EVENT: "E",
};

export const PERMISSION_TYPES = {
  SEND_PERMISSION: "SEND_PERMISSION",
};

export const FEATURES = {
  ACCOUNT: "account",
  ACCOUNT_EXTRA_INFO: "accountExtraInfo",
  ASSISTANCE: "assistance",
  ACTIONS_MERCHANT: "actionsMerchant",
  BANNERS: "banners",
  CALENDAR: "calendar",
  CAMPAIGN_COUPON: "campaignCoupon",
  CAMPAIGN_COURSE_PASS: "campaignCoursePass",
  CAMPAIGN_EARNING_CARD: "campaignEarningCard",
  CAMPAIGN_EVENT_PASS: "campaignEventPass",
  CAMPAIGN_INSTANTWIN: "campaignInstantWin",
  //CAMPAIGN_INSTANTWIN_SARCA: "campaignInstantWinSarca",
  CAMPAIGN_MULTIWIN: "campaignMultiWin",
  CAMPAIGN_SHOPPING_CARD: "campaignShoppingCard",
  CAMPAIGN_PROVISIONING_CARD: "campaignProvisioningCard",
  CAMPAIGNS_MANAGER: "campaignsManager",
  CONSENT_MANAGEMENT: "consentManagement",
  CONSENT_MANAGEMENT_CREATE: "consentManagementCreate",
  CHECKIN: "checkin",
  CROSS_LINKS: "crossLinks",
  DELETE_ACCOUNT: "deleteAccount",
  DISTRIBUTION_LISTS: "distributionLists",
  EVENT_ACTION: "eventAction",
  EVENT_ACTION_CREATE: "eventActionCreate",
  EVENTS: "events",
  EXPORT: "export",
  FAIR: "fair",
  FAQ: "faq",
  LCNG: "lcng",
  FRIEND_CODE: "friendCode",
  HIDE_FB_LOGIN: "hideFacebookLogin",
  HIDE_GOOGLE_LOGIN: "hideGooglelogin",
  HIDE_STORE_PARAMS: "hideStoreParams",
  OPERATOR: "operator",
  USER_DATA: "userData",
  HOME_MENU: "homeMenu",
  INFO_SAVE: "infoSave",
  INSTANT_WIN: "instantWin",
  //INSTANT_WIN_SARCA: "instantWinSarca",
  MULTI_WIN: "multiWin",
  ISSUE_NOTIFICATION: "issueNotification",
  ISSUE_PRODUCT: "issueProduct",
  LOCATIONS: "locations",
  LOGOUT: "logout",
  LOOPS: "loops",
  LOTS: "lots",
  MANAGE_CAMPAIGN: "createCampaigns",
  MANAGE_SETTINGS: "manageSetting",
  MESSAGES: "messages",
  MESSAGES_MANAGER: "messagesManager",
  MY_CODE: "myQrCode",
  MY_LOCATION: "myLocation",
  NOTIFICATIONS: "notifications",
  PASS_CHECKIN: "passCheckin",
  PROFILE_PAGE: "profilePage",
  PROFILE_COMPLETE: "profileComplete",
  PUBLIC_HOME: "publicHome",
  PUBLIC_HOME_MANAGER: "publicHomeManager",
  PUSH_NOTIFICATIONS: "pushNotifications",
  REPORTING: "reporting",
  REPORTING_ORDERS: "reportingOrders",
  MANAGE_RESERVATIONS: "manageReservations",
  SHOW_RESERVATIONS: "showReservations",
  MANAGE_CAMPUS_CERTIFICATES: "manageCampusCertificates",
  RESERVATIONS_CONFIG: "reservations",
  REVIEWS: "reviews",
  EMPLOYEE_ACCESS: "employeeAccess",
  SCAN_QR: "scanQrCode",
  SCAN_QR_CONSUMER: "scanQrCodeConsumer",
  SCANNER_GDSP: "scan",
  SEND_POINTS: "sendPoints",
  SHARE_PRODUCT: "shareProduct",
  STATISTICS: "statistics",
  UNIQUE_SIGNIN: "uniqueSignIn",
  VIEW_CAMPAIGNS: "viewCampaigns",
  VIEW_DASHBOARD: "viewDashboard",
  VIEW_REPORTS: "viewReports",
  PRODUCTS_INVENTORY: "productsInventory",
  POI: "poi",
  TOUR: "tour",
  USER_INSPECTION: "userInspection",
  MANAGE_ORDERS: "manageOrders",
  ORDER_CONFIG: "orderConfig",
  ORDER: "order",
  MANAGE_EPRODUCTS: "manageEProducts",
  EPRODUCT_CATEGORY: "eProductCategory",
  EPRODUCT: "eProduct",
  MANAGE_TEST: "manageTest",
  ADMIN_SUBSCRIPTIONS: "adminSubscriptions",
  SETTINGS_MANAGEMENT: "settingsManagement",
  SUBSCRIBE_STEP1: "subscribeStep1",
  SUBSCRIBE_STEP2: "subscribeStep2",
  CONTENT_MANAGEMENT: "contentManagement",
  CHECKIN_EVENT_ACTION: "checkinEventAction",
};

export const EXPERIMENTAL_FEATURES = [
  FEATURES.CHECKIN,
  FEATURES.INSTANT_WIN,
  FEATURES.MULTI_WIN,
  FEATURES.LOCATIONS,
  FEATURES.PROFILE_PAGE,
];

export const FEATURE_PERMISSION = {
  [FEATURES.EXPORT]: PERMISSIONS.VIEW_BUSINESS_STATISTICS,
  [FEATURES.ISSUE_PRODUCT]: PERMISSIONS.ISSUE_BUSINESS_PRODUCTS,
  [FEATURES.MANAGE_CAMPAIGN]: PERMISSIONS.MANAGE_BUSINESS_CAMPAIGN,
  [FEATURES.REPORTING]: PERMISSIONS.VIEW_BUSINESS_STATISTICS,
  [FEATURES.SCAN_QR]: PERMISSIONS.USE_BUSINESS_PRODUCTS,
  [FEATURES.SEND_POINTS]: PERMISSIONS.USE_BUSINESS_PRODUCTS,
  [FEATURES.VIEW_CAMPAIGNS]: PERMISSIONS.VIEW_BUSINESS_CAMPAIGN,
  [FEATURES.VIEW_DASHBOARD]: PERMISSIONS.VIEW_BUSINESS_STATISTICS,
  [FEATURES.VIEW_REPORTS]: PERMISSIONS.VIEW_REPORTS,
};

export const PLANS = {
  TRIAL: "TRIAL",
};

export const BUSINESS_TYPES = {
  MERCHANT: "Merchant",
  MUSEUM: "Museum",
  LOOP: "Loop",
};

export const CODE_FORMATS = {
  STRING: "string",
  QR_CODE: "qrcode",
};

export const PRODUCT_TYPES = {
  CAMPAIGN_COUPON: "CAMPAIGN_COUPON",
  CAMPAIGN_EARNING_CARD: "CAMPAIGN_EARNING_CARD",
  CAMPAIGN_EVENT_PASS: "CAMPAIGN_EVENT_PASS",
  CAMPAIGN_SHOPPING_CARD: "CAMPAIGN_SHOPPING_CARD",
  // CAMPAIGN_AMAZON: "CAMPAIGN_AMAZON",
  CAMPAIGN_MULTIWIN: "CAMPAIGN_MULTIWIN",
};

export const CAMPAIGNS_LABELS = {
  CARD: "card",
  COUPON: "coupon",
  INSTANT: "instant",
  PASS: "pass",
  SUBSCRIPTION: "subscription",
};

export const PRODUCT_SUBTYPES = {
  // COUPON_AMAZON: "AMAZON_COUPON",
  COUPON_DISCOUNT: "DISCOUNT_COUPON",
  COUPON_SIMPLE: "SIMPLE_COUPON",
  COUPON_VALUE: "VALUE_COUPON",
  EARNING_CARD_INSTANT_WIN: "EARNING_CARD_INSTANT_WIN",
  EARNING_CARD_SIMPLE: "EARNING_CARD_SIMPLE",
  EVENT_PASS_SIMPLE: "EVENT_PASS_SIMPLE",
  MULTIWIN_COUPON: "MULTIWIN_COUPON",
  MULTIWIN_STAMP: "MULTIWIN_STAMP",
  PROVISIONING_CARD: "SHOPPING_CARD_PROVISIONING",
  // SHOPPING_CARD_RECHARGEABLE: "SHOPPING_CARD_RECHARGEABLE",
  SHOPPING_CARD_SIMPLE: "SHOPPING_CARD_SIMPLE",
};

export const QRCODE_TYPES = {
  ORDER: "ECOMORDER",
};

export const PRODUCTS = {
  COUPON_DISCOUNT_PERC: "COUPON_DISCOUNT_PERC",
  COUPON_DISCOUNT_VALUE: "COUPON_DISCOUNT_VALUE",
  COUPON_MULTISHOT: "COUPON_MULTISHOT",
  COUPON_MULTIWIN: "COUPON_MULTIWIN",
  COUPON_SIMPLE: "COUPON_SIMPLE",
  COURSE_PASS: "COURSE_PASS",
  EARNING_CARD: "EARNING_CARD",
  EVENT_PASS: "EVENT_PASS",
  INSTANT_WIN: "INSTANT_WIN",
  MULTI_WIN: "MULTI_WIN",
  PROVISIONING_CARD: "PROVISIONING_CARD",
  SHOPPING_CARD: "SHOPPING_CARD",
};

export const TX_TYPES = {
  BURN: "TX_TYPE_BURN",
  CANCEL: "TX_TYPE_CANCEL",
  CHECK_IN: "TX_TYPE_CHECK_IN",
  DECREASE: "TX_TYPE_DECREASE",
  EXPIRE: "TX_TYPE_EXPIRE",
  FRIEND_BONUS: "TX_TYPE_FRIEND_BONUS",
  INCREASE_BONUS: "TX_TYPE_INCREASE_BONUS",
  INCREASE: "TX_TYPE_INCREASE",
  ISSUE: "TX_TYPE_ISSUE",
  PLAY: "TX_TYPE_PLAY",
  PROFILE_WEEKLY_BONUS: "TX_TYPE_PROFILE_WEEKLY_BONUS",
};

export const TX_DESCRIPTIONS = {
  LOSER: "LOSER",
  PRIZE_ISSUE: "Prize issue",
  WINNER: "WINNER",
};

export const CAMPAIGN_STATUS = {
  ACTIVE: 0,
  INACTIVE: 1,
  EXPIRED: 2,
  DELETED: 3,
  SUSPENDED: 4,
};

export const CAMPAIGN_ACTIONS = {
  ANONYMOUS: 1,
  NO_PERMISSION: 2,
  DISABLED_ACCOUNT: -2,
  CAMPAIGN_NOT_STARTED: -10,
  CAMPAIGN_ENDED: -11,
  BOOKED: -12,
  NO_PRODUCTS_AVAILABLE: -20,
  ALL_PRODUCTS_USED: -21,
  NOT_AVAILABLE: 0,
  REEDEMABLE: 10,
  ENABLED: 11,
  BOOKABLE: 12,
  EXTERNAL: 13,
};

export const EVENT_STATUS = {
  INITIAL: 0,
  STARTED: 1,
  ENDED: 2,
};

export const VARIANTS = {
  ERROR: "error",
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
};

export const CONSUMER = "consumer";
export const MANAGER = "manager";
export const CONSUMER_NAMESPACE = "/";
export const OLD_MANAGER_NAMESPACE = "/merchant/";
export const MANAGER_NAMESPACE = "/manager/";
export const AUTH_NAMESPACE = "auth/";

export const ROUTES = {
  ACCOUNT: "account/",
  ACTION: `${AUTH_NAMESPACE}action/`,
  HOME: "",
  LOGOUT: `${AUTH_NAMESPACE}logout/`,
  PASSWORD_FORGET: `${AUTH_NAMESPACE}pw-forget/`,
  PROFILE: "me/",
  SIGN_IN: `${AUTH_NAMESPACE}login/`,
  SIGN_IN_EMAIL: `${AUTH_NAMESPACE}login/mail/`,
  SIGN_IN_ACTION: `${AUTH_NAMESPACE}signin/:action?/`,
  SIGN_UP: `${AUTH_NAMESPACE}register/`,
  STATIC: "content/:content?/",
};

export const CONSUMER_ROUTES = {
  ACCOUNT: `${CONSUMER_NAMESPACE}${ROUTES.ACCOUNT}`,
  ACTION: `${CONSUMER_NAMESPACE}${ROUTES.ACTION}`,
  CALENDAR: `${CONSUMER_NAMESPACE}campaign/:campaignId/calendar/`,
  CAMPAIGN: `${CONSUMER_NAMESPACE}campaign/:campaignId/`,
  FRIEND_CODE: `${CONSUMER_NAMESPACE}friend/:friendCode/`,
  FRIEND: `${CONSUMER_NAMESPACE}friend/`,
  HOME: `${CONSUMER_NAMESPACE}`,
  SUBSCRIPTION_START: `${CONSUMER_NAMESPACE}subscription-start/`,
  //JOIN: `${CONSUMER_NAMESPACE}join/`,
  //SUBSCRIBE_PAYMENT_OUTCOME: `${CONSUMER_NAMESPACE}subscribe-payment-outcome/`,
  LOGOUT: `${CONSUMER_NAMESPACE}${ROUTES.LOGOUT}`,
  MESSAGE: `${CONSUMER_NAMESPACE}messages/:id/:messageId/`,
  MESSAGES: `${CONSUMER_NAMESPACE}messages/`,
  MY_LOCATION: `${CONSUMER_NAMESPACE}my-location/`,
  PASSWORD_FORGET: `${CONSUMER_NAMESPACE}${ROUTES.PASSWORD_FORGET}`,
  PLAY_RESULT: `${CONSUMER_NAMESPACE}campaign/:campaignId/play/result/`,
  PLAY: `${CONSUMER_NAMESPACE}campaign/:campaignId/play/:productId/`,

  PRICING: `${CONSUMER_NAMESPACE}pricing/`,
  PRODUCTS: `${CONSUMER_NAMESPACE}products/`,
  PROFILE: `${CONSUMER_NAMESPACE}${ROUTES.PROFILE}`,
  QR_CODE: `${CONSUMER_NAMESPACE}code/`,
  RECEIVE: `${CONSUMER_NAMESPACE}receive/:permissionId/`,
  SCAN: `${CONSUMER_NAMESPACE}scan/`,
  SIGN_IN_ACTION: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_IN_ACTION}`,
  SIGN_IN_EMAIL: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_IN_EMAIL}`,
  SIGN_IN: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_IN}`,
  SIGN_UP: `${CONSUMER_NAMESPACE}${ROUTES.SIGN_UP}`,
  STATIC: `${CONSUMER_NAMESPACE}${ROUTES.STATIC}`,
  TEST: `${CONSUMER_NAMESPACE}test/`,

  LUCCA_BUY: `${CONSUMER_NAMESPACE}get_pass/`,
  LUCCA_CONFIRM: `${CONSUMER_NAMESPACE}confirm_pass/`,
  LUCCA_CANCEL: `${CONSUMER_NAMESPACE}cancel_order/`,
};

export const MANAGER_ROUTES = {
  ACCOUNT: `${MANAGER_NAMESPACE}${ROUTES.ACCOUNT}`,
  ASSISTANCE: `${MANAGER_NAMESPACE}assistance/`,
  ACTIONS_MERCHANT: `${MANAGER_NAMESPACE}actions/`,
  CAMPAIGN_EVENTS: `${MANAGER_NAMESPACE}campaign/:campaignId/events/`,
  CAMPAIGN_NOTIFICATIONS: `${MANAGER_NAMESPACE}campaign/:campaignId/notifications/`,
  CAMPAIGN: `${MANAGER_NAMESPACE}campaign/:campaignId/`,
  CAMPAIGNS: `${MANAGER_NAMESPACE}campaigns/:filter?/`,
  CLONE_CAMPAIGN: `${MANAGER_NAMESPACE}campaign/:campaignId/clone/`,
  CONSENT_MANAGEMENT: `${MANAGER_NAMESPACE}consent-management/`,
  CONSENT_MANAGEMENT_CREATE: `${MANAGER_NAMESPACE}consent-management-create/`,
  CREATE_CAMPAIGN: `${MANAGER_NAMESPACE}campaigns/create/:subtype?/`,
  DASHBOARD: `${MANAGER_NAMESPACE}dashboard/`,
  EDIT_CAMPAIGN: `${MANAGER_NAMESPACE}campaign/:campaignId/edit/`,
  EXPORT: `${MANAGER_NAMESPACE}export/`,
  HOME: `${MANAGER_NAMESPACE}`,
  LOCATIONS: `${MANAGER_NAMESPACE}locations/`,
  LOGOUT: `${MANAGER_NAMESPACE}${ROUTES.LOGOUT}`,
  LOOPS: `${MANAGER_NAMESPACE}loops/`,
  LOTS: `${MANAGER_NAMESPACE}lots/:businessId?/`,
  MANAGE: `${MANAGER_NAMESPACE}settings/`,
  MESSAGES_CAMPAIGNS: `${MANAGER_NAMESPACE}messages_campaigns/:campaignId?/`,
  CREATE_MESSAGE_CAMPAIGN: `${MANAGER_NAMESPACE}message_campaign/create/`,
  EDIT_MESSAGE_CAMPAIGN: `${MANAGER_NAMESPACE}message_campaign/edit/:messageId?/`,
  PASSWORD_FORGET: `${MANAGER_NAMESPACE}${ROUTES.PASSWORD_FORGET}`,
  POI_MANAGER: `${MANAGER_NAMESPACE}poi/manage/`,
  MANAGE_POI_LIST: `${MANAGER_NAMESPACE}pois/`,
  MANAGE_TOUR_LIST: `${MANAGER_NAMESPACE}tours/`,
  GLOSSARY: `${MANAGER_NAMESPACE}glossary/`,
  MANAGE_ORDERS: `${MANAGER_NAMESPACE}orders/`,
  ORDER_CONFIG: `${MANAGER_NAMESPACE}configurations/`,
  ORDER: `${MANAGER_NAMESPACE}ecomorders/`,
  MANAGE_PRODUCTS: `${MANAGER_NAMESPACE}products/`,
  EPRODUCT_CATEGORY: `${MANAGER_NAMESPACE}ecomcategories/`,
  EPRODUCT: `${MANAGER_NAMESPACE}ecomitems/`,
  FAIR: `${MANAGER_NAMESPACE}gestione-fiere/`,
  OPERATOR: `${MANAGER_NAMESPACE}operatore/`,
  USER_DATA: `${MANAGER_NAMESPACE}user-data/`,
  HOME_MENU: `${MANAGER_NAMESPACE}home/`,
  POI: `${MANAGER_NAMESPACE}poi/`,
  PRIZES: `${MANAGER_NAMESPACE}prizes/`,
  PROFILE: `${MANAGER_NAMESPACE}${ROUTES.PROFILE}`,
  PROMOS: `${MANAGER_NAMESPACE}promo/`,
  REPORTING: `${MANAGER_NAMESPACE}reporting/`,
  REPORTING_ORDERS: `${MANAGER_NAMESPACE}reporting-orders/`,
  MANAGE_RESERVATIONS: `${MANAGER_NAMESPACE}reservations/`,
  SHOW_RESERVATIONS: `${MANAGER_NAMESPACE}show_reservations/`,
  MANAGE_CAMPUS_CERTIFICATES: `${MANAGER_NAMESPACE}manage_campus_certificates/`,
  RESERVATIONS_CONFIG: `${MANAGER_NAMESPACE}reservations-config/`,
  DISTRIBUTION_LISTS: `${MANAGER_NAMESPACE}distribution-lists/`,
  DISTRIBUTION_LIST_CREATE: `${MANAGER_NAMESPACE}distribution-lists/create/:subtype?/`,
  DISTRIBUTION_LIST_EDIT: `${MANAGER_NAMESPACE}distribution-list/:listId/edit/`,
  EMPLOYEE_ACCESS: `${MANAGER_NAMESPACE}employee-access/`,
  EVENT_ACTION: `${MANAGER_NAMESPACE}event-action/`,
  EVENT_ACTION_CREATE: `${MANAGER_NAMESPACE}event-action-create/`,
  PUSH_NOTIFICATIONS: `${MANAGER_NAMESPACE}push-notifications/`,
  RESERVATIONS: `${MANAGER_NAMESPACE}campaign/:campaignId/reservations/`,
  REVIEWS: `${MANAGER_NAMESPACE}reviews/`,
  SCAN: `${MANAGER_NAMESPACE}scan/`,
  SCANNER_GDSP: `${MANAGER_NAMESPACE}scanQrCode/`,
  SEND_POINTS: `${MANAGER_NAMESPACE}send-points/`,
  SIGN_IN_ACTION: `${MANAGER_NAMESPACE}${ROUTES.SIGN_IN_ACTION}`,
  SIGN_IN_EMAIL: `${MANAGER_NAMESPACE}${ROUTES.SIGN_IN_EMAIL}`,
  SIGN_IN: `${MANAGER_NAMESPACE}${ROUTES.SIGN_IN}`,
  SIGN_UP: `${MANAGER_NAMESPACE}${ROUTES.SIGN_UP}`,
  STATIC: `${MANAGER_NAMESPACE}${ROUTES.STATIC}`,
  STATISTICS: `${MANAGER_NAMESPACE}statistics/`,
  STORES: `${MANAGER_NAMESPACE}stores/`,
  MUSEUMS: `${MANAGER_NAMESPACE}museums/`,
  SUBSCRIPTION: `${MANAGER_NAMESPACE}subscription/`,
  VIEW_SUBSCRIPTIONS: `${MANAGER_NAMESPACE}view-subscriptions/`,
  TENANT_SETTINGS: `${MANAGER_NAMESPACE}configuration/`,
  TOUR: `${MANAGER_NAMESPACE}tour/`,
  USER_INSPECTION: `${MANAGER_NAMESPACE}campaign/:campaignId/user-inspection/`,
  VOID_REQUEST: `${MANAGER_NAMESPACE}void/`,
  SETTINGS_MANAGEMENT: `${MANAGER_NAMESPACE}settings/`,

  LUCCA_STATS: `${MANAGER_NAMESPACE}lucca-stats/`,

  PRODUCTS_INVENTORY: `${MANAGER_NAMESPACE}products/`,
  MANAGE_TEST: `${MANAGER_NAMESPACE}manage-test/`,
  ADMIN_SUBSCRIPTIONS: `${MANAGER_NAMESPACE}admin-subscriptions/`,
  SUBSCRIPTION_CREATE: `${MANAGER_NAMESPACE}subscription-create/`,
  //SUBSCRIPTION_START: `${MANAGER_NAMESPACE}subscription-start/`,
  SUBSCRIBE_STEP1: `${MANAGER_NAMESPACE}subscribe_step1/`,
  SUBSCRIBE_STEP2: `${MANAGER_NAMESPACE}subscribe_step2/`,
  SUBSCRIBE_STEP3: `${MANAGER_NAMESPACE}subscribe-payment-outcome/`,
  CONTENT_MANAGEMENT: `${MANAGER_NAMESPACE}content-management/`,
  CHECKIN_EVENT_ACTION: `${MANAGER_NAMESPACE}checkin-event-action/`,
};

export const API = {
  BANNERS: "/banners",

  BUSINESS: "/business/{business}",
  CREATE_BUSINESS: "/business",
  DELETED_BUSINESS: "/business/{business}/deleted",
  DELETE_CONTENT: "/business/{business}/contents/{content}",
  GET_BUSINESS_CAMPAIGNS: "/business/{business}/campaigns",
  GET_CAMPAIGN_PERF: "/business/{business}/campaigns/{campaign}/performance",
  GET_CAMPAIGN_DETAILS: "/business/{business}/campaigns/{campaign}/details",
  GET_CAMPAIGN_UPLOAD: "/business/{business}/campaigns/{campaign}/upload-url",
  GET_CAMPAIGNS: "/business/{business}/campaigns",
  GET_CONTENTS: "/business/{business}/contents",
  GET_PRODUCT_INFO: "/business/{business}/products/{qr-code}/info",
  GET_PRODUCT_INFO_TENANT:
    "/business/{business}/products/{qr-code}/info/{tenant-name}",
  GET_TERMS: "/business/{business}/terms",
  SIGN_TERM: "/business/{business}/terms/{terms}/sign",
  GET_RULES: "/business/{business}/campaigns/{campaign}/rules",
  GET_UPLOAD_CONTENTS: "/business/{business}/contents/upload-url",
  GET_USE_PERMISSION_BY_EMAIL: "/business/{business}/products/default/qrcode",
  UPDATE_CONTENT_STATUS: "/business/{business}/contents/{content}/{status}",
  UPDATE_CONTENT: "/business/{business}/contents/{content}",
  CREATE_CONTENT: "/business/{business}/contents",
  LOCATIONS: "/locations",
  BUSINESS_LOCATIONS: "/business/{business}/locations",
  LOCATION: "/business/{business}/locations/{location}",
  CREATE_BUSINESS_EVENT: "/business/{business}/event",
  BUSINESS_EVENT: "/business/{business}/event/{event}",
  BUSINESS_EVENTS: "/business/{business}/events",
  UNJOIN: "/business/{business}/campaign/{campaign}/unjoin",
  SETJOINTYPE: "/business/{business}/campaign/{campaign}/setjointype",

  CAMPAIGN_CREATE: "/campaigns",
  CAMPAIGN_EDIT: "/campaigns/{campaign}",
  ISSUE_PRODUCT: "/campaigns/{campaign}/issue",
  UPLOAD_CAMPAIGN_IMAGE: "/campaigns/{campaign}/picture/upload",
  GET_CAMPAIGN_TERMS: "/campaigns/{campaign}/use-terms",
  GET_CAMPAIGN_PRIZES: "/campaigns/{campaign}/products/{product}/prizes",
  ACCEPT_PRODUCT: "/campaigns/{campaign}/products/accept",
  CAMPAIGN_RESERVATION: "/campaigns/{campaign}/reservations",
  CAMPAIGN_USERS: "/campaigns/{campaign}/users",
  CAMPAIGN_USERS_PRODUCTS: "/campaigns/{campaign}/users/{user}/products",
  CAMPAIGN_PRODUCTS: "/campaigns/{campaign}/products",
  CAMPAIGN_PRODUCT_USERS: "/campaigns/{campaign}/productusers",

  CATEGORIES: "/categories/campaigns/{campaign}",

  CHECKIN: "/checkin",
  CHECKINS: "/checkins",
  CHECKOUT: "/checkout",

  CONSENT: "/consents/expressions",
  CONSENTS: "/consents",
  CONSENTS_EXPRESSIONS: "/consents/expressions",

  COOKIE: "/cookie",

  GET_TRANSACTIONS: "/data/transactions",

  EVENT_STATUS: "/events/{event}/status",
  EVENT_USERS: "/events/{event}/users",

  LOOPS: "/loops",
  LOOP: "/loops/{loop}",
  LOOP_BUSINESSES: "/loops/{loop}/business",
  LOOP_BUSINESS: "/loops/{loop}/business/{business}",

  MALL_INVENTORIES: "/mall/inventories",
  MALL_INVENTORY_ASSIGN: "/mall/inventory/assign",
  MALL_COUPON_STATS: "/mall/business/{business}/coupons/stats",

  ME: "/me",

  MESSAGES: "/messages",
  MESSAGE: "​/messages/{message}",

  NOTIFICATIONS: "​/campaigns/{campaign}/notifications",
  UPDATE_NOTIFICATION: "/campaigns/{campaign}/notifications/{notification}",

  CREATE_ORDER: "/operations/orders",
  CHECK_ORDER: "/operations/orders/{order}/check/{status}",
  GET_INVENTORIES: "/operations/orders/inventories",

  PERMISSION: "/permissions/{id}",
  CHECK_MANAGER: "/permissions/{permission}/check-user",

  CHANGE_ORDER_STATUS: "/business/{business}/ecomorder/{ecomorder}/status",
  BURN_COUPON: "/products/coupon/{qr-code}/burn",
  CREATE_QRCODE_IMAGE: "/products/{qr-code}/qrcode-img",
  REDEEM_PRIZE: "/products/card/{card}/prize/{prize}",
  DECREASE_CARD_BALANCE: "/products/card/{qr-code}/decrease",
  INCREASE_CARD_BALANCE: "/products/card/{qr-code}/increase",
  CREATE_PERMISSION: "/products/{product}/qrcode",
  CREATE_MULTI_PERMISSION: "/products/qrcode",
  GET_EXTERNAL_CODE: "/products/{product}/external-id",
  PLAY_IW: "/products/{product}/play-instantwin",
  SEND_PERMISSION: "/products/{product}/send-permission",
  RECEIVE_PRODUCT: "/products/{qr-code}/receive",
  PRODUCT_PERMISSIONS: "/products/{product}/permissions",
  DISABLE_PRODUCT_PERMISSIONS: "/products/{qr-code}/disable-permission",
  PROCESS_STAMP: "/products/stamp",

  RESERVATION: "/reservations/{reservation}",

  SIGNOUT: "/signout",
  SIGNUP: "/signup",
  //BMESIGNUP: "/bmesignup",
  //BMESIGNUP_SUBSCRIBE: "/bmesignupsubscribe",
  CUSTOM_SIGNUP: "/signup/custom",

  QUERY_POINTS_USERS: "/stats/points-users",

  SUBSCRIPTION: "/subscriptions/{subscription}",
  //SUBSCRIPTION_BY_ID: "/subscriptions/{subscriptionId}",
  SUBSCRIPTIONS: "/subscriptions",
  SUBSCRIPTIONS_STEP1: "/subscriptions/subscribe/step1",
  SUBSCRIPTIONS_STEP2: "/payments/subscriptions/{subscriptionId}/step2",
  SUBSCRIPTIONS_STEP3: "/payments/subscriptions/{subscriptionId}/step3/{txId}",
  SUBSCRIPTIONS_TX_MESSAGE:
    "/payments/subscriptions/{subscriptionId}/txs/{txId}/message",
  PAYMENTS_ITEMS: "/payments/items",
  //PAYMENTS_PAYLOAD: "/payments/txs/{txId}/payload",
  SUBSCRIPTIONS_PRICES: "/payments/subscriptions/prices",
  //SUBSCRIPTIONS_PERMISSIONS: "/subscriptions/{subscription}/permissions",
  SUBSCRIPTIONS_PERMISSION:
    "/subscriptions/{subscription}/permissions/{permission}",
  SUBSCRIPTIONS_BS: "/subscriptions/{subscription}/business",
  SUBSCRIPTIONS_MANAGER: "/subscriptions/{subscription}/manager",
  SUBSCRIPTIONS_BS_STATS:
    "/subscriptions/{subscription}/business/{business}/stats",
  SUBSCRIPTIONS_PUBLIC: "/pub/subscriptions",

  GET_TENANT_BUSINESS: "/tenant/business",
  GET_TENANT_BUSINESS_ADMIN: "/tenant/business/admin",
  GET_TENANT_BUSINESS_COUNT: "/tenant/business/count",
  //GET_TENANT_PERMISSIONS: "/tenant/permissions",
  GET_TENANT_CAMPAIGNS: "/tenant/campaigns",
  GET_ALL_TENANT_CAMPAIGNS: "/tenant/allcampaigns",
  GET_TENANT_POLICY: "/tenant/policies",
  UPDATE_TENANT_POLICY: "/tenant/policies",
  SUPPORT_EMAIL: "/tenant/support/email",
  GET_CAMPAIGN: "/tenant/{tenant}/campaigns/{campaign}",
  GET_EVENTS: "/tenant/{tenant}/events",
  VOID: "/tenant/void",

  TRANSACTIONS: "/transactions/summary",

  GET_USER: "/users/{user}",
  USER_PROFILE: "/users/{user}/profile",
  GET_USER_BUSINESS: "/users/{user}/business",
  USER_BUSINESS_PERMISSION: "/users/{user}/business/{business}/permissions",
  USER_SUBSCRIPTION_PERMISSION:
    "/users/{user}/subscriptions/{subscription}/permissions",
  GET_PRODUCTS: "/users/{user}/products",
  GET_PRODUCTS_STATS: "/users/{user}/campaigns/stats",
  GET_DEFAULT_PRODUCT: "/users/{user}/products/default",
  GET_USER_RESERVATIONS: "/users/{user}/reservations",
  UPLOAD_AVATAR: "/users/{user}/picture/upload",
  UPDATE_LCNG_USER: "/users/lcng/{user}",
  CREATE_MANAGER: "/users/manager",
  CREATE_MANAGER_WITH_CREDENTIALS: "/users/manager/withcredentials",
  USER_PERMISSION: "/users/permission/{permission}",
  USER_INFO: "/users/{user}/{tenant-name}/info",

  WINS: "/wins",
  WIN: "/wins/{win}",
  WIN_STATUS: "/wins/{win}/status",

  POI_CREATE: "/business/{business}/pois",
  POI_EDIT: "/business/{business}/pois/{poi}",
  POI_GET: "/business/pois/query",
  POI_DELETE: "/business/{business}/pois/{poi}",
  TOUR_CREATE: "/business/{business}/tour",
  TOUR_EDIT: "/business/{business}/tours/{tour}",
  TOUR_DELETE: "/business/{business}/tours/{tour}",
  TOUR_GET: "/business/tours/query",
  QUESTION_CREATE: "/pois/{poi}/question",
  QUESTION_DELETE: "/questions/{question}",
  QUESTION_EDIT: "/pois/{poi}/question/{question}",
  QUESTION_GET: "/questions/query",
  GLOSSARY_KEYWORD_CREATE: "/glossary/keywords",
  GLOSSARY_KEYWORD_DELETE: "/glossary/keywords/{keyword}",
  GLOSSARY_KEYWORD_EDIT: "/glossary/keywords/{keyword}",
  GLOSSARY_KEYWORD_GET: "/glossary/keywords/{keyword}",
  GLOSSARY_KEYWORD_QUERY_GET: "/glossary/query/keywords",
  GLOSSARY_GET: "/glossary",
  EPRODUCT_CATEGORY_CREATE: "/ecomcategories",
  EPRODUCT_CATEGORY_EDIT: "/ecomcategory/{ecomcategory}",
  EPRODUCT_CATEGORY_GET: "/ecomcategories",
  EPRODUCT_CREATE: "/business/{business}/ecomitems",
  EPRODUCT_EDIT: "/business/{business}/ecomitem/{ecomitem}",
  EPRODUCT_GET: "/business/{business}/ecomitems",
  EORDER_CREATE: "/business/{business}/ecomorders",
  GETSLOT: "/business/{business}/available-slots",
  EORDER_EDIT: "/business/{business}/ecomorder/{ecomorder}",
  EORDER_STATUS_EDIT: "/business/{business}/ecomorder/{ecomorder}/status",
  EORDERS_LIST_STATUS_EDIT: "/business/{business}/ecomorderslist/status",
  EORDER_GET: "/business/{business}/ecomorders",
  EORDERS_RESUME_GET: "/business/{business}/ecomorders/resume",
  EVENT_ACTION_CREATE: "/business/{business}/event-actions",
  EVENT_ACTION_EDIT: "/business/{business}/event-actions/{event}",
  EVENT_ACTION_DELETE: "/business/{business}/event-actions/{event}",
  EVENT_ACTION_GET: "/business/{business}/event-actions",
  EVENT_ACTION_BY_ID_GET: "/business/{business}/event-actions/{event}",
  FAIRS_GET: "/tenant/contents",
  DATE_FAIRS_GET: "/tenant/contents/light",
  GET_MERCHANT_BY_TYPE: "/search/business/type/{type}",
  CREATE_FAIR: "/tenant/{tenant}/contents/upload",
  CREATE_FAIR_CAMPAIGNS: "/tenant/contents/{content}/eventpass",
  UPDATE_FAIR: "/tenant/contents/{content}",
  UPDATE_FAIR_STATUS: "/tenant/contents/{content}/{status}",
  DELETE_FAIR: "/tenant/contents/{content}",
  GET_USER_POINTS_COUNT: "/data/campaigns/default/users/points/count",
  GET_USER_POINTS: "/data/campaigns/default/users/points",
  GET_USER_PRIZES: "/data/campaigns/default/users/prizes",
  GET_USER_STATS: "/data/campaigns/default/users/points/csv",
  GET_PRIZES_STATS: "/data/campaigns/default/users/prizes/csv",
  GET_MERCHANTS_STATS: "/data/campaigns/default/merchants/actions/csv",
  GET_MERCHANTS_ACTIONS: "/data/campaigns/default/merchants/actions",
  GET_MERCHANTS_ACTIONS_COUNT:
    "/data/campaigns/default/merchants/actions/count",
  GET_REVIEWS: "/tenant/actions",
  UPDATE_ACTION_STATUS: "/tenant/actions/{action}/status/{status}",
  SUBSCRIPTION_CREATE: "/superadmin/subscriptions",
  SUBSCRIPTIONS_GET: "/superadmin/subscriptions",
  CONSENT_CREATE: "/tenant/consents",
  CONSENT_GET: "/tenant/consents",
  CONSENT_EDIT: "/tenant/consents/{consent}",
  CONSENT_DELETE: "/tenant/consents/{consent}",
  CHECKINS_GET: "/checkins/count",
  //CHECKIN_DOWNLOAD_GET: "/checkins/download",
  CHECKIN_DOWNLOAD: "/checkins/download",
  CUSTOMER_INFO_GET: "/users/{user}",
  CLIENTS_GET: "/tenant/clients",
  SEND_PUSH_NOTIFICATION: "/tenant/notifications/send-push",
  TASKS_GET: "/tasks",
  TASK_STATS_GET: "/tasks/{task}/stats",
  TASK_ERRORS_GET: "/tasks/{task}/errors",
  TENANT_TEST_CONFIG: "/tenant/testconfig",
  TENANT_TEST_CONFIG_CLEANPLAY_BYPRODUCT:
    "/tenant/testconfig/cleanplay/products/{product}",
  TENANT_TEST_CONFIG_CLEANTODAYPLAYS:
    "/tenant/testconfig/cleanplay/today/{email}",
  USER_PHONE_GET: "/business/{business}/ecomorders/{ecomorder}/telephone",
  CLOSING_DATES_CREATE: "/business/{business}/closing-dates",
  CLOSING_DATES_GET: "/business/{business}/closing-dates",
  ECOM_CONF_CREATE: "/business/{business}/ecom-daily-confs",
  ECOM_CONF_EDIT: "/business/{business}/ecom-daily-confs/{conf}",
  ECOM_CONF_DELETE: "/business/{business}/ecom-daily-confs/{conf}",
  ECOM_CONF_GET: "/business/{business}/ecom-daily-confs",
  MEDIACONTENT_CREATE: "/business/{business}/entities/{entity}/media",
  MEDIACONTENT_UPDATE: "/business/{business}/entities/{entity}/media/{media}",
  MEDIACONTENT_DELETE:
    "/business/{business}/entities/{entity}/lang/{lang}/media/{media}",
  EMPLOYEE_ACCESS_CREATE: "/business/{business}/useractivations/{type}",
  EMPLOYEE_ACCESS_DELETE: "/business/{business}/useractivations/{type}/{id}",
  REGISTRATION_GET: "/business/{business}/useractivations/{type}",
  ACCESS_REGISTRATION: "/business/{business}/useractivations/{type}/qrcode",
  EMPLOYEE_ACCESS_AUTHORIZE: "/business/useractivations/status/{status}",
  SUBSCRIPTION_MEDIACONTENT_CREATE: "/subscriptions/{subscription}/media",
  SUBSCRIPTION_MEDIACONTENT_DELETE:
    "/subscriptions/{subscription}/media/{media}",
  BUSINESS_MESSAGES: "/business/{business}/messages",
  BUSINESS_MESSAGE: "/business/{business}/messages/{message}",
  SAVE_MESSAGING_TOKEN: "/messaging/token",
  GET_PRODUCT: "/products/{product}",
  SUBSCRIPTION_LOOKUP: "/subscriptionlookup/{tenant}/{friendlyUrl}",
  SUBSCRIPTION_LOOKUP_FAST: "/subscriptionlookupfast/{tenant}/{friendlyUrl}",
  USERS_GET: "/users/admin/query",
  CREATE_DISTRIBUTION_LIST: "/userlists",
  UPDATE_DISTRIBUTION_LIST: "/userlists/{userlist}",
  DISTRIBUTION_LISTS_GET: "/userlists",
  DISTRIBUTION_LIST_GET: "/userlists/{userlist}",
  GET_GENLIST_CATEGORIES_ADMIN: "/tenant/genlists/business_category/admin",
  GET_GENLIST_CATEGORIES: "/tenant/genlists/business_category/view/it",
  UPDATE_GENLIST_CATEGORIES: "/tenant/genlists/business_category/admin",
  GET_GENLIST_ADMIN: "/tenant/genlists/{listId}/admin",
  GET_GENLIST: "/tenant/genlists/{listId}/view/{lang}",
  UPDATE_GENLIST: "/tenant/genlists/{listId}/admin",
  GET_ACTION_FROM_QRCODE: "/gdsp/actions/qrcode/{code}/info",
  GET_FAIR_FROM_QRCODE: "/gdsp/actions/qrcode/{code}/fair",
  GET_CONTENT: "/tenants/GDSP/contents/{content}",
  GET_BUSINESS_REWARDING_ACTION: "/gdsp/business/{business}/rewardingaction",
  GET_ALL_REWARDING_ACTIONS: "/users/{user}/rewardingactions",
  CANCEL_ACTION: "/gdsp/business/{business}/action/cancel",
  CONFIRM_ACTION: "/gdsp/business/{business}/action/confirm",
  DISABLE_USER: "/users/{user}/aud/{aud}/disable",
  ENABLE_USER: "/users/{user}/aud/{aud}/enable",
  GET_MANAGER_CREDENTIALS_BY_EMAIL: "/userpwd/by-email",
  DELETE_RESERVATION: "/order/{order}",
  DELETE_EVENT_RESERVATION: "/products/{product}/status",
  GET_RESERVATIONS_CSV: "/business/ecomorders/csv",
  GET_CAMPUS_RESERVATIONS_XLS: "/custom/campus/business/ecomorders/xls",
  GET_MAIN_EVENT_PASSES_CSV: "/campaigns/{campaign}/passes/csv",
  GET_CAMPUS_MAIN_EVENT_PASSES_XLS:
    "/custom/campus/campaigns/{campaign}/passes/xls",
  //GET_CAMPUS_MAIN_EVENT_PASSES_CSV:
  //  "/custom/campus/campaigns/{campaign}/passes/csv",
  GET_MAIN_EVENT_PASSES: "/campaigns/{campaign}/passes",
  GET_MAIN_EVENT_PASSES_RESUME: "/campaigns/{campaign}/passes/resume",
  POST_CONSENTS_SIGN: "/consents/sign",
  GET_CAMPUS_MATCH_LIST_CSV:
    "/custom/campus/business/{business}/match-list/csv",

  OPERATOR_QRCODE: "/business/{business}/operator-qrcode",

  GET_CAMPUS_CERTIFICATES_RESUME: "/custom/campus/certificates-resume",
  GET_CAMPUS_CERTIFICATES_RESUMEV2: "/v2/custom/campus/certificates-resume",
  GET_CAMPUS_CERTIFICATES_CSV: "/custom/campus/certificates-csv",
  //UPLOAD_CONTENTS: "/business/{business}/contents/upload-url",
  UPLOAD_CAMPUS_CERTIFICATES_CSV: "/custom/campus/certificates-csv",
  UPLOAD_CAMPUS_CERTIFICATES_CSV_V2: "/v2/custom/campus/certificates-csv",
  PROCESS_UPLOAD: "/tenant/upload/{upload}/process",
  CHECKIN_PARTICIPANTS: "/checkins/participants",
  CHECKIN_WINNERS: "/checkins/winners",
  CHECKIN_SEND: "/checkins/send",
  CHECKIN_EXCHANGES: "/checkins/exchanges",
  CHECKIN_EXCHANGES_FILE: "/checkins/exchanges/file",
  CHECK_CHECKINS_REPLY: "/checkins/exchanges/check-reply",
  EVENT_ACTION_EDIT_CUSTOM_STAUS: "/event-actions/{event}/custom-status",
  CHECKIN_WINNER_ASSIGNMENT: "/checkins/winner/assignment",
};

function getDomainConfig() {
  let tenant, subscription, alternativeSite, externalRoot, nosubscriptions;
  const domain = window.location.hostname.replace(
    new RegExp("\\.|\\-", "g"),
    "_"
  );
  let domainConfig = process.env["REACT_APP_DOMAIN_" + domain];
  if (!domainConfig) {
    const parts = window.location.hostname.split(".");
    console.log(
      "********",
      `REACT_APP_DOMAIN___placeholder___${parts[1]}_${parts[2]}`
    );
    if (parts.length === 3) {
      domainConfig =
        process.env[`REACT_APP_DOMAIN___placeholder___${parts[1]}_${parts[2]}`];
      //console.log("********", domainConfig);
      if (domainConfig && parts[0]) {
        subscription = parts[0];
      }
    }
  }
  //console.log("xxxxxxx", domainConfig);
  if (domainConfig) {
    const items = domainConfig.split(",");
    for (const item of items) {
      const element = item.split("=");
      if (element.length !== 2) {
        continue;
      }
      switch (element[0]) {
        case "tenant":
          tenant = element[1];
          break;
        case "subscription":
          subscription = element[1];
          break;
        case "alternativeSite":
          alternativeSite = element[1];
          break;
        case "externalRoot":
          externalRoot = element[1];
          break;
        case "nosubscriptions":
          nosubscriptions = element[1] && element[1].toLowerCase() === "true";
          break;
      }
    }
  }
  // priority: subscription => nosubscriptions => REACT_APP_SUBSCRIPTIONS
  let _subscriptions = JSON.parse(process.env.REACT_APP_SUBSCRIPTIONS);
  if (subscription) {
    _subscriptions = true;
  } else if (nosubscriptions) {
    _subscriptions = false;
  }
  return {
    TENANT: tenant,
    SUBSCRIPTION: subscription,
    ALTERNATIVESITE: alternativeSite,
    EXTERNAL_ROOT: externalRoot === "true",
    SUBSCRIPTIONS: _subscriptions,
  };
}

export const DOMAIN_CONFIG = getDomainConfig();

export const SUBSCRIPTIONS = DOMAIN_CONFIG?.SUBSCRIPTIONS;

export const URL_TRACE_PARAM = "t";
export const URL_TRACE_VALUES = { SUBSCRIBE: "s", EXTERNAL: "e" };
export const URL_SUBSCRIPTION_PARAM = "s";
export const URL_SUBSCRIBE_PARAMS = { SUBSCRIPTION: "s", PAYMENT: "p" };

export const URL_CALLBACK_PARAM = "c";
