import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
  Snackbar,
  SnackbarContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import {
  ArrowBack,
  MoreVert,
  People,
  PeopleAltOutlined,
  PlayArrow,
  Replay,
  Stop,
  Close,
  Cancel,
  ListSharp,
} from "@material-ui/icons";
import React, { useCallback, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { campaign, common, form } from "../../messages";
import { DatePicker } from "../../ui/DatePicker";
import Title from "../../ui/Title";
import { MANAGER_ROUTES } from "../../utils/constants";
import { /*useHistory,*/ Link as RouterLink } from "react-router-dom";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { format, isAfter } from "date-fns";
import Confirm from "../../ui/Confirm";
import { eventActions } from "../../messages/eventActions";
import CheckInParticipants from "../../components/CheckInParticipants";
import CheckInExchanges from "../../components/CheckInExchanges";
import CheckInWinners from "../../components/CheckInWinners";

const eventType = "CHECKIN_EVENT_ACTION";
const eaCustomStatuses = {
  toBeStarted: "",
  pending: "pending",
  toBeAssigned: "to-be-assigned",
  missingReply: "missing-reply",
  closed: "closed",
};
const exchangeStatuses = {
  ok: "ok",
  err: "err",
  notSent: "not-sent",
  processed: "processed",
  wrongFile: "wrong-file",
};

export default function CheckinEventAction() {
  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    notifySuccess,
    startLoading,
    stopLoading,
  } = useBmapi();

  const today = new Date();
  const oneYearAgo = new Date();
  oneYearAgo.setFullYear(today.getFullYear() - 1);
  const [filters, setFilters] = useState({
    from: oneYearAgo,
    to: today,
    selected_type: eventType,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [events, setEvents] = useState([]);
  const [pendingEvents, setPendingEvents] = useState([]);
  const [error, setError] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showStartDrawAlert, setShowStartDrawAlert] = useState(false);
  const [showCancelDrawAlert, setShowCancelDrawAlert] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [participantsOpenDialog, setParticipantsOpenDialog] = useState(null);
  const [exchangesOpenDialog, setExchangesOpenDialog] = useState(false);
  const [snackbarMessages, setSnackbarMessages] = useState([]);
  const [isChecking, setIsChecking] = useState(false);
  const [stopOpenDialog, setStopOpenDialog] = useState(null);
  const [reopenOpenDialog, setReopenOpenDialog] = useState(null);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const handleFilters = useCallback((label) => {
    const updateValue = (val) => {
      const today = new Date();
      setFilters((prevFilters) => {
        let newFilters = { ...prevFilters, [label]: val };

        if (label === "to") {
          if (!val) {
            newFilters.to = today; // Reset to today if deleted
          } else if (val > today) {
            newFilters.to = today; // Ensure "to" is never greater than today
          }
        }

        return newFilters;
      });
    };

    return (i, f) => {
      if (typeof f === "boolean") updateValue(f);
      else if (i?.target) updateValue(i.target.value);
      else updateValue(i);
    };
  }, []);

  const byDate = (a, b) => new Date(b.end_at) - new Date(a.end_at);

  const getCampaignLabel = (id) => {
    const filtered = campaignList.filter((c) => c.id === id);
    return filtered && filtered.length
      ? filtered[0].name
      : intl.formatMessage(campaign.noCampaign);
  };

  const loadEventList = useCallback(
    (params) => {
      params = {
        from: params.from ? format(params.from, "yyyy-MM-dd") : null,
        to: params.to ? format(params.to, "yyyy-MM-dd") : null,
        type: params.selected_type === "-" ? null : params.selected_type,
      };

      for (const property in params) {
        if (params[property] === null) {
          delete params[property];
        }
      }

      startLoading();
      bmapi
        .getEventActions(params)
        .then((resp) => {
          if (!resp) {
            resp = [];
            setEvents(resp);
          } else {
            setEvents(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    },
    [bmapi, intl, notifyError, startLoading, stopLoading]
  );

  useEffect(() => {
    loadEventList(filters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPendingEvents(
      events.filter((ev) => ev.custom_status === eaCustomStatuses.pending)
    );
  }, [events]);

  useEffect(() => {
    const readCampaigns = async () => {
      let list = await bmapi.getAllTenantCampaigns();
      setCampaignList(list);
    };
    readCampaigns();
  }, [bmapi]);

  const handleOpen = (e, event) => {
    setAnchorEl(e.currentTarget);
    //setEventId(event.id);
    setSelectedEvent(event);
  };

  const update = (e) => {
    e.preventDefault();
    setFilters((prevFilters) => ({
      ...prevFilters,
      to: prevFilters.to || today, // Ensure "to" is always set when empty
    }));
    loadEventList(filters);
  };

  const startDraw = () => {
    if (!selectedEvent?.id) {
      return;
    }
    startLoading();
    bmapi
      .sendCheckins({ event_action: selectedEvent.id })
      .then(() => updateEventAction())
      .then(() => notifySuccess("File checkin inviato"))
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
        setShowStartDrawAlert(false);
        setSelectedEvent(null);
      })
      .finally(() => {
        stopLoading();
        setSelectedEvent(null);
        setShowStartDrawAlert(false);
      });
  };

  const cancelDraw = () => {
    if (!selectedEvent?.id) {
      return;
    }
    startLoading();
    bmapi
      .updateEventActionCustomStatus(
        selectedEvent.id,
        eaCustomStatuses.toBeStarted
      )
      .then(() => updateEventAction())
      .then(() => notifySuccess("Estrazione reinizializzata"))
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        setSelectedEvent(null);
        setShowCancelDrawAlert(false);
        stopLoading();
      });
  };

  const stopDraw = () => {
    if (!selectedEvent?.id) {
      return;
    }
    startLoading();
    bmapi
      .updateEventActionCustomStatus(selectedEvent.id, eaCustomStatuses.closed)
      .then(() => updateEventAction())
      .then(() => notifySuccess("Estrazione chiusa"))
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        setSelectedEvent(null);
        setStopOpenDialog(false);
        stopLoading();
      });
  };

  const reopenDraw = () => {
    if (!selectedEvent?.id) {
      return;
    }
    startLoading();
    bmapi
      .updateEventActionCustomStatus(
        selectedEvent.id,
        eaCustomStatuses.toBeAssigned
      )
      .then(() => updateEventAction())
      .then(() => notifySuccess("Riassegnare i vicitori"))
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        setSelectedEvent(null);
        setReopenOpenDialog(false);
        stopLoading();
      });
  };

  const updateEventAction = () => {
    if (!selectedEvent) return;
    bmapi
      .getEventActionById({
        business: selectedEvent.bussinessId,
        event: selectedEvent.id,
      })
      .then((ea) => {
        setEvents((list) =>
          list.map((event) => (event.id === ea.id ? ea : event))
        );
        return ea;
      });
  };

  const updateEventActionPar = useCallback(
    (eventAct) => {
      if (!eventAct) return;
      bmapi
        .getEventActionById({
          business: eventAct.bussinessId,
          event: eventAct.id,
        })
        .then((ea) => {
          setEvents(
            (list) => list.map((event) => (event.id === ea.id ? ea : event)) // Replace the matching event
          );
          return ea;
        });
    },
    [bmapi]
  );

  const openParticipants = () => {
    setParticipantsOpenDialog({ winners: false });
  };

  const openWinners = () => {
    setParticipantsOpenDialog({ winners: true });
  };

  const closeParticipants = () => {
    setParticipantsOpenDialog(null);
  };

  const openExchanges = () => {
    setExchangesOpenDialog(true);
  };

  const closeExchanges = () => {
    setExchangesOpenDialog(false);
  };

  useEffect(() => {
    if (!pendingEvents || pendingEvents.length === 0) return;

    const interval = setInterval(() => {
      checkPendingEvents();
    }, 10000); // 10 seconds

    return () => clearInterval(interval); // Cleanup on unmount or change
  }, [pendingEvents, checkPendingEvents]);

  const getStatusDescription = (customStatus) => {
    switch (customStatus || "") {
      case eaCustomStatuses.toBeStarted:
        return intl.formatMessage(eventActions.toBeStarted);
      case eaCustomStatuses.pending:
        return intl.formatMessage(eventActions.pending);
      case eaCustomStatuses.toBeAssigned:
        return intl.formatMessage(eventActions.toBeAssigned);
      case eaCustomStatuses.closed:
        return intl.formatMessage(eventActions.closed);
      default:
        return customStatus;
    }
  };

  const decodeStatus = (status) => {
    switch (status) {
      case exchangeStatuses.ok:
        return "file vincitori ricevuto";
      case exchangeStatuses.err:
        return "errore ricezione file vincitori";
      case exchangeStatuses.notSent:
        return "il file dei partecipanti non è stato inviato correttamente";
      case exchangeStatuses.processed:
        return "il file vincitori è già stato proecessato";
      case exchangeStatuses.wrongFile:
        return "il file vincitori ricevuto non è corretto";
      default:
        return status;
    }
  };

  const checkPendingEvents = useCallback(async () => {
    if (!pendingEvents?.length) return;

    setIsChecking(true); // Start spinner
    try {
      const peIds = pendingEvents.map((pe) => pe.id);
      const resp = await bmapi.checkCheckinReply(peIds);
      const newMessages = [];

      for (let rr of resp) {
        const received = pendingEvents.find(
          (pe) => pe.id == rr.event_action_id
        );
        if (received && (rr.status || "") !== "") {
          const message = `${received.title}, ${decodeStatus(rr.status)}`;
          newMessages.push({
            text: message,
            isSuccess: rr.status === exchangeStatuses.ok,
            message: rr.message,
          });
        }
        updateEventActionPar(received);
      }

      setSnackbarMessages((prev) => [...prev, ...newMessages]);
    } catch (e) {
      notifyError(getErrorMessageString(e, intl));
    } finally {
      setIsChecking(false); // Stop spinner
    }
  }, [pendingEvents, bmapi, intl, notifyError, updateEventActionPar]);

  const handleCloseSnackbar = () => {
    setSnackbarMessages([]); // Clear messages when closed
  };

  const canStart = (dt) => {
    try {
      return isAfter(new Date(), new Date(dt));
    } catch (e) {
      return false;
    }
  };

  return (
    <Container maxWidth="xl">
      <Title>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box>
            <FormattedMessage
              id="navigation.manager.checkinEvents"
              defaultMessage="Eventi"
            />
          </Box>
        </Box>
      </Title>
      <Container maxWidth="md">
        <Box mb={2}>
          <Card>
            <CardContent>
              <form onSubmit={update}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  flexWrap="wrap"
                  style={{ width: "50%" }}
                >
                  <DatePicker
                    label={intl.formatMessage(common.filterFrom)}
                    value={filters.from}
                    onChange={handleFilters("from")}
                    margin="normal"
                    clearable
                  />
                  <DatePicker
                    label={intl.formatMessage(common.filterTo)}
                    value={filters.to}
                    onChange={handleFilters("to")}
                    margin="normal"
                    minDate={filters.from}
                    clearable
                    onError={(e) => (e ? setError(true) : setError(false))}
                    maxDate={today}
                  />
                </Box>
                <Box display="flex" justifyContent="flex-end">
                  <FormControl margin="normal" style={{ marginRight: "16px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      disabled={error}
                    >
                      {intl.formatMessage(common.search)}
                    </Button>
                  </FormControl>
                  {pendingEvents?.length > 0 && (
                    <FormControl margin="normal">
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={error || isChecking}
                        onClick={checkPendingEvents}
                        endIcon={
                          isChecking ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : null
                        }
                      >
                        Aggiorna file
                      </Button>
                    </FormControl>
                  )}
                </Box>
              </form>
            </CardContent>
          </Card>
        </Box>
      </Container>
      <Box mt={2} mb={2}>
        <Button
          component={RouterLink}
          to={MANAGER_ROUTES.HOME}
          startIcon={<ArrowBack />}
        >
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>
      {smallScreen && (
        <React.Fragment>
          {events.length !== 0 ? (
            <Card>
              <List>
                {events.sort(byDate).map((ev, i) => (
                  <React.Fragment key={i}>
                    {i !== 0 && <Divider variant="inset" component="li" />}
                    <ListItem>
                      <ListItemText
                        primary={
                          <React.Fragment>
                            <Typography gutterBottom variant="h6">
                              {ev.title}
                            </Typography>
                            <Typography gutterBottom>
                              {intl.formatMessage(common.status)}:{" "}
                              {getStatusDescription(ev.custom_status)}
                            </Typography>
                            <Typography gutterBottom>
                              {intl.formatMessage(common.campaign)}:{" "}
                              {getCampaignLabel(ev.campaign_id)}
                            </Typography>
                          </React.Fragment>
                        }
                        secondary={`${intl.formatMessage(
                          common.from
                        )} ${new Date(ev.start_at).toLocaleDateString("it-IT", {
                          day: "numeric",
                          year: "numeric",
                          month: "short",
                        })} ${intl.formatMessage(common.to)} ${new Date(
                          ev.end_at
                        ).toLocaleDateString("it-IT", {
                          day: "numeric",
                          year: "numeric",
                          month: "short",
                        })}`}
                      />
                      <ListItemSecondaryAction>
                        <IconButton onClick={(e) => handleOpen(e, ev)}>
                          <MoreVert />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </React.Fragment>
                ))}
              </List>
            </Card>
          ) : (
            <Card>
              <CardContent style={{ padding: 16 }}>
                {intl.formatMessage(eventActions.noEvent)}
              </CardContent>
            </Card>
          )}
        </React.Fragment>
      )}
      {!smallScreen && (
        <React.Fragment>
          {events.length !== 0 ? (
            <Card>
              <TableContainer>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>{intl.formatMessage(common.name)}</TableCell>
                      <TableCell>
                        {intl.formatMessage(common.campaign)}
                      </TableCell>
                      <TableCell>{intl.formatMessage(common.date)}</TableCell>
                      <TableCell>{intl.formatMessage(common.status)}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableHead>
                  {events.sort(byDate).map((ev) => (
                    <TableBody key={ev.id}>
                      <TableRow>
                        <TableCell>{ev.title}</TableCell>
                        <TableCell>
                          {getCampaignLabel(ev.campaign_id)}
                        </TableCell>
                        <TableCell>
                          {ev.start_at === "0001-01-01T00:00:00Z"
                            ? "-"
                            : intl.formatDate(ev.start_at)}
                        </TableCell>
                        <TableCell>
                          {getStatusDescription(ev.custom_status)}
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={(e) => handleOpen(e, ev)}
                            style={{ padding: 0 }}
                          >
                            <MoreVert />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  ))}
                </Table>
              </TableContainer>
            </Card>
          ) : (
            <Card>
              <CardContent style={{ padding: 16 }}>
                {intl.formatMessage(eventActions.noEvent)}
              </CardContent>
            </Card>
          )}
        </React.Fragment>
      )}

      <Menu
        key={selectedEvent?.id}
        anchorEl={anchorEl}
        open={!!anchorEl}
        onClose={() => {
          setAnchorEl(null);
          //setEventId(null);
          setSelectedEvent(null);
        }}
        keepMounted
        onClick={() => setAnchorEl(null)}
      >
        {selectedEvent &&
          (selectedEvent.custom_status || eaCustomStatuses.toBeStarted) ===
            eaCustomStatuses.toBeStarted &&
          canStart(selectedEvent.end_at) && (
            <MenuItem onClick={() => setShowStartDrawAlert(true)}>
              <ListItemIcon>
                <PlayArrow />
              </ListItemIcon>
              <Typography variant="inherit">
                {intl.formatMessage(eventActions.startDraw)}
              </Typography>
            </MenuItem>
          )}

        {(selectedEvent?.custom_status === eaCustomStatuses.closed ||
          selectedEvent?.custom_status === eaCustomStatuses.toBeAssigned) && (
          <MenuItem onClick={openWinners}>
            <ListItemIcon>
              <People />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(eventActions.winnersList)}
            </Typography>
          </MenuItem>
        )}

        {selectedEvent?.custom_status === eaCustomStatuses.toBeAssigned && (
          <MenuItem onClick={() => setStopOpenDialog(true)}>
            <ListItemIcon>
              <Stop />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(eventActions.stopDraw)}
            </Typography>
          </MenuItem>
        )}

        <MenuItem onClick={openParticipants}>
          <ListItemIcon>
            <PeopleAltOutlined />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(eventActions.participantsList)}
          </Typography>
        </MenuItem>

        <MenuItem onClick={openExchanges}>
          <ListItemIcon>
            <ListSharp />
          </ListItemIcon>
          <Typography variant="inherit">
            {intl.formatMessage(eventActions.viewExchanges)}
          </Typography>
        </MenuItem>

        {selectedEvent?.custom_status === eaCustomStatuses.closed && (
          <MenuItem onClick={() => setReopenOpenDialog(true)}>
            <ListItemIcon>
              <Replay />
            </ListItemIcon>
            <Typography variant="inherit">
              {intl.formatMessage(eventActions.reopenDraw)}
            </Typography>
          </MenuItem>
        )}

        {selectedEvent?.custom_status &&
          selectedEvent.custom_status !== eaCustomStatuses.toBeStarted &&
          selectedEvent.custom_status !== eaCustomStatuses.closed && (
            <MenuItem onClick={() => setShowCancelDrawAlert(true)}>
              <ListItemIcon>
                <Cancel />
              </ListItemIcon>
              <Typography variant="inherit">
                {intl.formatMessage(eventActions.reinitializeDraw)}
              </Typography>
            </MenuItem>
          )}
      </Menu>

      <Confirm
        open={showStartDrawAlert}
        onConfirm={startDraw}
        onCancel={() => {
          setSelectedEvent(null);
          setShowStartDrawAlert(false);
        }}
        text={
          "Confermi di voler iniziare l'estrazione e inviare il file dei nominativi?"
        }
      />

      <Confirm
        open={showCancelDrawAlert}
        onConfirm={cancelDraw}
        onCancel={() => {
          setSelectedEvent(null);
          setShowCancelDrawAlert(false);
        }}
        text={
          "La reinizializzazione dell'estrazione comporta il reinvio del file dei partecipanti e la creazione di una nuova lista dei vincitori"
        }
      />

      <Confirm
        open={stopOpenDialog}
        onConfirm={stopDraw}
        onCancel={() => {
          setSelectedEvent(null);
          setStopOpenDialog(false);
        }}
        text={"Confermi di voler chiudere l'estrazione?"}
      />

      <Confirm
        open={reopenOpenDialog}
        onConfirm={reopenDraw}
        onCancel={() => {
          setSelectedEvent(null);
          setReopenOpenDialog(false);
        }}
        text={"Confermi di voler riaprire l'estrazione?"}
      />

      {/* Participants Dialog */}
      <Dialog
        open={!!participantsOpenDialog}
        onClose={closeParticipants}
        fullWidth
        maxWidth="xl"
      >
        <DialogTitle>
          {selectedEvent?.title} -{" "}
          {intl.formatMessage(
            participantsOpenDialog?.winners
              ? eventActions.winnersList
              : eventActions.participantsList
          )}
          <IconButton
            aria-label="close"
            onClick={closeParticipants}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {participantsOpenDialog?.winners && (
            <CheckInWinners
              eventAction={selectedEvent}
              isClosed={
                selectedEvent?.custom_status === eaCustomStatuses.closed
              }
            />
          )}
          {participantsOpenDialog && !participantsOpenDialog.winners && (
            <CheckInParticipants id={selectedEvent?.id} />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeParticipants} color="primary">
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>

      {/* exchanges Dialog */}
      <Dialog open={exchangesOpenDialog} onClose={closeExchanges} fullWidth>
        <DialogTitle>
          {selectedEvent?.title} - Flussi
          <IconButton
            aria-label="close"
            onClick={closeExchanges}
            style={{ position: "absolute", right: 8, top: 8 }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <CheckInExchanges id={selectedEvent?.id} />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeExchanges} color="primary">
            {intl.formatMessage(common.close)}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarMessages.length > 0}
        onClose={handleCloseSnackbar}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Box>
          {snackbarMessages.map((msg, index) => (
            <SnackbarContent
              key={index}
              style={{
                backgroundColor: msg.isSuccess ? "green" : "red", // Green for success, red for error
                color: "white", // White text
                marginBottom: "5px", // Spacing between stacked messages
              }}
              message={
                <Box>
                  <Typography style={{ fontWeight: "bold" }}>
                    {msg.text}
                  </Typography>
                  {msg.message && (
                    <Typography style={{ fontWeight: "normal" }}>
                      ({msg.message})
                    </Typography>
                  )}
                </Box>
              }
            />
          ))}
        </Box>
      </Snackbar>
    </Container>
  );
}
