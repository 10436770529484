import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Divider,
  MenuItem,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import { format } from "date-fns";
import Title from "../../ui/Title";
import { ArrowBack, CloudDownload } from "@material-ui/icons";
import { common } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import utils, { sanitizeForFileName } from "../../utils/utils";

const byEventDate = (a, b) => {
  const dateA = format(new Date(a.start_date), "yyyy-MM-dd");
  const dateB = format(new Date(b.start_date), "yyyy-MM-dd");
  const nameA = a.name?.toUpperCase() || "";
  const nameB = b.name?.toUpperCase() || "";
  const timeA = a.start_hour;
  const timeB = b.start_hour;

  if (dateA < dateB) {
    return 1;
  } else if (dateA > dateB) {
    return -1;
  } else if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  } else if (timeA < timeB) {
    return 1;
  } else if (timeA > timeB) {
    return -1;
  } else {
    return 0;
  }
};

function ShowFairReservations({ currentData }) {
  if (!(currentData?.list?.length === 3)) return null;
  const onsite = currentData.list[0];
  const online = currentData.list[1];
  const booked = currentData.list[2];

  return (
    <Card>
      <CardContent>
        <Box my={2}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="body1">Iscrizioni in presenza</Typography>
            <Typography variant="body1" fontWeight="bold">
              {onsite}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            mb={1}
          >
            <Typography variant="body1">Iscrizioni in streaming</Typography>
            <Typography variant="body1" fontWeight="bold">
              {online}
            </Typography>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="body1">Previsioni</Typography>
            <Typography variant="body1" fontWeight="bold">
              {booked}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
}

function ShowFairPasses({ currentData, eventList, onDownload }) {
  // Sort eventList by start_date in ascending order
  const sortedEvents = [...eventList].sort(
    (a, b) => new Date(a.start_date) - new Date(b.start_date)
  );

  // Group events by start_date
  const groupedEvents = sortedEvents.reduce((acc, event) => {
    //const date = new Date(event.start_date).toLocaleDateString("it-IT");
    const date = event.start_date;
    if (!acc[date]) {
      acc[date] = {};
    }

    // Parse extra field if it's a JSON string
    let extraData = {};
    try {
      extraData = JSON.parse(event.extra);
    } catch (error) {
      console.warn("Failed to parse extra field:", event.extra);
    }

    const room = extraData?.room || " - "; // Use " - " if room is not specified
    if (!acc[date][room]) {
      acc[date][room] = [];
    }
    acc[date][room].push(event);
    return acc;
  }, {});

  const cleanRoomId = (txt) => (!txt || txt === " - " ? "" : txt);

  return (
    <Box>
      {Object.keys(groupedEvents).map((date) => (
        <Box key={date} mb={3}>
          {/* Display the date */}
          <Typography variant="h6" gutterBottom>
            {new Date(date).toLocaleDateString("it-IT")}
          </Typography>

          {/* Iterate through rooms on this date */}
          {Object.keys(groupedEvents[date]).map((room) => (
            <Box key={`${date}-${room}`} style={{ marginBottom: "10px" }}>
              <Card
                key={`${date}-${room}`}
                sx={{ padding: 2 }} // Add margin between cards
              >
                <CardContent>
                  {/* Room Name */}
                  <Typography
                    variant="h6"
                    sx={{ textAlign: "left", marginBottom: 2 }}
                  >
                    {room}
                  </Typography>

                  {/* List items in the room sorted by start_hour */}
                  {groupedEvents[date][room]
                    .sort((a, b) => a.start_hour.localeCompare(b.start_hour))
                    .map((item, index, arr) => {
                      const current = currentData?.list?.find(
                        (c) => c.campaign_id === item.id
                      );
                      const count = current ? current.count : 0;

                      return (
                        <React.Fragment key={item.id}>
                          <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            mb={1}
                          >
                            {/* Start Hour */}
                            <Typography variant="body2" sx={{ width: "20%" }}>
                              {item.start_hour}
                            </Typography>
                            {/* Name aligned to the left */}
                            <Typography
                              variant="body2"
                              sx={{
                                flexGrow: 1,
                                textAlign: "left",
                                marginLeft: 2,
                              }}
                            >
                              {item.name}
                            </Typography>
                            {/* Count */}
                            <Typography
                              variant="body2"
                              fontWeight="bold"
                              sx={{ width: "20%", textAlign: "right" }}
                            >
                              {count}
                            </Typography>
                          </Box>
                          {/* Divider between items */}
                          {index < arr.length - 1 && <Divider />}
                        </React.Fragment>
                      );
                    })}
                  {onDownload && (
                    <Button
                      onClick={() => onDownload(cleanRoomId(room), date)}
                      startIcon={<CloudDownload />}
                    >
                      Scarica Prenotazioni
                    </Button>
                  )}
                </CardContent>
              </Card>
            </Box>
          ))}
        </Box>
      ))}
    </Box>
  );
}

export default function ShowReservations() {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [reservations, setReservations] = useState(null);
  const [eventReservations, setEventReservations] = useState(null);
  const [fairSelected, setFairSelected] = useState(null);
  const [fairList, setFairList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [tab, setTab] = useState("merchant");

  const data = [
    {
      enabled: tab === "merchant",
      list: reservations,
      event: false,
    },
    {
      enabled: tab === "events",
      list: eventReservations,
      event: true,
    },
  ];

  const currentData = data.find((i) => i.enabled);

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const businessId = bmapi.getUserInfo().business.id;

  /*const loadReservationList = useCallback(() => {
    console.log("kkkkkkkkk", fairSelected);
    if (fairSelected == null) return;
    startLoading();
    bmapi
      .getEOrdersResume({
        business: businessId,
        content: fairSelected?.campaign_data?.id,
      })
      .then((resp) => {
        setReservations(resp || []);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    stopLoading();
  }, [
    bmapi,
    intl,
    notifyError,
    startLoading,
    stopLoading,
    businessId,
    fairSelected,
  ]);*/

  const loadReservationList = (fair) => {
    if (fair == null) return;
    startLoading();
    bmapi
      .getEOrdersResume({
        business: businessId,
        content: fair?.campaign_data?.id,
      })
      .then((resp) => {
        setReservations(resp || []);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      });
    stopLoading();
  };

  const loadEventReservationList = useCallback(
    (fair, event) => {
      if (fair) {
        startLoading();
        bmapi
          .getMainEventPassesResume(fair.id, fair.business_id, event?.id)
          .then((resp) => {
            setEventReservations(resp || []);
          })
          .catch((e) => {
            notifyError(getErrorMessageString(e, intl));
          })
          .finally(() => {
            stopLoading();
          });
      }
    },
    [bmapi, intl, notifyError, startLoading, stopLoading]
  );

  const loadFairs = useCallback(() => {
    startLoading();
    bmapi
      .getBusinessCampaigns({})
      .then((resp) => {
        const filtered = resp.filter(
          (c) =>
            c?.campaign_data?.type === "CAMPAIGN_EVENT_PASS" &&
            !c?.campaign_data?.rules?.main_event_id
        );
        setFairList(filtered || []);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  const loadEvents = (fair) => {
    startLoading();
    bmapi
      .getEventsByCampaign(fair)
      .then((resp) => {
        resp.sort(byEventDate);
        setEventList(resp || []);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };
  useEffect(() => {
    loadFairs();
  }, [loadFairs]);
  const changeTab = (tab) => {
    setTab(tab);
  };

  const download = () => {
    startLoading();
    bmapi
      .getCampusReservationsXls({
        business: bmapi.getUserInfo().business.id,
        content: fairSelected?.campaign_data?.id,
      })
      .then((blob) => {
        const fileName = fairSelected?.campaign_data?.name ?? "iscrizioni";
        utils.download(blob, sanitizeForFileName(fileName, "xlsx"));
      })
      .catch((e) => {
        notifyError("" + e);
      })
      .finally(stopLoading);
  };

  /*const downloadEventsGlobal = () => {
    if (!fairSelected?.campaign_data?.id) return;
    startLoading();
    bmapi
      .getMainEventPassesCsv(fairSelected?.campaign_data?.id, {
        business: bmapi.getUserInfo().business.id,
      })
      .then((blob) => {
        utils.download(blob, "prenotazioni_eventi.csv");
      })
      .catch((e) => {
        notifyError("" + e);
      })
      .finally(stopLoading);
  };*/

  const downloadEvents = (roomId, date) => {
    if (!fairSelected?.campaign_data?.id) return;
    const strDate = format(new Date(date), "yyyy-MM-dd");
    startLoading();
    bmapi
      .getCampusMainEventPassesXls(fairSelected?.campaign_data?.id, {
        business: bmapi.getUserInfo().business.id,
        room: roomId,
        date: date,
        status: "0",
      })
      .then((blob) => {
        utils.download(
          blob,
          sanitizeForFileName(roomId + "_" + strDate, "xlsx")
        );
      })
      .catch((e) => {
        notifyError("" + e);
      })
      .finally(stopLoading);
  };

  return (
    <Container maxWidth="sm">
      <Title>Prenotazioni</Title>

      {/* fairs dropdown */}
      <Box mt={2}>
        <Box display="flex">
          <TextField
            required
            fullWidth
            select
            label="Salone"
            value={fairSelected || ""} // Handle null or undefined value
            onChange={(e) => {
              const campaignId = e.target.value?.campaign_data?.id;
              setFairSelected(e.target.value);
              loadReservationList(e.target.value);
              loadEvents(campaignId);
              loadEventReservationList(e.target.value?.campaign_data);
            }}
          >
            {fairList.map((option) => (
              <MenuItem key={option.campaign_id} value={option}>
                {option.name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>

      {fairSelected && (
        <Box>
          <Tabs
            value={tab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            onChange={(_, v) => {
              changeTab(v);
            }}
          >
            <Tab label="Iscrizioni" value="merchant" />
            <Tab label="Incontri" value="events" />
          </Tabs>

          <Box mt={currentData.event ? 8 : 2} style={{ clear: "right" }}>
            {/* reservations */}
            {!currentData.event && (
              <ShowFairReservations currentData={currentData} />
            )}

            {/* passes */}
            {currentData.event && (
              <ShowFairPasses
                currentData={currentData}
                eventList={eventList}
                onDownload={downloadEvents}
              />
            )}
          </Box>

          <Box my={2}>
            {tab === "merchant" && (
              <Button onClick={download} startIcon={<CloudDownload />}>
                Scarica Iscrizioni
              </Button>
            )}
          </Box>
        </Box>
      )}
      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>
    </Container>
  );
}
