import { defineMessages } from "react-intl";

export const eventActions = defineMessages({
  actionLabel: {
    id: "component.eventActions.actionLabel",
    defaultMessage: "Etichetta pulsante azione",
  },
  addEvent: {
    id: "component.eventActions.addEvent",
    defaultMessage: "Aggiungi evento",
  },
  banner: {
    id: "component.eventActions.banner",
    defaultMessage: "Banner",
  },
  bonusCategory: {
    id: "component.eventActions.bonusCategory",
    defaultMessage: "Bonus categorie merceologiche",
  },
  bonusEaster: {
    id: "component.eventActions.bonusEaster",
    defaultMessage: "Bonus Pasqua",
  },
  bonusPlay: {
    id: "component.eventActions.bonusPlay",
    defaultMessage: "Bonus giocata",
  },
  bonusPoints: {
    id: "component.eventActions.bonusPoints",
    defaultMessage: "Bonus punti",
  },
  checkin: {
    id: "component.eventActions.checkin",
    defaultMessage: "Checkin",
  },
  compositePlayCoupons: {
    id: "component.eventActions.compositePlayCoupons",
    defaultMessage: "Giocata composita: multi-bollino",
  },
  compositePlayStamps: {
    id: "component.eventActions.compositePlayStamps",
    defaultMessage: "Giocata composita: multi-cartolina",
  },
  enabled: {
    id: "component.eventActions.enabled",
    defaultMessage: "Abilitato",
  },
  eventClone: {
    id: "component.eventActions.eventClone",
    defaultMessage: "Duplica evento",
  },
  eventCreation: {
    id: "component.eventActions.eventCreation",
    defaultMessage: "Creazione evento",
  },
  eventEdit: {
    id: "component.eventActions.eventEdit",
    defaultMessage: "Modifica evento",
  },
  fe: {
    id: "component.eventActions.fe",
    defaultMessage: "Visibile su front-end",
  },
  filterType: {
    id: "component.eventActions.filterType",
    defaultMessage: "Filtra per tipo di evento",
  },
  greenPlay: {
    id: "component.eventActions.greenPlay",
    defaultMessage: "Giocata green",
  },
  mktRequired: {
    id: "component.eventActions.mktRequired",
    defaultMessage: "Visibile solo con marketing diretto",
  },
  minValue: {
    id: "component.eventActions.minValue",
    defaultMessage: "Inserire un valore pari o superiore a 1",
  },
  multiplyPlay: {
    id: "component.eventActions.multiplyPlay",
    defaultMessage: "Giocata multipla",
  },
  noEvent: {
    id: "component.eventActions.noEvent",
    defaultMessage: "Nessun evento presente",
  },
  noType: {
    id: "component.eventActions.noType",
    defaultMessage: "Nessun tipo",
  },
  preCheckin: {
    id: "component.eventActions.preCheckin",
    defaultMessage: "Pre checkin",
  },
  scanner: {
    id: "component.eventActions.scanner",
    defaultMessage: "Scanner",
  },
  test: {
    id: "component.eventActions.test",
    defaultMessage: "Test",
  },
  ////////////////////////////////////////////////////////////////
  successCreate: {
    id: "component.eventActions.successCreate",
    defaultMessage: "Evento creato con successo",
  },
  successClone: {
    id: "component.eventActions.successClone",
    defaultMessage: "Evento duplicato con successo",
  },
  successDelete: {
    id: "component.eventActions.successDelete",
    defaultMessage: "Evento eliminato con successo",
  },
  successEdit: {
    id: "component.eventActions.successEdit",
    defaultMessage: "Evento modificato con successo",
  },
  toBeStarted: {
    id: "component.eventActions.toBeStarted",
    defaultMessage: "Da iniziare",
  },
  pending: {
    id: "component.eventActions.pending",
    defaultMessage: "In attesa",
  },
  toBeAssigned: {
    id: "component.eventActions.toBeAssigned",
    defaultMessage: "Da assegnare",
  },
  closed: {
    id: "component.eventActions.closed",
    defaultMessage: "Chiusa",
  },
  startDraw: {
    id: "component.eventActions.startDraw",
    defaultMessage: "Inizia estrazione",
  },
  stopDraw: {
    id: "component.eventActions.stopDraw",
    defaultMessage: "Termina estrazione",
  },
  reinitializeDraw: {
    id: "component.eventActions.reinitializeDraw",
    defaultMessage: "Reinizializza l'estrazione",
  },
  reopenDraw: {
    id: "component.eventActions.reopenDraw",
    defaultMessage: "Riapri estrazione",
  },
  participantsList: {
    id: "component.eventActions.participantsList",
    defaultMessage: "Lista partecipanti",
  },
  winnersList: {
    id: "component.eventActions.winnersList",
    defaultMessage: "Lista vincitori",
  },
  viewExchanges: {
    id: "component.eventActions.viewExchanges",
    defaultMessage: "Log flussi",
  },
});
