import React, { useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import classNames from "classnames";

import {
  Avatar,
  Badge,
  Card,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@material-ui/core";
import { Adjust as AdjustIcon } from "@material-ui/icons";

import { IconsMap, useCampaigns } from "../../utils/campaigns";
import styles from "../../utils/styles";

function StoreCampaignElement({ campaign }) {
  const intl = useIntl();
  const classes = styles.useStyles();

  const CampaignIcon = IconsMap[campaign.type];

  const dateInfo = () => {
    if (campaign.isExpired) {
      return intl.formatMessage(
        {
          id: "component.campaigns.expired",
          defaultMessage: "Scaduta il {expirationDate, date, medium}",
        },
        { expirationDate: new Date(campaign.expiration_date) }
      );
    } else if (campaign.isFuture) {
      return intl.formatMessage(
        {
          id: "component.campaigns.startDate",
          defaultMessage: "Inizio campagna: {startDate, date, medium}",
        },
        { startDate: new Date(campaign.start_date) }
      );
    }
    return intl.formatMessage(
      {
        id: "component.campaigns.expiration",
        defaultMessage: "Scadenza: {expirationDate, date, medium}",
      },
      { expirationDate: new Date(campaign.expiration_date) }
    );
  };

  return (
    <React.Fragment>
      <ListItem>
        <ListItemAvatar classes={{ root: classes.listItemAvatar }}>
          <Badge
            color="error"
            overlap="circle"
            badgeContent=""
            invisible={true}
          >
            <Avatar
              src={campaign.avatar_url || ""}
              className={classNames(classes.cardIcon, classes.cardIconLarge, {
                [classes.disabled]: campaign.toSign || campaign.isExpired,
              })}
            >
              {CampaignIcon ? <CampaignIcon /> : <AdjustIcon />}
            </Avatar>
          </Badge>
        </ListItemAvatar>
        <ListItemText
          primary={
            <React.Fragment>
              <Typography
                variant="caption"
                display="block"
                color={campaign.loop_campaign ? "primary" : "inherit"}
              >
                {campaign.business_owner_name}
              </Typography>
            </React.Fragment>
          }
          secondary={dateInfo()}
        />
        <ListItemSecondaryAction></ListItemSecondaryAction>
      </ListItem>
    </React.Fragment>
  );
}

export default function StoreCampaigns() {
  const { campaigns, loadCampaigns } = useCampaigns();

  useEffect(() => {
    loadCampaigns();
  }, [loadCampaigns]);

  const renderCampaignElement = (campaign) => (
    <React.Fragment key={campaign.id}>
      <Divider variant="inset" component="li" />
      <StoreCampaignElement key={campaign.id} campaign={campaign} />
    </React.Fragment>
  );

  const byDate = (a, b) => {
    if (a.isActive || a.isExpired)
      return new Date(a.expiration_date) > new Date(b.expiration_date);
    else return new Date(a.start_date) > new Date(b.start_date);
  };

  if (!campaigns?.length) {
    return (
      <Typography gutterBottom>
        <FormattedMessage
          id="component.campaigns.noCampaign"
          defaultMessage="Nessuna campagna presente."
        />
      </Typography>
    );
  }

  const mainCampaigns = campaigns?.filter(
    (camp) =>
      (camp.isAvailable || camp.isActive || camp.isFuture) &&
      camp.campaign_data?.rules?.main_event_id === ""
  );
  console.log("--fffffff--", mainCampaigns);

  return (
    <Card>
      <List>{mainCampaigns.sort(byDate).map(renderCampaignElement)}</List>
    </Card>
  );
}
