import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { common } from "../messages/common";
import { Button, Container, MenuItem, TextField } from "@material-ui/core";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import { form } from "../messages";

const PushNotificationOneUser = ({
  userId,
  onPushSent,
  defaultTitle = "",
  defaultMessage = "",
}) => {
  const intl = useIntl();
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    notifySuccess,
  } = useBmapi();
  const [clients, setClients] = useState([]);

  const [values, setValues] = useState({
    client_id: "",
    title: defaultTitle,
    message: defaultMessage,
  });

  const handleValue = useCallback(
    (label) => (e) => {
      ((val) => setValues((v) => ({ ...v, [label]: val })))(e.target.value);
    },
    []
  );

  useEffect(() => {
    startLoading();
    if (bmapi) {
      bmapi
        .getClients()
        .then((resp) => {
          const filtered = (resp || []).filter((c) => c.additional_data);
          setClients(filtered);
          if (filtered.length > 0) {
            setValues((v) => ({ ...v, client_id: filtered[0].id }));
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const send = () => {
    userId = "abb4c3a9-197d-476b-b1c2-8625ac365479";
    const payload = { ...values, users: [userId] };
    startLoading();
    let isSuccess = false;
    bmapi
      .sendPushNotification(payload)
      .then((resp) => {
        stopLoading();
        isSuccess = resp?.results?.length > 0 && resp.results[0] === "ok";
        if (isSuccess) {
          notifySuccess("Notifica push inviata correttamente");
        } else {
          notifySuccess("Non si è potuto inviare la notifica push");
        }
      })
      .then(() => {
        setValues((v) => ({ ...v, title: "", message: "" }));
        if (onPushSent) {
          onPushSent(isSuccess);
        }
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  return (
    <Container maxWidth="sm" style={{ padding: 0 }}>
      <TextField
        required
        fullWidth
        select
        label={intl.formatMessage(common.app)}
        value={values.client_id}
        InputLabelProps={{
          shrink: values.client_id,
        }}
        onChange={handleValue("client_id")}
      >
        {clients
          .filter((c) => c.additional_data)
          .map((c) => (
            <MenuItem key={c.id} value={c.id}>
              {c.description}
            </MenuItem>
          ))}
      </TextField>
      <TextField
        margin="normal"
        required
        label={intl.formatMessage(form.title)}
        fullWidth
        value={values.title}
        onChange={handleValue("title")}
        InputLabelProps={{
          shrink: values.title,
        }}
      />
      <TextField
        label={intl.formatMessage(common.message)}
        multiline
        rows={4}
        variant="outlined"
        fullWidth
        margin="normal"
        value={values.message}
        onChange={handleValue("message")}
        required
      />
      <Button
        variant="contained"
        color="primary"
        onClick={send}
        disabled={!values.client_id || !values.message || !values.title}
      >
        {intl.formatMessage(common.send)}
      </Button>
    </Container>
  );
};

export default PushNotificationOneUser;
