import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  Divider,
  Button,
} from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import { common } from "../messages";
import { ArrowBack, ArrowForward, HelpRounded } from "@material-ui/icons";
import utils from "../utils/utils";

const CheckInExchanges = ({ id }) => {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [folders, setFolders] = useState(null);
  const byName = (a, b) => b.name.localeCompare(a.name);

  const loadExchanges = useCallback(() => {
    if (!id) return; // Prevent API call if id is undefined

    startLoading();

    bmapi
      .getCheckinExchanges({ eventAction: id })
      .then((resp) => setFolders(resp ? [...resp].sort(byName) : [])) // Sort alphabetically
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(() => stopLoading());
  }, [bmapi, id, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    loadExchanges();
  }, [loadExchanges]);

  if (!folders) {
    return <Typography>{intl.formatMessage(common.noElement)}</Typography>;
  }

  if (!folders.length) {
    return (
      <Box mt={2}>
        <Typography>Non sono stati scambiati file</Typography>
      </Box>
    );
  }

  const formatDateTime = (input) => {
    const year = input.substring(0, 4);
    const month = input.substring(4, 6);
    const day = input.substring(6, 8);
    const hours = input.substring(9, 11);
    const minutes = input.substring(11, 13);
    const seconds = input.substring(13, 15);

    return `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
  };

  console.log("dddddd", folders);

  return (
    <Box mt={2}>
      <List>
        {folders.map((folder, index) => (
          <React.Fragment key={index}>
            <ListItem
              alignItems="flex-start"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Box>
                <ListItemText
                  primary={formatDateTime(folder.name)}
                  secondary={
                    <Typography component="div">
                      {" "}
                      {/* 🔥 Prevents <ul> inside <p> issue */}
                      <ExchangeFiles
                        files={folder.sent_files}
                        folderPath={folder.folder_path}
                        subPath="sent"
                        icon={<ArrowForward />}
                      />
                      <ExchangeFiles
                        files={folder.received_files}
                        folderPath={folder.folder_path}
                        subPath="received"
                        icon={<ArrowBack />}
                      />
                      <ExchangeFiles
                        files={folder.other_files}
                        folderPath={folder.folder_path}
                        icon={<HelpRounded />}
                      />
                    </Typography>
                  }
                />
              </Box>
              {folder.has_winners && (
                <Button variant="text" color="primary" onClick={() => {}}>
                  Estratti
                </Button>
              )}
            </ListItem>

            {index < folders.length - 1 && <Divider component="li" />}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};

const ExchangeFiles = ({ files, folderPath, subPath, icon }) => {
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const intl = useIntl();

  const handleButtonClick = (filename) => {
    const filePath = subPath
      ? `${folderPath}/${subPath}/${filename}`
      : `${folderPath}/${filename}`;
    startLoading();
    bmapi
      .getCheckinExchangesFile({ path: filePath })
      .then((blob) => utils.download(blob, filename))
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(() => {
        stopLoading();
      });
  };

  if (!files || !Array.isArray(files)) return null;

  return (
    <List>
      {files.map((file, index) => (
        <ListItem key={index} dense>
          {icon}
          <Button
            onClick={() => handleButtonClick(file)}
            color="primary"
            variant="text"
            style={{
              textTransform: "none",
              padding: 0,
              minWidth: "auto",
              justifyContent: "flex-start",
              display: "flex",
            }}
          >
            {file}
          </Button>
        </ListItem>
      ))}
    </List>
  );
};

export default CheckInExchanges;
