import {
  Box,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import { common } from "../messages";
import { parseISO, format } from "date-fns";

const CheckInParticipants = ({ id }) => {
  const intl = useIntl();
  const { bmapi, notifyError, startLoading, stopLoading } = useBmapi();
  const [participants, setParticipants] = React.useState(null);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const loadParticipants = useCallback(() => {
    startLoading();
    if (bmapi) {
      const params = {
        eventAction: id,
      };

      bmapi
        .getCheckinParticipants(params)
        .then((resp) => {
          if (!resp) {
            resp = [];
            setParticipants(resp);
          } else {
            setParticipants(resp);
          }
        })
        .catch((e) => {
          notifyError(getErrorMessageString(e, intl));
        })
        .finally(() => {
          stopLoading();
        });
    }
  }, [bmapi, intl, notifyError, startLoading, stopLoading, id]);

  useEffect(() => {
    loadParticipants();
  }, [loadParticipants]);

  if (participants === null) {
    return <Typography>{intl.formatMessage(common.noElement)}</Typography>;
  }

  const formatDate = (dt) => {
    if (!dt || dt === "") return "";
    try {
      const parsedDate = parseISO(dt);
      return format(parsedDate, "dd/MM/yyyy HH:mm:ss");
    } catch (_) {
      return dt;
    }
  };

  return (
    <Box mt={0}>
      <Box mt={0}>
        <Typography>{participants?.length} partecipanti</Typography>
      </Box>
      <Box mt={2}>
        {!smallScreen && (
          <TableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Data e ora check-in</TableCell>
                  <TableCell>{intl.formatMessage(common.email)}</TableCell>
                  <TableCell>{intl.formatMessage(common.name)}</TableCell>
                  <TableCell>{intl.formatMessage(common.lastName)}</TableCell>
                  <TableCell>{intl.formatMessage(common.mobile)}</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {participants?.map((d) => (
                  <TableRow key={d.id} hover>
                    <React.Fragment>
                      <TableCell>{formatDate(d.timestamp)}</TableCell>
                      <TableCell>{d.email}</TableCell>
                      <TableCell>{d.first_name}</TableCell>
                      <TableCell>{d.last_name}</TableCell>
                      <TableCell>{d.mobile}</TableCell>
                    </React.Fragment>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        {smallScreen && (
          <List>
            {participants?.map((d, i) => (
              <Card key={i}>
                {i !== 0 && <Divider variant="inset" component="li" />}
                <ListItem>
                  <ListItemText
                    primary={
                      <React.Fragment>
                        <Typography gutterBottom>
                          {formatDate(d.timestamp)}
                        </Typography>
                        <Typography gutterBottom variant="h6">
                          {d.first_name} {d.last_name}
                        </Typography>
                        <Typography gutterBottom>{d.email}</Typography>
                        <Typography
                          gutterBottom
                          style={{ textDecoration: "underline" }}
                          href={`tel://${d.mobile}`}
                        >
                          {d.mobile}
                        </Typography>
                      </React.Fragment>
                    }
                  ></ListItemText>
                </ListItem>
              </Card>
            ))}
          </List>
        )}
      </Box>
    </Box>
  );
};

export default CheckInParticipants;
