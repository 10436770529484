import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { useBmapi } from "../../utils/bmapi-context";
import { getErrorMessageString } from "../../utils/errors";
import { useIntl } from "react-intl";
import Title from "../../ui/Title";
import { ArrowBack, CloudDownload, CloudUpload } from "@material-ui/icons";
import { common } from "../../messages";
import { MANAGER_ROUTES } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import utils, { sanitizeForFileName } from "../../utils/utils";

// Helper to group matches by campaign_id
const groupByCampaign = (matches) => {
  const grouped = {};
  matches.forEach((match) => {
    const { campaign_id } = match;
    if (!grouped[campaign_id]) {
      grouped[campaign_id] = [];
    }
    grouped[campaign_id].push(match);
  });

  // Sort the days within each campaign (sorting by string 'yyyymmdd')
  return Object.entries(grouped)
    .map(([campaign_id, events]) => ({
      campaign_id,
      campaign_name: events[0].campaign_name,
      events: events.sort((a, b) => a.day.localeCompare(b.day)), // Sort days as strings (yyyymmdd)
      firstDay: events.length > 0 ? events[0].day : "", // Track first day for sorting cards
    }))
    .sort((a, b) => a.firstDay.localeCompare(b.firstDay)); // Sort cards by the first day of each campaign
};

export default function ManageCampusCertificates() {
  const {
    bmapi,
    notifyError,
    startLoading,
    stopLoading,
    notifySuccess,
  } = useBmapi();
  const intl = useIntl();
  const history = useHistory();
  const [matches, setMatches] = useState(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogData, setDialogData] = useState(null);

  const fileInputRef = useRef(null); // Reference for the hidden file input

  const goToHome = () => {
    history.push(MANAGER_ROUTES.HOME_MENU);
  };

  const loadMatches = useCallback(() => {
    startLoading();
    bmapi
      .getCampusCertificatesResumeV2()
      .then((resp) => {
        setMatches(resp || []);
      })
      .catch((e) => {
        notifyError(getErrorMessageString(e, intl));
      })
      .finally(stopLoading);
  }, [bmapi, intl, notifyError, startLoading, stopLoading]);

  useEffect(() => {
    loadMatches();
  }, [loadMatches]);

  const download = (event) => {
    console.log(event);
    startLoading();
    bmapi
      .getCampusCertificatesCsv({
        campaign_id: event.campaign_id,
        day: event.day,
      })
      .then((blob) => {
        const now = new Date();
        const timestamp = `${now.getFullYear()}${(now.getMonth() + 1)
          .toString()
          .padStart(2, "0")}${now
          .getDate()
          .toString()
          .padStart(2, "0")}_${now
          .getHours()
          .toString()
          .padStart(2, "0")}${now
          .getMinutes()
          .toString()
          .padStart(2, "0")}${now.getSeconds().toString().padStart(2, "0")}`;

        const sanitizedCampaignName = event.campaign_name.replace(/ /g, "_");
        const filename = sanitizeForFileName(
          `${sanitizedCampaignName}_${timestamp}.csv`
        );

        utils.download(blob, filename);
      })
      .catch((e) => {
        notifyError("" + e);
      })
      .finally(stopLoading);
  };

  const upload = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    startLoading();

    const campaignId = fileInputRef.current.dataset.campaignId;
    const day = fileInputRef.current.dataset.day;
    const campaignName = fileInputRef.current.dataset.campaignName;

    const formData = new FormData();
    formData.append("file", file);

    bmapi
      .uploadCampusCertificatesV2({ campaign: campaignId, day: day }, formData)
      .then((resp) => {
        setDialogData({ campaignName, resp: resp }); // Set campaign name for dialog
        setDialogOpen(true); // Open dialog
      })
      .catch((err) => {
        notifyError(getErrorMessageString(err, intl));
      })
      .finally(stopLoading);

    // Clear the file input value after upload to allow re-uploads
    e.target.value = null;
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleProceed = (uploadId) => {
    startLoading();
    bmapi
      .processUpload({ upload: uploadId })
      .then(() => {
        notifySuccess(
          `File per "${dialogData.campaignName}" caricato correttamente`
        );
        loadMatches();
      })
      .catch((err) => {
        notifyError(getErrorMessageString(err, intl));
      })
      .finally(stopLoading);
    setDialogOpen(false);
  };

  const groupedMatches = matches ? groupByCampaign(matches) : [];

  const convertDateFormat = (dateString) => {
    if (dateString.length !== 8) {
      return dateString;
    }
    const day = dateString.substring(6, 8);
    const month = dateString.substring(4, 6);
    const year = dateString.substring(0, 4);
    return `${day}/${month}/${year}`;
  };

  return (
    <Container maxWidth="sm">
      <Title>Gestione attestati</Title>

      <Box>
        <Box mt={2} style={{ clear: "right" }}>
          {groupedMatches.map((campaign) => (
            <Card key={campaign.campaign_id} style={{ marginTop: 10 }}>
              <CardContent>
                <Typography variant="h6">{campaign.campaign_name}</Typography>
                {campaign.events.map((event) => (
                  <>
                    <Typography key={event.day} gutterBottom>
                      Nominativi da attestare ({convertDateFormat(event.day)}):{" "}
                      {event.count}
                    </Typography>
                    <Box my={0} display="flex" justifyContent="space-between">
                      <Button
                        onClick={() => download(event)}
                        startIcon={<CloudDownload />}
                      >
                        Scarica (da attestare)
                      </Button>
                      <Button
                        onClick={() => {
                          fileInputRef.current.dataset.campaignId =
                            campaign.campaign_id;
                          fileInputRef.current.dataset.campaignName =
                            campaign.campaign_name;
                          fileInputRef.current.dataset.day = event.day;
                          fileInputRef.current.click();
                        }}
                        startIcon={<CloudUpload />}
                      >
                        Carica (attestati)
                      </Button>
                    </Box>
                    <Divider />
                  </>
                ))}
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>

      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        onChange={upload}
      />

      <Box my={2}>
        <Button onClick={goToHome} startIcon={<ArrowBack />}>
          {intl.formatMessage(common.backHome)}
        </Button>
      </Box>

      {/* Dialog component */}
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogTitle>Caricamento certificati</DialogTitle>
        <DialogContent>
          <Typography>Certificati per: {dialogData?.campaignName}</Typography>
          <Box display="flex" flexDirection="column" width="100%" mt={2}>
            {dialogData?.resp?.warn_events?.length > 0 && (
              <Typography variant="h6">Il nome evento non coincide</Typography>
            )}
            {dialogData?.resp?.warn_events?.map((we, i) => (
              <Box key={"we" + i} mb={1}>
                {we.event_name}
              </Box>
            ))}

            {dialogData?.resp?.warn_users?.filter((u) => u.code === "wf")
              .length > 0 && (
              <Typography variant="h6">
                Righe non corrette (da ignorare)
              </Typography>
            )}
            {dialogData?.resp?.warn_users
              ?.filter((u) => u.code === "wf")
              .map((we, i) => (
                <Box key={"wf" + i} mb={1}>
                  Riga: {we.row}
                </Box>
              ))}

            {dialogData?.resp?.warn_users?.filter((u) => u.code === "nu")
              .length > 0 && (
              <Typography variant="h6">
                Utenti non trovati (da ignorare)
              </Typography>
            )}
            {dialogData?.resp?.warn_users
              ?.filter((u) => u.code === "nu")
              .map((we, i) => (
                <Box key={"nu" + i} mb={1}>
                  {we.email}
                </Box>
              ))}

            {dialogData?.resp?.warn_users?.filter((u) => u.code === "dp")
              .length > 0 && (
              <Typography variant="h6">Duplicati (da ignorare)</Typography>
            )}
            {dialogData?.resp?.warn_users
              ?.filter((u) => u.code === "dp")
              .map((we, i) => (
                <Box key={"dp" + i} mb={1}>
                  {we.email}
                </Box>
              ))}

            {dialogData?.resp?.warn_users?.filter((u) => u.code === "na")
              .length > 0 && (
              <Typography variant="h6">
                Certificato non previsto ??? (da ignorare)
              </Typography>
            )}
            {dialogData?.resp?.warn_users
              ?.filter((u) => u.code === "na")
              .map((we, i) => (
                <Box key={"na" + i} mb={1}>
                  {we.email}
                </Box>
              ))}

            {dialogData?.resp?.warn_users?.filter((u) => u.code === "nc")
              .length > 0 && (
              <Typography variant="h6">
                Nuovi certificati (da inserire)
              </Typography>
            )}
            {dialogData?.resp?.warn_users
              ?.filter((u) => u.code === "nc")
              .map((we, i) => (
                <Box key={"nc" + i} mb={1}>
                  {we.email}
                </Box>
              ))}

            <Typography variant="h6">
              Certificati da inserire: {dialogData?.resp?.count}
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Chiudi
          </Button>
          {dialogData?.resp?.count > 0 && (
            <Button
              onClick={() => handleProceed(dialogData?.resp?.upload_id)}
              color="primary"
            >
              Procedi
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </Container>
  );
}
