import React from "react";
import { useIntl } from "react-intl";

import Utils from "@date-io/date-fns";
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
  TimePicker as MuiTimePicker,
} from "@material-ui/pickers";

import { getDateMessages } from "../utils/dictionaries";

export function TimePicker({ label, value, onChange, ...props }) {
  const intl = useIntl();

  return (
    <MuiPickersUtilsProvider
      utils={Utils}
      locale={getDateMessages(intl.locale, intl.defaultLocale)}
    >
      <MuiTimePicker
        ampm={false}
        margin="normal"
        label={label}
        value={value}
        onChange={onChange}
        format="HH:mm"
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}

export function DatePicker({ label, value, onChange, maxDate, ...props }) {
  const intl = useIntl();

  return (
    <MuiPickersUtilsProvider
      utils={Utils}
      locale={getDateMessages(intl.locale, intl.defaultLocale)}
    >
      <MuiDatePicker
        margin="normal"
        label={label}
        value={value}
        onChange={onChange}
        format="d MMMM yyyy"
        maxDate={maxDate}
        {...props}
      />
    </MuiPickersUtilsProvider>
  );
}
