import { format } from "date-fns";
import {
  API,
  BUSINESS_TYPES,
  CONSUMER,
  DEFAULT_MANIFEST,
  DEV_SUBSCRIPTION,
  DOMAIN_CONFIG,
  DOMAIN_WILDCARD,
  EMPTY_USER,
  ERRORS,
  EVENT_STATUS,
  EXPERIMENTAL_FEATURES,
  FEATURE_PERMISSION,
  HTTP,
  MANAGER,
  MESSAGE_STATUS,
  PERMISSIONS,
  RESOURCE_TYPES,
  ROLES,
  SUBSCRIPTIONS,
  TENANTS,
  USER_STATUS,
} from "./constants";
import Firebase, {
  EmailAuthProvider,
  FacebookAuthProvider,
  GoogleAuthProvider,
  TwitterAuthProvider,
} from "./firebase";
import createTheme from "./themes";
import utils, {
  BmapiError,
  getSignInSubscriptionData,
  getSubscriptionLogo,
} from "./utils";

export default class BmarkenAPI {
  constructor(tenant, subscription, app) {
    console.log(
      "LOADING, bmapi.js: BmarkenAPI constructor tenant=",
      tenant,
      "subscription=",
      subscription,
      "app=",
      app
    );

    const env = process.env.REACT_APP_FORCE_ENV || process.env.REACT_APP_ENV;

    console.group("BME");

    console.group("Loaded");
    //console.info(`Env: ${process.env.REACT_APP_ENV}`);
    console.info(`Env: ${env}`);
    //this.allSettings = utils.createSettings(tenant, process.env.REACT_APP_ENV);
    this.allSettings = utils.createSettings(tenant, env);

    this.app = app;
    this.tenant = tenant;
    this.subscriptionSlug = subscription;
    this.user = null;
    this.userData = null;

    this.settings = this.allSettings[this.app];

    this.isConsumerEnabled = this.isAppEnabled(CONSUMER);
    this.isManagerEnabled = this.isAppEnabled(MANAGER);
    this.language = this.settings.defaultLanguage;
    this.onAuthStateChangedFns = [];
    this.onThemeLoadFns = [];

    //callbacks per le notifiche
    this.orderNotificationCallback = null;

    if (!this.isAppEnabled(this.app)) {
      if (this.app === CONSUMER && this.isManagerEnabled) {
        throw new BmapiError(ERRORS.ONLY_MANAGER_ENABLED, this.settings);
      }
      throw new BmapiError(ERRORS.APP_NOT_CONFIGURED, this.settings);
    }
    if (!this.subscriptionSlug && SUBSCRIPTIONS) {
      throw new BmapiError(ERRORS.MISS_SUBSCRIPTION, this.settings);
    }
    this.businessProfileConf = require(`../configurations/business/${this.settings.businessProfile}.json`);
    this.manifest = require(`../configurations/manifest/${this.settings.manifest}.json`);
    /*
    this.firebase = new Firebase(
      require(`../configurations/firebase/${this.settings.firebase}.json`),
      this.appKey,
      this.settings.persistance,
      (user) => this.setFirebaseUser(user),
      this.settings.analytics
    );*/

    this.themeConf = utils.createThemeConf(this.settings.theme, this.app);
    this.theme = {};
    this.title = this.getTitle();
    console.groupEnd();

    console.info(`Tenant: ${tenant} [${this.settings.tenantId}]`);
    console.info(`ApiUrl: ${this.settings.apiUrl}`);
    console.info(`ClientId: ${this.settings.clientId}`);
    console.info(`Subscription: ${subscription || "disabled"}`);
    console.info(`App: ${app}`);
    console.info(`Env: ${env}/${process.env.NODE_ENV}`);
    console.info(`UI version: ${process.env.REACT_APP_GIT_SHA}`);
    console.info(`Audience: ${this.settings.firebase}`);
    console.info(`Stats: ${this.settings.analytics ? "On" : "Off"}`);
    console.info("Domain config:", DOMAIN_CONFIG);
    console.info("Skin:", this.settings.skin);

    this.hasMerchants = true;

    this.settings.experimental
      ? console.warn(
          `Experimental: On [${EXPERIMENTAL_FEATURES.filter(
            (f) => !!this.settings[f]
          ).join(", ")}]`
        )
      : console.info("Experimental: Off");
    console.groupEnd();

    console.log(
      "LOADING, bmapi.js: BmarkenAPI invoking /me, subscriptionSlug:",
      this.subscriptionSlug
    );
    return [
      this,
      /*
      this.getMe()
        .then(() =>
          this.subscriptionSlug && this.subscriptionSlug !== DEV_SUBSCRIPTION
            ? this.getSubscriptionsBySlug()
            : []
        )
        .then((subs) => {
          console.log(
            "LOADING, bmapi.js: BmarkenAPI subscriptions found=",
            subs
          );
          if (
            SUBSCRIPTIONS &&
            (!Array.isArray(subs) || subs.length === 0) &&
            (!JSON.parse(process.env.REACT_APP_DEV_SUBSCRIPTION) ||
              this.subscriptionSlug !== DEV_SUBSCRIPTION)
          ) {
            throw new BmapiError(ERRORS.WRONG_SUBSCRIPTION, this.settings);
          }
          if (SUBSCRIPTIONS) {
            this.subscription = subs[0];
            this.themeConf = this.getThemeConf();
            this.museumsOnly =
              this?.subscription?.business_types?.length === 1 &&
              this?.subscription?.business_types[0] === BUSINESS_TYPES.MUSEUM;
            this.campaignsDisabled = !!this?.subscription?.campaigns_disabled;
          }
          this.theme = createTheme(this.themeConf, app);
        })
        .then(() => {
          console.log(
            "LOADING, bmapi.js: BmarkenAPI constructor end invoking triggerAuthStateChanged"
          );
          return this.triggerAuthStateChanged();
        }),*/
      this.load(app, subscription),
    ];
  }

  async load(app, friendlyUrl) {
    console.log("xx1", this.settings.globalTenantId, friendlyUrl);
    let sub, firebaseAudience, firebaseTenant;
    if (!this.settings.tenantId) {
      // dynamic tenant

      if (!this.settings.globalTenantId)
        throw new BmapiError(ERRORS.GLOBAL_TENANT_MISSING, this.settings);
      if (!this.subscriptionSlug)
        throw new BmapiError(ERRORS.FRIENDLY_URL_MISSING, this.settings);
      sub = await this.subscriptionLookup(
        this.settings.globalTenantId,
        friendlyUrl
      );
      console.log("xx1.1", sub, app);
      const client = sub?.clients?.find((client) => client.app_id === app);
      firebaseAudience = client?.firebase_audience;
      firebaseTenant = client?.firebase_tenant;
      this.settings.tenantId = sub.tenant_id;
      this.settings.clientId = client?.client_id;
      console.log("_____ssss", this.app);
    } else {
      console.log("xx1.A", friendlyUrl);
      // static tenant
      firebaseAudience = this.settings.firebase;

      if (friendlyUrl && friendlyUrl !== DEV_SUBSCRIPTION) {
        const subs = await this.getSubscriptionsBySlug();
        console.log("xx1.B", subs);
        if (Array.isArray(subs) && subs?.length > 0) {
          console.log("xx1.C");
          sub = subs[0];
        }
      }
    }

    this.theme = createTheme(this.themeConf, app);
    console.log("xx2", this.settings?.noLogin);

    // load firebase
    this.firebase = new Firebase(
      require(`../configurations/firebase/${firebaseAudience}.json`),
      this.appKey,
      this.settings.persistance,
      (user) => this.setFirebaseUser(user),
      //this.settings.analytics
      firebaseTenant
    );

    console.log("xx3");

    if (!this.settings?.noLogin) {
      console.log("xx2", firebaseAudience);

      // check if the user is authenticated
      await this.getMe();

      /*
    let subs = [];
    if (this.subscriptionSlug && this.subscriptionSlug !== DEV_SUBSCRIPTION) {
      subs = await this.getSubscriptionsBySlug();
    }
    console.log("LOADING, bmapi.js: BmarkenAPI subscriptions found=", subs);
    */

      console.log("xx4", SUBSCRIPTIONS, sub);

      if (
        SUBSCRIPTIONS &&
        //(!Array.isArray(subs) || subs.length === 0) &&
        !sub &&
        (!JSON.parse(process.env.REACT_APP_DEV_SUBSCRIPTION) ||
          this.subscriptionSlug !== DEV_SUBSCRIPTION)
      ) {
        throw new BmapiError(ERRORS.WRONG_SUBSCRIPTION, this.settings);
      }

      console.log("xx5");
      if (SUBSCRIPTIONS) {
        //this.subscription = subs[0];
        this.subscription = sub;
        this.themeConf = this.getThemeConf();
        this.museumsOnly =
          this?.subscription?.business_types?.length === 1 &&
          this?.subscription?.business_types[0] === BUSINESS_TYPES.MUSEUM;
        this.campaignsDisabled = !!this?.subscription?.campaigns_disabled;
        console.log("xx5.b");
      }

      console.log("xx5.c");

      console.log(
        "LOADING, bmapi.js: BmarkenAPI constructor end invoking triggerAuthStateChanged"
      );
      console.log("xx5.d");
      return await this.triggerAuthStateChanged();
    }
  }

  /*authStateChanged(user) {
    console.log("authStateChanged", user);
    (user) => this.setFirebaseUser(user);
  }*/

  getTitle() {
    return `${this.manifest.name || DEFAULT_MANIFEST.name}${
      process.env.NODE_ENV !== "production" ? ` [${process.env.NODE_ENV}]` : ""
    }`;
  }

  getSubscriptionLogos() {
    const sub = this.subscription;
    const { logoUrl } = getSubscriptionLogo(sub);
    const logoBig = logoUrl;
    return this.isConsumer()
      ? {
          logo: sub.logo_small || logoBig,
          logoLogin: logoBig,
        }
      : {
          logo: sub.logo_small_manager || sub.logo_big_manager || logoBig,
          logoLogin: sub.logo_big_manager || logoBig,
        };
  }

  getThemeConf() {
    if (!this.subscription) {
      return this.themeConf;
    }
    let _themeConf = { ...this.themeConf };
    console.log("--------------getthemeconf", this.subscription.primary_color);
    if (this.subscription.primary_color) {
      _themeConf.primary = this.subscription.primary_color;
    }
    const logos = this.getSubscriptionLogos();
    if (logos?.logo) {
      _themeConf.logo = logos?.logo;
    }
    if (logos?.logoLogin) {
      _themeConf.logoLogin = logos?.logoLogin;
    }
    return _themeConf;
  }

  setLanguage(lang) {
    this.language = lang;
  }

  get businessProfile() {
    return {
      ...this.businessProfileConf,
      ...(this.businessProfileConf[this.language] || {}),
    };
  }

  isAppEnabled(app) {
    //return this.allSettings[app].clientId && this.allSettings[app].enabled;
    return this.allSettings[app].enabled;
  }

  setFirebaseUser(user) {
    // localStorage.removeItem(this.appKey);
    this.user = user;
    return this.triggerAuthStateChanged();
  }

  can(feature) {
    const { role, permissions = [] } = this.getUserInfo();

    return (
      !!this.settings[feature] &&
      (!EXPERIMENTAL_FEATURES.includes(feature) ||
        !!this.settings.experimental) &&
      (role === ROLES.USER ||
        !FEATURE_PERMISSION[feature] ||
        (permissions || []).includes(FEATURE_PERMISSION[feature]))
    );
  }

  hasBusinessType(businessTypes) {
    if (!businessTypes) {
      return false;
    }
    const business = this.getUserInfo().business;
    if (!business) {
      return false;
    }
    if (Array.isArray(businessTypes)) {
      return businessTypes.indexOf(business.type) !== -1;
    }
    return businessTypes === business.type;
  }

  hasEcommerceOrdering() {
    const business = this.getUserInfo().business;
    if (!business) {
      return false;
    }
    return business.ecommerce_ordering;
  }

  hasCampaignsEnabled() {
    const business = this.getUserInfo().business;
    if (!business) {
      return false;
    }
    return business.campaigns_enabled;
  }

  get customCSS() {
    return this.themeConf.customCSS
      ? this.themeConf.customCSS.includes("://")
        ? this.themeConf.customCSS
        : `${this.tenantStaticUrl}${this.themeConf.customCSS}`
      : false;
  }

  logo({ small = false } = {}) {
    const logo =
      this.themeConf[small ? "logo" : "logoLogin"] || this.settings.logo;
    return logo?.includes("://") ? logo : `${this.tenantStaticUrl}${logo}`;
  }

  get tenantStaticUrl() {
    return `/static/tenants/${this.tenant}/`;
  }

  isConsumer() {
    return this.app === CONSUMER;
  }

  isManager() {
    return this.app === MANAGER;
  }

  getFirebaseAuth() {
    return this.firebase.app.auth();
  }

  logEvent(event) {
    this.firebase.log(event);
  }

  getJsonHeaders() {
    return {
      Accept: "application/json",
      "Content-Type": "text/plain",
    };
  }

  apiFetch(
    url,
    {
      params = {},
      query = {},
      method = HTTP.GET,
      body = null,
      headers,
      json = true,
    } = {}
  ) {
    const fullUrl = utils.composeUrl(
      url.includes("://") ? url : `${this.settings.apiUrl}${url}`,
      {
        ...params,
        business: params.business || this.getUserInfo().business?.id,
        subscription: params.subscription || this.subscription?.id || "",
        tenant: params.tenant || this.settings.tenantId,
        "tenant-name": this.tenant,
        user: params.user || this.getUserInfo().user_id,
      },
      { ...query, tenant: this.settings.tenantId }
    );
    return utils
      .fetch(
        fullUrl,
        method,
        json && body ? JSON.stringify(body) : body,
        headers || this.getJsonHeaders()
      )
      .catch((error) => {
        if (error.httpCode === 401) return this.clean().then(() => undefined);
        throw error;
      });
  }

  //query deve essere un'array di array di coppie chiave-valore
  /*apiFetchMultipleQueryParams(
    url,
    {
      params = {},
      query = [],
      method = HTTP.GET,
      body = null,
      headers,
      json = true,
    } = {}
  ) {
    query.push(["tenant", this.settings.tenantId]);

    const fullUrl = utils.composeUrl(
      url.includes("://") ? url : `${this.settings.apiUrl}${url}`,
      {
        ...params,
        business: params.business || this.getUserInfo().business.id,
        subscription: this.subscription ? this.subscription.id : "",
        tenant: params.tenant || this.settings.tenantId,
        "tenant-name": this.tenant,
        user: params.user || this.getUserInfo().user_id,
      },
      query
    );

    return utils
      .fetch(
        fullUrl,
        method,
        json && body ? JSON.stringify(body) : body,
        headers || this.getJsonHeaders()
      )
      .catch((error) => {
        if (error.httpCode === 401) return this.clean().then(() => undefined);
        throw error;
      });
  }*/

  plainGet(url, config = {}) {
    return this.apiFetch(url, { ...config, headers: config.header || {} });
  }

  apiGet(url, config = {}) {
    return this.apiFetch(url, { ...config, method: HTTP.GET });
  }

  apiPost(url, config = {}) {
    return this.apiFetch(url, { ...config, method: HTTP.POST });
  }

  apiPut(url, config = {}) {
    return this.apiFetch(url, { ...config, method: HTTP.PUT });
  }

  apiDelete(url, config = {}) {
    return this.apiFetch(url, { ...config, method: HTTP.DELETE });
  }

  get appKey() {
    return `${this.tenant}-${this.settings.uniqueSignIn ? "app" : this.app}`;
  }

  get userDataKey() {
    return `${this.appKey}-${this.getUserInfo().user_id}`;
  }

  get tenantDataKey() {
    return `${this.appKey}-data`;
  }

  needsVerification() {
    return (
      this.user &&
      this.isConsumer() &&
      this.user.email &&
      !this.user.email.includes(DOMAIN_WILDCARD) &&
      this.settings.unverified &&
      !this.getUserInfo().token_info.email_verified
    );
  }

  needsPrivacy() {
    return (
      this.userData &&
      this.settings.profileVersion !== this.userData.profile_active
    );
  }

  get userStatus() {
    const logged = this.getUserInfo().user_id;
    const needsPrivacy = this.needsPrivacy();

    if (logged && needsPrivacy) return USER_STATUS.ACTIVATION;
    if (logged && !needsPrivacy) return USER_STATUS.LOGGED;
    return USER_STATUS.ANONYMOUS;
  }

  getUserInfo() {
    return utils.getData(this.appKey, EMPTY_USER);
  }

  setUserInfo(userInfo) {
    return utils.setData(this.appKey, userInfo, EMPTY_USER);
  }

  removeUserInfo() {
    this.user = null;
    this.userData = null;
    localStorage.removeItem(this.appKey);
  }

  getUserData() {
    return utils.getData(this.userDataKey);
  }

  setUserData(data) {
    return utils.setData(this.userDataKey, data);
  }

  getTenantData() {
    return utils.getData(this.tenantDataKey);
  }

  setTenantData(data) {
    return utils.setData(this.tenantDataKey, data);
  }

  setCallbackUrl(signinCallbackUrl) {
    return this.setTenantData({ signinCallbackUrl });
  }

  validateEmail(email) {
    return utils.validateEmail(email);
  }

  validatePassword(password) {
    return password.length >= 6;
  }

  validateRoles(roleWanted, userRole) {
    return utils.validateRoles(roleWanted, userRole);
  }

  validateUUID(id) {
    return utils.validateUUID(id);
  }

  createBg(seed, color) {
    return utils.createBg(seed, color || this.themeConf.primary);
  }

  clean() {
    this.removeUserInfo();
    return this.triggerAuthStateChanged().then(() =>
      this.setTenantData({ logout: true })
    );
  }

  async firebaseSignOut() {
    console.log("firebase signout");
    if (this.auth) {
      try {
        await this.auth.signOut();
      } catch (e) {
        console.log("firebase signout error", e);
      }
    }
  }

  logout() {
    console.log("uuuuuuu");
    return (
      this.apiPost(API.SIGNOUT)
        //.then(() => this.firebaseSignOut())
        .then(() => this.clean())
        .catch(console.error)
    );
  }

  saveToken(firebase_token_id) {
    const token_info = utils.parseTokenId(firebase_token_id);

    this.setUserInfo({
      firebase_token_id,
      role: token_info.r,
      token_info,
      user_id: token_info.bme_id,
    });
    return token_info;
  }

  checkIfLoggedIn() {
    return this.user?.id ? this.getUserInfo() : false;
  }

  async doCreateUserWithEmailAndPassword(email, password) {
    return this.getFirebaseAuth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        console.log("iiii1");
        this.getFirebaseAuth().currentUser.sendEmailVerification();
        return this.isConsumer() && this.settings.unverified
          ? this.doSignInWithEmailAndPassword(email, password)
          : this.logout();
      });
  }

  doPasswordReset(email) {
    return this.getFirebaseAuth().sendPasswordResetEmail(email);
  }

  async doPasswordUpdate(oldPassword, newPassword) {
    const credential = EmailAuthProvider.credential(
      this.getUserInfo().token_info.email,
      oldPassword
    );

    return this.getFirebaseAuth()
      .currentUser.reauthenticateWithCredential(credential)
      .then(() =>
        this.getFirebaseAuth()
          .currentUser.updatePassword(newPassword)
          .then(() => this.doRefreshToken())
      );
  }

  handleVerifyEmail(actionCode) {
    return this.getFirebaseAuth().applyActionCode(actionCode);
  }

  getResetPasswordEmail(actionCode) {
    return this.getFirebaseAuth().verifyPasswordResetCode(actionCode);
  }

  handleResetPassword(actionCode, newPassword) {
    return this.getFirebaseAuth().confirmPasswordReset(actionCode, newPassword);
  }

  getCurrentToken(refresh = false) {
    console.log("iiii2");
    return this.getFirebaseAuth().currentUser
      ? this.getFirebaseAuth().currentUser.getIdToken(refresh)
      : this.logout();
  }

  async doRefreshToken() {
    return this.getCurrentToken(true)
      .then((t) => this.saveToken(t))
      .then(() => {
        const token = this.getUserInfo().firebase_token_id;
        return this.apiPost(API.COOKIE, {
          headers: { "Content-Type": "text/plain" },
          body: {
            Authorization: `Bearer ${token}`,
            client_id: this.settings.clientId,
          },
        });
      })
      .then(() => this.getMe());
  }

  async checkVerification(user, forceEmailVerified = false) {
    const emailVerified = forceEmailVerified || user.emailVerified;
    console.log("iiii3");
    if (
      this.isManager() &&
      !emailVerified &&
      !this.settings.unverified &&
      !user.email.includes(DOMAIN_WILDCARD)
    ) {
      this.logout();
      throw new Error(ERRORS.USER_NOT_VERIFIED);
    } else if (
      this.isConsumer() &&
      !emailVerified &&
      user.email &&
      !user.email.includes(DOMAIN_WILDCARD)
    ) {
      if (!this.settings.unverified) {
        this.logout();
        throw new Error(ERRORS.USER_NOT_VERIFIED);
      } else if (
        typeof this.settings.unverified === "number" &&
        this.settings.unverified <
          (new Date() - new Date(user.metadata.creationTime)) / 864e5
      ) {
        this.logout();
        throw new Error(ERRORS.VERIFICATION_TIME_EXPIRED);
      }
    }
  }

  getCustomToken() {
    const { clientId, tenantId } = this.settings;
    if (!clientId) throw new Error(ERRORS.CLIENTID_NOT_FOUND);

    return this.apiPost(API.CUSTOM_SIGNUP, {
      body: { client_id: clientId, tenant_id: tenantId },
    })
      .then((res) => res.token)
      .catch((e) => {
        console.log("iiii3");
        this.logout();
        throw e;
      });
  }

  async completeSignIn(token) {
    return this.signup(token)
      .then(() => this.doRefreshToken())
      .then(() => this.triggerAuthStateChanged());
  }

  async doSignInWithEmailAndPassword(email, password) {
    this.logginIn = true;
    return this.getFirebaseAuth()
      .signInWithEmailAndPassword(email, password)
      .then((credentials) => this.checkVerification(credentials.user))
      .then(() => this.getCurrentToken())
      .then((token) => this.completeSignIn(token))
      .then(() => (this.logginIn = false));
  }

  doSignInWithCustomToken() {
    this.logginIn = true;
    return this.getCustomToken()
      .then((token) => this.getFirebaseAuth().signInWithCustomToken(token))
      .then((credentials) => this.checkVerification(credentials.user))
      .then(() => this.getCurrentToken())
      .then((token) => this.completeSignIn(token))
      .then(() => (this.logginIn = false));
  }

  async doSocialSignIn() {
    this.logginIn = true;
    return this.getCurrentToken()
      .then((token) => this.completeSignIn(token))
      .then(() => (this.logginIn = false));
  }

  /*
  signup(token_id) {
    this.logEvent("signup");
    if (!this.settings.clientId) throw new Error(ERRORS.CLIENTID_NOT_FOUND);

    return this.apiPost(API.SIGNUP, {
      body: { client_id: this.settings.clientId, token_id },
    })
      .then(() => token_id)
      .catch((e) => {
        this.logout();
        throw e;
      });
  }*/

  async signup(token_id) {
    if (!this.settings.clientId) throw new Error(ERRORS.CLIENTID_NOT_FOUND);
    const signinSubscriptionData = getSignInSubscriptionData();
    try {
      if (signinSubscriptionData.isSubscribing) {
        const signupUrl = this.settings.signupSubscribeUrl || API.SIGNUP;
        await this.apiPost(signupUrl, {
          body: {
            client_id: this.settings.clientId,
            token_id,
            subscription_id: this.subscription?.id,
          },
        });
      } else {
        const signupUrl = this.settings.signupUrl || API.SIGNUP;
        await this.apiPost(signupUrl, {
          body: {
            client_id: this.settings.clientId,
            token_id,
            subscription_id: this.subscription?.id,
          },
        });
      }

      return token_id;
    } catch (e) {
      if (e.message === "Email not provided") {
        e.missingEmail = true;
        if (this.getFirebaseAuth().currentUser) {
          const tokenResult = await this.getFirebaseAuth().currentUser.getIdTokenResult();
          if (tokenResult) {
            e.signInProvider = tokenResult.signInProvider;
          }
          try {
            await this.getFirebaseAuth().currentUser.delete();
          } catch (e2) {
            console.error(e2);
          }
        }
      }
      console.log("iiii4");
      this.logout();
      throw e;
    }
  }
  /*
    deleteAuthAccount() {
    if (this.user) {
      this.user.delete().catch((e) => console.error(e))
    }
  }
  */

  getSocialSignInOptions() {
    let signInOptions = [];

    if (this.settings.google) {
      signInOptions.push({
        provider: GoogleAuthProvider.PROVIDER_ID,
        scopes: ["email"],
        customParameters: { prompt: "select_account" },
      });
    }

    if (this.settings.facebook) {
      signInOptions.push({
        provider: FacebookAuthProvider.PROVIDER_ID,
        scopes: ["public_profile", "email"],
        customParameters: { prompt: "select_account" },
      });
    }

    if (this.settings.twitter) {
      signInOptions.push(TwitterAuthProvider.PROVIDER_ID);
    }

    return signInOptions;
  }

  getSocialSignInConfig(onSuccess, onFailure) {
    return {
      //signInFlow: "redirect",
      signInFlow: "popup",
      signInOptions: this.getSocialSignInOptions(),
      callbacks: {
        signInSuccessWithAuthResult: onSuccess,
        signInFailure: onFailure,
      },
      signInSuccessUrl: "https://localhost:3000/test/-/auth/login/",
    };
  }

  onAuthStateChanged(fn) {
    console.log("LOADING, bmapi.js: BmarkenAPI onAuthStateChanged");
    this.onAuthStateChangedFns.push(fn);
    return () => {
      this.onAuthStateChangedFns = this.onAuthStateChangedFns.filter(
        (f) => f !== fn
      );
    };
  }

  /*
  isFacebook(token) {
    return (
      token &&
      token.firebase &&
      token.firebase.sign_in_provider === "facebook.com"
    );
  }*/

  getCurrentAuthProvider() {
    return this.getFirebaseAuth()
      .currentUser.getIdTokenResult()
      .then((tokenResult) => {
        return tokenResult ? tokenResult.signInProvider : null;
      })
      .catch(() => null);
  }

  loadSubPermissions() {
    return (this.subscription
      ? this.getUserSubscriptionPermissions()
      : Promise.resolve([])
    ).then((subPermissions) => this.setUserInfo({ subPermissions }));
  }

  async triggerAuthStateChanged() {
    const user = this.checkIfLoggedIn();

    console.log(
      "LOADING, bmapi.js: BmarkenAPI triggerAuthStateChanged logged user=",
      user
    );

    const trigger = (user) => {
      console.log("LOADING, bmapi.js: BmarkenAPI trigger user=", user);
      return Promise.all(this.onAuthStateChangedFns.map((fn) => fn(user)));
    };

    return !user.user_id
      ? trigger(user)
      : this.loadUserData()
          .then(() => this.loadSubPermissions())
          .then(() => this.loadBusinessAndLimits())
          .then(() => trigger(this.getUserInfo()));
  }

  setTenantLanguage(language) {
    this.language = language;
    this.setTenantData({ language });
    return this.triggerAuthStateChanged();
  }

  saveUser(
    {
      birthday = "",
      direct_marketing = false,
      fiscal_code = "",
      gender = "",
      indirect_marketing = false,
      language = "",
      last_name = "",
      metadata = {},
      mobile = "",
      name = "",
      privacy = false,
      rules = false,
    } = {},
    user
  ) {
    return this.apiPost(API.USER_PROFILE, {
      params: { user },
      body: {
        birthday,
        direct_marketing,
        fiscal_code,
        gender,
        indirect_marketing,
        language,
        last_name,
        metadata,
        mobile,
        name,
        privacy,
        rules,
      },
    })
      .then(() => this.loadUserData())
      .then(() => this.triggerAuthStateChanged());
  }

  // TODO:SUBSCRIPTION all user business will be in a subscription
  getUserBusiness() {
    return (this.isSubscriptionManager()
      ? this.getSubscriptionBusinesses()
      : this.getUserBusinesses()
    ).then((bs) => {
      return this.subscription
        ? bs.filter((b) => b.subscription_id === this.subscription.id)
        : bs;
    });
  }

  getUserReservations() {
    return this.apiGet(API.GET_USER_RESERVATIONS);
  }

  async getUserPermission() {
    const { role, business } = this.getUserInfo();

    if (role === ROLES.TENANT_MANAGER) {
      return Object.values(PERMISSIONS);
    } else if (role === ROLES.STORE_MANAGER && business) {
      return this.apiGet(API.USER_BUSINESS_PERMISSION);
    }
    return [];
  }

  getUserSubscriptionPermissions() {
    return this.user ? this.apiGet(API.USER_SUBSCRIPTION_PERMISSION) : [];
  }

  async setUserPermission() {
    const permissions = await this.getUserPermission();
    await this.setUserInfo({ permissions });
    return permissions;
  }

  async selectBusiness(business) {
    //this.setUserInfo({ business });
    //this.setUserData({ business });
    try {
      const completeBusiness = await this.getBusiness(business.id);
      await this.setUserInfo({ business: completeBusiness });
      await this.setUserData({ business: completeBusiness });
      return await this.setUserPermission();
    } catch (e) {
      console.log("getBusiness error: ", e);
      throw e;
    }
  }

  async changeBusiness(business) {
    return this.selectBusiness(business).then(() =>
      this.triggerAuthStateChanged()
    );
  }

  // TODO:SUBSCRIPTION (all the businesses will be in a subscription)
  getBusinesses() {
    return this.subscription
      ? this.getSubscriptionBusinesses()
      : this.getTenantBusinesses();
  }

  getBusinessesAdmin(query = {}) {
    return this.apiGet(API.GET_TENANT_BUSINESS_ADMIN, { query });
  }

  getBusinessesCount(query = {}) {
    return this.apiGet(API.GET_TENANT_BUSINESS_COUNT, { query });
  }

  getTenantBusinesses() {
    return this.apiGet(API.GET_TENANT_BUSINESS);
  }

  getUserBusinesses() {
    return this.apiGet(API.GET_USER_BUSINESS);
  }

  getSubscriptionBusinesses() {
    return this.apiGet(API.SUBSCRIPTIONS_BS);
  }

  // TODO:SUBSCRIPTION all managers will be inside a subscription
  /*
  getManagers() {
    return this.subscription
      ? this.getSubscriptionPermissions()
      : this.getTenantPermissions();
  }*/
  /*
  getTenantPermissions(query) {
    return this.apiGet(API.GET_TENANT_PERMISSIONS, { query });
  }*/
  /*
  getSubscriptionPermissions() {
    return this.apiGet(API.SUBSCRIPTIONS_PERMISSIONS);
  }*/

  saveLocation(values, location) {
    return location
      ? this.apiPut(API.LOCATION, { params: { location }, body: values })
      : this.apiPost(API.BUSINESS_LOCATIONS, { body: values });
  }

  deleteLocation(location) {
    return this.apiDelete(API.LOCATION, { params: { location } });
  }

  getLocations(query = {}) {
    return this.apiGet(API.LOCATIONS, { query });
  }

  getLocationsForBusiness(businessId) {
    return this.getLocations({
      businessId: businessId || this.getUserInfo().business.id,
    });
  }

  getLocation(locationId) {
    return this.getLocations({ locationId }).then((ls = []) =>
      ls.length > 0 ? ls[0] : false
    );
  }

  saveBusinessEvent(values, event) {
    return event
      ? this.apiPut(API.BUSINESS_EVENT, { params: { event }, body: values })
      : this.apiPost(API.CREATE_BUSINESS_EVENT, { body: values });
  }

  setBusinessEventStatus(event, location_id = "", status) {
    return this.apiPut(API.EVENT_STATUS, {
      params: { event },
      body: { location_id, status },
    });
  }

  startBusinessEvent(event, location_id) {
    return this.setBusinessEventStatus(
      event,
      location_id,
      EVENT_STATUS.STARTED
    );
  }

  stopBusinessEvent(event, location_id) {
    return this.getEventCheckIns(event)
      .then((checkins) =>
        (checkins || []).length ? checkins.map((c) => c.user_id) : []
      )
      .then((users) => users.length && this.checkoutEvent(event, users))
      .then(() =>
        this.setBusinessEventStatus(event, location_id, EVENT_STATUS.ENDED)
      );
  }

  getBusinessEvents() {
    return this.apiGet(API.BUSINESS_EVENTS).then((res) => res || []);
  }

  getCampaignEvents(campaignId) {
    return this.getBusinessEvents().then((events) =>
      events.filter((e) => (e.campaign_ids || []).includes(campaignId))
    );
  }

  getCampaignUsers(campaign, businessId) {
    return this.apiGet(API.CAMPAIGN_USERS, {
      params: { campaign },
      query: { businessContext: businessId || this.getUserInfo().business.id },
    });
  }

  getProductUsers(campaign, businessId) {
    return this.apiGet(API.CAMPAIGN_PRODUCT_USERS, {
      params: { campaign },
      query: {
        businessContext: businessId || this.getUserInfo().business.id,
        status: 0,
      },
    });
  }

  getMainEventPasses(campaign, business, event) {
    return this.apiGet(API.GET_MAIN_EVENT_PASSES, {
      params: { campaign },
      query: {
        businessContext: business || this.getUserInfo().business.id,
        eventCampaign: event || "",
        status: 0,
      },
    });
  }

  getMainEventPassesResume(campaign, business, event) {
    return this.apiGet(API.GET_MAIN_EVENT_PASSES_RESUME, {
      params: { campaign },
      query: {
        businessContext: business || this.getUserInfo().business.id,
        eventCampaign: event || "",
        status: 0,
      },
    });
  }

  revokeUserProduct(campaign, user) {
    return this.apiDelete(API.CAMPAIGN_USERS_PRODUCTS, {
      params: { campaign, user },
    });
  }

  getEventUsers(event) {
    return this.apiGet(API.EVENT_USERS, { params: { event } });
  }

  checkin(resource_id, resource_type, users_id = []) {
    return this.apiPost(API.CHECKIN, {
      body: { resource_id, resource_type, users_id },
    });
  }

  checkinEvent(event, users_id) {
    return this.checkin(event, RESOURCE_TYPES.EVENT, users_id);
  }

  checkinLocation(location, users_id) {
    return this.checkin(location, RESOURCE_TYPES.LOCATION, users_id);
  }

  checkout(resource_id, resource_type, users_id = []) {
    return this.apiPost(API.CHECKOUT, {
      body: { resource_id, resource_type, users_id },
    });
  }

  checkoutEvent(event, users_id) {
    return this.checkout(event, RESOURCE_TYPES.EVENT, users_id);
  }

  checkoutLocation(location, users_id) {
    return this.checkout(location, RESOURCE_TYPES.LOCATION, users_id);
  }

  getEventCheckIns(businessEventId) {
    return this.apiGet(API.CHECKINS, {
      query: {
        businessEventId,
        businessId: this.getUserInfo().business.id,
        type: RESOURCE_TYPES.EVENT,
      },
    });
  }

  getUserCheckIns(userId) {
    return this.apiGet(API.CHECKINS, {
      query: { userId: userId || this.getUserInfo().user_id },
    });
  }

  getLocationCheckIns(locationId) {
    return this.apiGet(API.CHECKINS, {
      query: {
        locationId,
        businessId: this.getUserInfo().business.id,
        type: RESOURCE_TYPES.LOCATION,
      },
    });
  }

  revokeUserPermission(permission, email) {
    return this.apiDelete(API.USER_PERMISSION, {
      params: { permission },
    }).then((res) => {
      return email === this.getUserInfo().email
        ? this.triggerAuthStateChanged().then(() => res)
        : res;
    });
  }

  createManager(email, business_id, permission) {
    return this.apiPost(API.CREATE_MANAGER, {
      body: {
        business_id,
        client_id: this.settings.clientId,
        email: email.trim(),
        permission,
        subscription_id: this.subscription ? this.subscription.id : "",
      },
    }).then((res) => {
      return email === this.getUserInfo().email
        ? this.triggerAuthStateChanged().then(() => res)
        : res;
    });
  }

  createManagerWithCredentials(email, business_id, permission, password) {
    return this.apiPost(API.CREATE_MANAGER_WITH_CREDENTIALS, {
      body: {
        business_id,
        client_id: this.settings.clientId,
        email: email.trim(),
        permission: String(permission),
        subscription_id: this.subscription ? this.subscription.id : "",
        password: password,
      },
    }).then((res) => {
      return email === this.getUserInfo().email
        ? this.triggerAuthStateChanged().then(() => res)
        : res;
    });
  }

  getPolicy() {
    return this.apiGet(API.GET_TENANT_POLICY);
  }

  chooseBusiness(businesses, prevId, maxBusinesses) {
    if (!businesses.length) return false;

    // if there was a previous business, give it the priority
    if (prevId) {
      const prev = businesses.find((b) => b.id === prevId);
      if (prev) return prev;
    }

    // if max businesse = 1 give the priority to the non loop business,
    // otherwise to the loop business
    let first;
    if (maxBusinesses === 1) {
      first = businesses.sort((a, b) => {
        if (a.type === b.type) return a.name.localeCompare(b.name);
        return a.type === BUSINESS_TYPES.LOOP ? 1 : -1;
      })[0];
    } else {
      first = businesses.sort((a, b) => {
        if (a.type === b.type) return a.name.localeCompare(b.name);
        return a.type === BUSINESS_TYPES.LOOP ? -1 : 1;
      })[0];
    }
    return first;
  }

  /*
  loadBusiness() {
    if (this.app === MANAGER) {
      return this.getUserBusiness().then((bs) => {
        const businesses = bs
          .filter((b) => b.status === 0)
          .filter(
            (b) => this.loopAvailable() || b.type !== BUSINESS_TYPES.LOOP
          );
        this.setUserInfo({ businesses });
        if (!businesses || businesses.length === 0)
          return this.selectBusiness(false);

        const prev = businesses.find(
          (b) => b.id === this.getUserData().business?.id
        );

        let first;
        if (this.subscription?.plan_limits?.max_business === 1) {
          // priority to the non loop business
          first = businesses.sort((a, b) => {
            if (a.type === b.type) return a.name.localeCompare(b.name);
            return a.type === BUSINESS_TYPES.LOOP ? 1 : -1;
          })[0];
        } else {
          // priority to the loop business
          first = businesses.sort((a, b) => {
            if (a.type === b.type) return a.name.localeCompare(b.name);
            return a.type === BUSINESS_TYPES.LOOP ? -1 : 1;
          })[0];
        }

        if (!prev) return this.selectBusiness(first || false);
        this.setUserInfo({ business: prev });
        return this.setUserPermission();
      });
    }
    return this.getUserInfo();
  }
  */

  async loadBusinessAndLimits() {
    if (this.app === MANAGER) {
      // set plan limits
      if (this.subscription?.id && !this.subscription.plan_limits) {
        const subDetails = await this.getSubscription(this.subscription.id);
        this.subscription = { ...this.subscription, ...subDetails };
      }

      // set businesses
      const bs = await this.getUserBusiness();
      const businesses = bs
        .filter((b) => b.status === 0)
        .filter((b) => this.loopAvailable() || b.type !== BUSINESS_TYPES.LOOP);
      const choosenBusiness = this.chooseBusiness(
        businesses,
        this.getUserData().business?.id,
        this.subscription?.plan_limits?.max_business
      );
      await this.selectBusiness(choosenBusiness);
      this.setUserInfo({ businesses });
      /*
      //this.setUserInfo({ businesses });
      if (!businesses || businesses.length === 0) this.selectBusiness(false);
      const prev = businesses.find(
        (b) => b.id === this.getUserData().business?.id
      );
      let first;
      if (this.subscription?.plan_limits?.max_business === 1) {
        console.log("ssddff 1.b");
        // priority to the non loop business
        first = businesses.sort((a, b) => {
          if (a.type === b.type) return a.name.localeCompare(b.name);
          return a.type === BUSINESS_TYPES.LOOP ? 1 : -1;
        })[0];
      } else {
        // priority to the loop business
        first = businesses.sort((a, b) => {
          if (a.type === b.type) return a.name.localeCompare(b.name);
          return a.type === BUSINESS_TYPES.LOOP ? -1 : 1;
        })[0];
        console.log("ssddff 1.c", first);
      }
      console.log("ssddff 1.d");

      //if (!prev) return this.selectBusiness(first || false);
      if (!prev) this.selectBusiness(first || false);
      //if (!prev && !(this.subscription?.status === 0)) return;
      console.log("ssddff 3");

      // set userinfo, permissions
      */
      //this.setUserInfo({ business: prev });
      //await this.setUserPermission();
    }
    return this.getUserInfo();
  }

  async subscribeStep3AndReload(subscriptionId, txId, body) {
    const resp = await this.subscribeStep3(subscriptionId, txId, body);
    this.triggerAuthStateChanged();
    return resp;
  }

  getTransactions(query = {}) {
    return this.apiGet(API.GET_TRANSACTIONS, { query });
  }

  getTransactionsByCampaign(campaignId, query = {}) {
    return this.getTransactions({ campaignId, ...query });
  }

  getTransactionsByBusiness(businessId, query = {}) {
    return this.getTransactions({ businessId, ...query });
  }

  getCampaigns() {
    return this.apiGet(API.GET_CAMPAIGNS);
  }

  getBusinessCampaigns(query = {}) {
    return this.apiGet(API.GET_BUSINESS_CAMPAIGNS, { query });
  }

  getProductInfo(code) {
    const apiUrl = [TENANTS.ANCOT].includes(this.tenant)
      ? API.GET_PRODUCT_INFO_TENANT
      : API.GET_PRODUCT_INFO;

    return this.apiGet(apiUrl, { params: { "qr-code": code } });
  }

  getCampaignTerms(campaign) {
    return this.apiGet(API.GET_CAMPAIGN_TERMS, { params: { campaign } });
  }

  getCampaignReservations(campaign) {
    return this.apiGet(API.CAMPAIGN_RESERVATION, { params: { campaign } });
  }

  reserveCampaign(campaign, quantity = 1) {
    return this.apiPost(API.CAMPAIGN_RESERVATION, {
      params: { campaign },
      body: { quantity },
    });
  }

  acceptProduct(campaign) {
    return this.apiPost(API.ACCEPT_PRODUCT, { params: { campaign } });
  }

  redeemPrize(card, prize) {
    return this.apiPost(API.REDEEM_PRIZE, { params: { card, prize } });
  }

  getCampaignDetails(campaign, business) {
    return this.apiGet(API.GET_CAMPAIGN_DETAILS, {
      params: { business, campaign },
    });
  }

  getTerms() {
    return this.apiGet(API.GET_TERMS);
  }

  signTerm(terms) {
    return this.apiPut(API.SIGN_TERM, { params: { terms } });
  }

  getUsePermissionByEmail(email) {
    return this.apiPut(API.GET_USE_PERMISSION_BY_EMAIL, { body: { email } });
  }

  getRules(campaign) {
    return this.apiGet(API.GET_RULES, { params: { campaign } });
  }

  burnCoupon(code, operation_type = "", campaign_id = "") {
    return this.apiPut(API.BURN_COUPON, {
      params: { "qr-code": code },
      body: {
        business_id: this.getUserInfo().business.id,
        campaign_id,
        operation_type,
      },
    });
  }

  changeOrderStatus(orderId, status) {
    return this.apiPut(API.CHANGE_ORDER_STATUS, {
      params: { ecomorder: orderId },
      body: {
        status: status,
      },
    });
  }

  increaseCardBalance(code, business_id, total_value, expense, extra_points) {
    return this.apiPut(API.INCREASE_CARD_BALANCE, {
      params: { "qr-code": code },
      body: { business_id, expense, extra_points, total_value },
    });
  }

  decreaseCardBalance(code, total_value) {
    return this.apiPut(API.DECREASE_CARD_BALANCE, {
      params: { "qr-code": code },
      body: { business_id: this.getUserInfo().business.id, total_value },
    });
  }

  getContents() {
    return this.apiGet(API.GET_CONTENTS);
  }

  updatePolicy(terms, privacy, marketing, profiling, rules) {
    return this.apiPut(API.UPDATE_TENANT_POLICY, {
      body: {
        marketing_policy: marketing,
        privacy_policy: privacy,
        profiling_policy: profiling,
        rules,
        terms,
      },
    });
  }

  createContent(
    title,
    description,
    expiration,
    priority,
    parent_id,
    content_type
  ) {
    return this.apiPost(API.CREATE_CONTENT, {
      body: {
        description,
        expiration,
        priority,
        title,
        parent_id,
        content_type,
      },
    });
  }

  updateContent(content, title, description, expiration, priority) {
    return this.apiPut(API.UPDATE_CONTENT, {
      params: { content },
      body: {
        description,
        expiration,
        priority,
        title,
      },
    });
  }

  updateContentStatus(content, status) {
    return this.apiPut(API.UPDATE_CONTENT_STATUS, {
      body: { content, status },
    });
  }

  deleteContent(content) {
    return this.apiDelete(API.DELETE_CONTENT, { params: { content } });
  }

  createOrder(values) {
    return this.apiPost(API.CREATE_ORDER, { body: values });
  }

  checkOrder(order, status) {
    return this.apiPut(API.CHECK_ORDER, { params: { order, status } });
  }

  getInventories() {
    return this.apiGet(API.GET_INVENTORIES);
  }

  uploadContentRequest(formData) {
    return this.apiGet(API.GET_UPLOAD_CONTENTS).then((res) =>
      this.apiPost(res.upload_url, { body: formData, headers: {}, json: false })
    );
  }

  getBusiness(business) {
    return this.apiGet(API.BUSINESS, { params: { business } });
  }

  getDeletedBusiness(business) {
    return this.apiGet(API.DELETED_BUSINESS, { params: { business } });
  }

  deleteBusiness(business) {
    return this.apiDelete(API.BUSINESS, { params: { business } }).then(() =>
      this.triggerAuthStateChanged()
    );
  }

  saveBusiness(values, business) {
    return (business
      ? this.apiPut(API.BUSINESS, { params: { business }, body: values })
      : this.apiPost(API.CREATE_BUSINESS, {
          body: {
            ...values,
            subscription_id: this.subscription ? this.subscription.id : "",
          },
        })
    ).then(() => this.triggerAuthStateChanged());
  }

  getTenantCampaigns() {
    return this.apiGet(API.GET_TENANT_CAMPAIGNS);
  }

  getAllTenantCampaigns() {
    return this.apiGet(API.GET_ALL_TENANT_CAMPAIGNS);
  }

  queryPointsUsers(values) {
    return this.apiPost(API.QUERY_POINTS_USERS, { body: values });
  }

  getCampaign(campaign) {
    return this.plainGet(API.GET_CAMPAIGN, { params: { campaign } });
  }

  getEventsByCampaign(main) {
    return this.plainGet(API.GET_EVENTS, { query: { main } });
  }

  getEventsByBusiness(business) {
    return this.plainGet(API.GET_EVENTS, { query: { business } });
  }

  saveCampaign(values, campaign) {
    return campaign
      ? this.apiPut(API.CAMPAIGN_EDIT, { params: { campaign }, body: values })
      : this.apiPost(API.CAMPAIGN_CREATE, { body: values });
  }

  deleteCampaign(campaign) {
    return this.apiDelete(API.CAMPAIGN_EDIT, { params: { campaign } });
  }

  issue(campaign, body) {
    return this.apiPost(API.ISSUE_PRODUCT, { params: { campaign }, body });
  }

  issueCampaign(
    campaign,
    {
      user = "",
      quantity = 0,
      value = 0,
      manager_id = "",
      recharge_card = false,
      win_id = "",
    } = {}
  ) {
    return this.issue(campaign, {
      user,
      quantity,
      value,
      manager_id,
      recharge_card,
      win_id,
    });
  }

  acceptReservation(campaign, reservation_id) {
    return this.issue(campaign, { reservation_id });
  }

  rejectReservation(reservation) {
    return this.apiDelete(API.RESERVATION, { params: { reservation } });
  }

  getUserProducts(query) {
    return this.apiGet(API.GET_PRODUCTS, { query });
  }

  getUserInspectionProducts(query, params) {
    return this.apiGet(API.GET_PRODUCTS, { query, params });
  }

  getUserProductsByStatus(status) {
    return this.getUserProducts({ status });
  }

  getUserProductsByCampaign(campaignId) {
    return this.getUserProducts({ campaignId });
  }

  getUserProductsStats() {
    return this.apiGet(API.GET_PRODUCTS_STATS);
  }

  createPermissionCode(product) {
    return this.apiGet(API.CREATE_PERMISSION, { params: { product } });
  }

  createMultiPermissionCode(products, permission_id = "") {
    return this.apiPost(API.CREATE_MULTI_PERMISSION, {
      body: { products, permission_id },
    });
  }

  requestAccountDelete() {
    return this.apiPost(API.SUPPORT_EMAIL, {
      body: {
        content: `Richiesta di oblio da parte dell'utente ${
          this.getUserInfo().token_info.email
        }`,
        reply_to: this.getUserInfo().token_info.email,
      },
    });
  }

  getExternalCode(product) {
    return this.apiGet(API.GET_EXTERNAL_CODE, { params: { product } }).then(
      ({ Code }) => Code
    );
  }

  getUser(user) {
    return this.apiGet(API.GET_USER, { params: { user } });
  }

  getUserTenantInfo(user) {
    return [TENANTS.ANCOT].includes(this.tenant) && this.isConsumer()
      ? this.apiGet(API.USER_INFO, { params: { user } })
      : {};
  }

  getMe() {
    return this.apiGet(API.ME)
      .catch(() => this.clean())
      .then((me) => {
        // TODO: introduce a permission to use the manager area
        /*if (me?.role === ROLES.USER && this.isManager()) {
          const userInfo = this.getUserInfo();
          if (
            (!userInfo.permissions || userInfo.permissions.length === 0) &&
            (!userInfo.subPermissions || userInfo.subPermissions.length === 0)
          )
            throw new BmapiError(ERRORS.MANAGER_ONLY, this.settings);
        }*/
        this.setUserInfo({
          role: me?.role,
          email: me?.email,
          user_id: me?.id,
        });
        this.user = me;
        return me;
      });
  }

  updateLcngUser(rules, privacy, direct_marketing, indirect_marketing, user) {
    return this.apiPut(API.UPDATE_LCNG_USER, {
      params: { user },
      body: { direct_marketing, indirect_marketing, privacy, rules },
    })
      .then(() => this.loadUserData())
      .then(() => this.triggerAuthStateChanged());
  }

  refreshUserData(user) {
    return this.loadUserData(user).then(() => this.triggerAuthStateChanged());
  }

  loadUserData(user) {
    return Promise.all([this.getUser(user), this.getUserTenantInfo(user)])
      .then(([data, info]) => {
        this.userData = data;
        this.userInfo = info;
        return data;
      })
      .catch(() => this.clean());
  }

  isTenantManager() {
    return this.getUserInfo().role === ROLES.TENANT_MANAGER;
  }

  isSubscriptionManager() {
    const perms = this.getUserInfo().subPermissions || [];
    return perms.includes(PERMISSIONS.MANAGE_SUBSCRIPTION);
  }

  canManageLoop() {
    const perms = this.getUserInfo().subPermissions || [];
    return (
      this.isTenantManager() || perms.includes(PERMISSIONS.MANAGE_SUBSCRIPTION)
    );
  }

  hasExceptions() {
    return (
      this.tenant !== TENANTS.MYSARCA ||
      this.user?.email?.includes(DOMAIN_WILDCARD)
    );
  }

  loopAvailable() {
    return (
      this.canManageLoop() &&
      (!this.subscription || this.subscription?.plan_limits?.loop_campaigns)
    );
  }

  canGoToAdmin() {
    const perms = this.getUserInfo().subPermissions || [];
    /*console.log(
      "XXXXXXXXXXXXX isManagerEnabled=",
      this.isManagerEnabled,
      ", isTenantManager=",
      this.isTenantManager(),
      "perms=",
      perms
    );*/
    return (
      this.isManagerEnabled &&
      (this.isTenantManager() ||
        perms.includes(PERMISSIONS.MANAGE_SUBSCRIPTION) ||
        perms.includes(PERMISSIONS.BUSINESS_MANAGER))
    );
  }

  upload(url, file, params, query, fields) {
    const formData = new FormData();
    formData.append("file", file);
    if (fields) {
      fields.map((field) => {
        formData.append(field.key, field.value);
      });
    }

    return this.apiPost(url, {
      params,
      query,
      body: formData,
      headers: {},
      json: false,
    });
  }

  uploadProfilePicture(file) {
    return this.upload(API.UPLOAD_AVATAR, file)
      .then(() => this.loadUserData())
      .then(() => this.triggerAuthStateChanged());
  }

  uploadCampaignImage(campaign, file, query) {
    return this.upload(API.UPLOAD_CAMPAIGN_IMAGE, file, { campaign }, query);
  }

  uploadCampaignCover(campaign, file) {
    return this.uploadCampaignImage(campaign, file);
  }

  uploadCampaignIcon(campaign, file) {
    return this.uploadCampaignImage(campaign, file, { type: "avatar" });
  }

  subscribe(body) {
    return this.apiPost(API.SUBSCRIPTIONS, { body });
  }

  subscribeStep2(subscriptionId, query) {
    return this.apiGet(API.SUBSCRIPTIONS_STEP2, {
      params: { subscriptionId },
      query,
    });
  }

  subscribeStep1(body) {
    return this.apiPost(API.SUBSCRIPTIONS_STEP1, { body });
  }

  subscribeStep3(subscriptionId, txId, body) {
    return this.apiPost(API.SUBSCRIPTIONS_STEP3, {
      body: body,
      params: { subscriptionId, txId },
    });
  }

  subscriptionTxMessage(subscriptionId, txId, message) {
    return this.apiPost(API.SUBSCRIPTIONS_TX_MESSAGE, {
      body: { text: message },
      params: { subscriptionId, txId },
    });
  }

  getSubscriptionStats() {
    return this.apiGet(API.SUBSCRIPTIONS_BS_STATS);
  }

  getSubscriptions(query = {}) {
    return this.apiGet(
      !this.user ? API.SUBSCRIPTIONS_PUBLIC : API.SUBSCRIPTIONS,
      { query: { ...query, tenantId: this.settings.tenantId } }
    );
  }

  // TODO: DELETE
  getSubscriptionsById(id) {
    return this.getSubscriptions({ id });
  }

  getSubscriptionsByUser(userId) {
    return this.getSubscriptions({
      userId: userId || this.getUserInfo().user_id,
    });
  }

  // TODO: DELETE
  getSubscriptionsBySlug(friendlyUrl = this.subscriptionSlug, excludeId) {
    console.log(
      "LOADING, bmapi.js: BmarkenAPI getSubscriptionsBySlug subscription not DEV SUBSCRIPTION, invoking subscriptions friendlyUrl=",
      friendlyUrl,
      "excludeId=",
      excludeId
    );
    return this.getSubscriptions({ friendlyUrl, excludeId });
  }

  updateSubscription(subId, body) {
    return this.apiPut(API.SUBSCRIPTION, {
      body: body,
      params: { subscription: subId },
    });
  }

  doInstantPlay(product) {
    return this.apiPost(API.PLAY_IW, { params: { product } });
  }

  getLoops() {
    return this.apiGet(API.LOOPS, {
      query: this.subscription ? { subscriptionId: this.subscription.id } : {},
    });
  }

  saveLoop(values, loop) {
    const body = { ...values, subscription_id: this.subscription?.id || "" };
    return loop
      ? this.apiPut(API.LOOP, { params: { loop }, body })
      : this.apiPost(API.LOOPS, { body });
  }

  deleteLoop(loop) {
    return this.apiDelete(API.LOOP, { params: { loop } });
  }

  getLoopBusinesses(loop) {
    return this.apiGet(API.LOOP_BUSINESSES, { params: { loop } });
  }

  addLoopBusiness(business, loop) {
    return this.apiPut(API.LOOP_BUSINESS, { params: { business, loop } });
  }

  deleteLoopBusiness(business, loop) {
    return this.apiDelete(API.LOOP_BUSINESS, { params: { business, loop } });
  }

  getBanner() {
    return this.apiGet(API.BANNERS, {
      query: this.subscription ? { subscription: this.subscription.id } : {},
    });
  }

  getWinners(campaignId) {
    return this.apiGet(API.WINS, campaignId ? { query: { campaignId } } : {});
  }

  getWinDetails(win) {
    return this.apiGet(API.WIN, { params: { win } });
  }

  setWinStatus(values, win) {
    return this.apiPut(API.WIN_STATUS, { params: { win }, body: values });
  }

  processStamp(stamp) {
    return this.apiPost(API.PROCESS_STAMP, {
      body: { stamp },
    });
  }

  getMessages() {
    return this.apiGet(API.MESSAGES);
  }

  getUnreadMessages() {
    return this.apiGet(API.MESSAGES, {
      query: { status: MESSAGE_STATUS.UNREAD },
    });
  }

  getMessage(message) {
    return this.apiGet(API.MESSAGE, { params: { message } });
  }

  markAsRead(message) {
    return this.apiPut(API.MESSAGE, {
      params: { message },
      body: { status: MESSAGE_STATUS.READ },
    });
  }

  getReimbursement(query) {
    return this.apiGet(API.TRANSACTIONS, {
      query: { reimbursement: true, ...query },
    });
  }

  getMallInventories() {
    return this.apiGet(API.MALL_INVENTORIES);
  }

  assignMallInventory(business_id, inventory_id) {
    return this.apiPut(API.MALL_INVENTORY_ASSIGN, {
      body: {
        business_id,
        inventory_id,
        subscription_id: this.subscription ? this.subscription.id : "",
      },
    });
  }

  getMallCouponStats(business) {
    return this.apiGet(API.MALL_COUPON_STATS, { params: { business } });
  }

  unJoinCampaign(campaign, business) {
    return this.apiPut(API.UNJOIN, { params: { business, campaign } });
  }

  setJoinType(campaign, joinType) {
    return this.apiPut(API.SETJOINTYPE, {
      params: { campaign },
      body: { join_type: joinType },
    });
  }

  getProductPermissions(product) {
    return this.apiGet(API.PRODUCT_PERMISSIONS, { params: { product } });
  }

  disableProductPermissions(code) {
    return this.apiPut(API.DISABLE_PRODUCT_PERMISSIONS, {
      params: { "qr-code": code },
    });
  }

  getSendProductPermission(product) {
    return this.apiGet(API.SEND_PERMISSION, { params: { product } });
  }

  receiveProduct(code) {
    return this.apiPost(API.RECEIVE_PRODUCT, { params: { "qr-code": code } });
  }

  getPermission(id) {
    return this.apiGet(API.PERMISSION, { params: { id } });
  }

  checkManagerRegistration(permission) {
    return this.apiGet(API.CHECK_MANAGER, { params: { permission } });
  }

  getCategories(campaign) {
    return this.apiGet(API.CATEGORIES, { params: { campaign } });
  }

  requestVoid(email) {
    return this.apiPost(API.VOID, { body: { email } });
  }

  deleteProducts(campaign, email) {
    return this.apiDelete(API.CAMPAIGN_PRODUCTS, {
      params: { campaign },
      body: email ? { email } : {},
    });
  }

  createSubscriptionsManager(email, permission) {
    return this.apiPost(API.SUBSCRIPTIONS_MANAGER, {
      body: {
        business_id: "",
        client_id: this.settings.clientId,
        email: email.trim(),
        permission,
        subscription_id: this.subscription ? this.subscription.id : "",
      },
    });
  }

  deleteSubscriptionPermission(permission) {
    return this.apiDelete(API.SUBSCRIPTIONS_PERMISSION, {
      params: { permission, subscription: this.subscription.id },
    });
  }

  getTassonomy(word) {
    // return this.apiGet(API.????, { query: { word } });
    return Promise.resolve({ text: `Significato di ${word}` });
  }

  getSubscriptionPlans() {
    const params = { type: "SUBSCRIPTION", status: "ACTIVE" };
    return this.apiGet(API.PAYMENTS_ITEMS, { query: params });
  }

  getSubscriptionPrice(plan_id, billingPeriod, storeQuantity, discountId) {
    return this.apiPost(API.SUBSCRIPTIONS_PRICES, {
      body: {
        store_quantity: storeQuantity,
        number_of_months: billingPeriod,
        item_id: plan_id,
        discount_id: discountId,
      },
    });
  }

  getSubscriptionDiscount(discountCode) {
    const params = {
      type: "DISCOUNT",
      status: "ACTIVE",
      itemKey: discountCode,
    };
    return this.apiGet(API.PAYMENTS_ITEMS, { query: params });
  }
  /*
  getPaymentPayload(txId) {
    return this.apiGet(API.PAYMENTS_PAYLOAD, { params: { txId: txId } });
  }*/

  createPOI(poi) {
    return this.apiPost(API.POI_CREATE, {
      body: {
        ...poi,
      },
    });
  }

  updatePOI(poi) {
    return this.apiPut(API.POI_EDIT, {
      params: {
        poi: poi.id,
      },
      body: {
        ...poi,
      },
    });
  }

  deletePOI(poiId) {
    return this.apiDelete(API.POI_DELETE, {
      params: {
        poi: poiId,
      },
    });
  }

  getPOIs(params) {
    return this.apiGet(API.POI_GET, { query: params });
  }

  createTour(tour) {
    return this.apiPost(API.TOUR_CREATE, {
      body: {
        ...tour,
      },
    });
  }

  updateTour(tour) {
    return this.apiPut(API.TOUR_EDIT, {
      params: {
        tour: tour.id,
      },
      body: {
        ...tour,
      },
    });
  }

  deleteTour(tourId) {
    return this.apiDelete(API.TOUR_DELETE, {
      params: {
        tour: tourId,
      },
    });
  }

  getTours(params) {
    return this.apiGet(API.TOUR_GET, { query: params });
  }

  createQuestion(question) {
    return this.apiPost(API.QUESTION_CREATE, {
      params: {
        poi: question.poi_id,
      },
      body: {
        ...question,
      },
    });
  }

  deleteQuestion(questionId) {
    return this.apiDelete(API.QUESTION_DELETE, {
      params: {
        question: questionId,
      },
    });
  }

  updateQuestion(question) {
    return this.apiPut(API.QUESTION_EDIT, {
      params: {
        poi: question.poi_id,
        question: question.id,
      },
      body: {
        ...question,
      },
    });
  }

  getQuestions(params) {
    return this.apiGet(API.QUESTION_GET, { query: params });
  }

  createGlossaryKeyword(keyword) {
    return this.apiPost(API.GLOSSARY_KEYWORD_CREATE, {
      body: {
        ...keyword,
      },
    });
  }

  deleteGlossaryKeyword(keywordId) {
    return this.apiDelete(API.GLOSSARY_KEYWORD_DELETE, {
      params: {
        keyword: keywordId,
      },
    });
  }

  updateGlossaryKeyword(keyword) {
    return this.apiPut(API.GLOSSARY_KEYWORD_EDIT, {
      params: {
        keyword: keyword.id,
      },
      body: {
        ...keyword,
      },
    });
  }

  getGlossary(params) {
    return this.apiGet(API.GLOSSARY_GET, { query: params });
  }

  getGlossaryKeyword(params) {
    return this.apiGet(API.GLOSSARY_KEYWORD_GET, { params: params });
  }

  getGlossaryKeywordQuery(params) {
    return this.apiGet(API.GLOSSARY_KEYWORD_QUERY_GET, { query: params });
  }

  createEProductCategory(category) {
    return this.apiPost(API.EPRODUCT_CATEGORY_CREATE, {
      body: {
        ...category,
      },
    });
  }

  updateCategory(category) {
    return this.apiPut(API.EPRODUCT_CATEGORY_EDIT, {
      params: {
        ecomcategory: category.id,
      },
      body: {
        ...category,
      },
    });
  }

  getEProductCategories(params) {
    return this.apiGet(API.EPRODUCT_CATEGORY_GET, { query: params });
  }

  createEProduct(product) {
    return this.apiPost(API.EPRODUCT_CREATE, {
      body: {
        ...product,
      },
    });
  }

  updateProduct(product) {
    return this.apiPut(API.EPRODUCT_EDIT, {
      params: {
        ecomitem: product.id,
      },
      body: {
        ...product,
      },
    });
  }

  getEProduct(params) {
    return this.apiGet(API.EPRODUCT_GET, { query: params });
  }

  createEOrder(order) {
    return this.apiPost(API.EORDER_CREATE, {
      body: {
        ...order,
      },
    });
  }

  getSlot(time) {
    return this.apiPost(API.GETSLOT, {
      body: {
        ...time,
      },
    });
  }

  updateOrder(order) {
    return this.apiPut(API.EORDER_EDIT, {
      params: {
        ecomorder: order.id,
      },
      body: {
        ...order,
      },
    });
  }

  updateOrderStatus(order) {
    return this.apiPut(API.EORDER_STATUS_EDIT, {
      params: {
        ecomorder: order.id,
      },
      body: {
        ...order,
      },
    });
  }

  updateOrdersListStatus(ordersStatus) {
    return this.apiPut(API.EORDERS_LIST_STATUS_EDIT, {
      body: ordersStatus,
    });
  }

  /*getEOrder(params) {
    return this.apiGet(API.EORDER_GET, { query: params });
  }*/

  /*getEOrder(params) {
    let config = { query: params };
    //return this.apiGet(API.EORDER_GET, { query: params });
    return this.apiFetchMultipleQueryParams(API.EORDER_GET, {
      ...config,
      method: HTTP.GET,
    });
  }*/

  getEOrder(params) {
    return this.apiGet(API.EORDER_GET, { query: params });
  }

  getEOrdersResume(params) {
    return this.apiGet(API.EORDERS_RESUME_GET, { query: params });
  }

  getUserPhone(params) {
    return this.apiGet(API.USER_PHONE_GET, { params: params });
  }

  createClosingDates(dates) {
    return this.apiPost(API.CLOSING_DATES_CREATE, {
      body: {
        ...dates,
      },
    });
  }

  getClosingDates(params) {
    return this.apiGet(API.CLOSING_DATES_GET, { query: params });
  }

  createEcomConf(conf, bs) {
    return this.apiPost(API.ECOM_CONF_CREATE, {
      body: {
        ...conf,
      },
      params: {
        business: bs,
      },
    });
  }

  updateEcomConf(conf, bs) {
    return this.apiPut(API.ECOM_CONF_EDIT, {
      params: {
        conf: conf.id,
        business: bs,
      },
      body: {
        ...conf,
      },
    });
  }

  deleteEcomConf(confId, bs) {
    return this.apiDelete(API.ECOM_CONF_DELETE, {
      params: {
        conf: confId,
        business: bs,
      },
    });
  }

  getEcomConf(params) {
    return this.apiGet(API.ECOM_CONF_GET, { params: params });
  }

  createEmployeeAccess(reg, businessId) {
    return this.apiPost(API.EMPLOYEE_ACCESS_CREATE, {
      params: {
        business: businessId,
        type: "access",
      },
      body: {
        ...reg,
      },
    });
  }

  deleteEmployeeAccess(businessId, userId) {
    return this.apiDelete(API.EMPLOYEE_ACCESS_DELETE, {
      params: {
        business: businessId,
        type: "access",
        id: userId,
      },
    });
  }

  accessRegistration(qr, businessId) {
    return this.apiPost(API.ACCESS_REGISTRATION, {
      params: {
        business: businessId,
        type: "access",
      },
      body: {
        ...qr,
      },
    });
  }

  getRegistrations(params, status) {
    return this.apiGet(API.REGISTRATION_GET, { params: params, query: status });
  }

  authorizeEmployeeAccess(id, status) {
    return this.apiPost(API.EMPLOYEE_ACCESS_AUTHORIZE, {
      params: {
        status: status,
      },
      body: id,
    });
  }

  /*getAccessRegistration(params) {
    return this.apiGet(API.ACCESS_REGISTRATION_GET, { params: params });
  }*/

  addMediaContent(id, mediaContent, file, entity_type, business) {
    let params = {};
    if (business) {
      params = { entity: id, business: id };
    } else {
      params = { entity: id };
    }
    const query = {};
    const fields = [
      { key: "id", value: mediaContent.id },
      { key: "lang", value: mediaContent.lang },
      { key: "url", value: mediaContent.url },
      { key: "external", value: mediaContent.external },
      { key: "title", value: mediaContent.title },
      { key: "media_type", value: mediaContent.media_type },
      { key: "media_subtype", value: mediaContent.media_subtype }, //
      //{ key: "parameters", value: mediaContent.parameters }, //
      { key: "configuration", value: mediaContent.configuration }, //
      { key: "crop_mode", value: mediaContent.crop_mode },
      { key: "priority", value: mediaContent.priority },
      { key: "entity_type", value: entity_type },
      { key: "attribution_link", value: mediaContent.attribution_link },
      { key: "attribution_text", value: mediaContent.attribution_text },
      { key: "alternative_text", value: mediaContent.alternative_text },
    ];
    return this.upload(API.MEDIACONTENT_CREATE, file, params, query, fields);
  }

  updateMediaContent(
    id,
    mediaContent,
    file,
    entity_type,
    mediaContentId,
    business
  ) {
    let params = {};
    if (business) {
      params = { entity: id, media: mediaContentId, business: id };
    } else {
      params = { entity: id, media: mediaContentId };
    }
    const query = {};
    const fields = [
      { key: "id", value: mediaContent.id },
      { key: "lang", value: mediaContent.lang },
      { key: "url", value: mediaContent.url },
      { key: "external", value: mediaContent.external },
      { key: "title", value: mediaContent.title },
      { key: "media_type", value: mediaContent.media_type },
      { key: "media_subtype", value: mediaContent.media_subtype }, //
      //{ key: "parameters", value: mediaContent.parameters }, //
      { key: "configuration", value: mediaContent.configuration }, //
      { key: "crop_mode", value: mediaContent.crop_mode },
      { key: "priority", value: mediaContent.priority },
      { key: "entity_type", value: entity_type },
      { key: "attribution_link", value: mediaContent.attribution_link },
      { key: "attribution_text", value: mediaContent.attribution_text },
      { key: "alternative_text", value: mediaContent.alternative_text },
    ];
    return this.upload(API.MEDIACONTENT_UPDATE, file, params, query, fields);
  }

  addSubscriptionMediaContent(id, mediaContent, file) {
    const params = { entity: id };
    const query = {};
    const fields = [
      { key: "id", value: mediaContent.id || "" },
      { key: "lang", value: mediaContent.lang || "" },
      { key: "url", value: mediaContent.url || "" },
      { key: "external", value: mediaContent.external || false },
      { key: "title", value: mediaContent.title || "" },
      { key: "media_type", value: mediaContent.media_type || "" },
      { key: "crop_mode", value: mediaContent.crop_mode || "" },
      { key: "priority", value: mediaContent.priority || 0 },
    ];
    return this.upload(
      API.SUBSCRIPTION_MEDIACONTENT_CREATE,
      file,
      params,
      query,
      fields
    );
  }

  deleteMediaContent(id, mediaContentId, lang, entity_type, business) {
    let newParams = {};
    if (business) {
      newParams = {
        entity: id,
        media: mediaContentId,
        lang: lang,
        business: id,
      };
    } else {
      newParams = { entity: id, media: mediaContentId, lang: lang };
    }
    return this.apiDelete(API.MEDIACONTENT_DELETE, {
      /*params: {
        entity: id,
        media: mediaContentId,
        lang: lang,
      },*/
      params: newParams,
      body: {
        entity_type: entity_type,
      },
    });
  }

  deleteSubscriptionMediaContent(id, mediaContentId) {
    return this.apiDelete(API.SUBSCRIPTION_MEDIACONTENT_DELETE, {
      params: {
        entity: id,
        media: mediaContentId,
      },
    });
  }

  getCampaignNotifications(campaign) {
    return this.apiGet(API.NOTIFICATIONS, {
      params: { campaign: campaign },
    });
  }

  saveNotificationCampaign(
    event_type = "",
    active = false,
    in_app_active = false,
    in_app_title = "",
    in_app_body = "",
    banner_active = false,
    banner_title = "",
    banner_body = "",
    push_active = false,
    push_title = "",
    push_body = "",
    push_image_url = "",
    email_active = false,
    email_subject = "",
    email_body = "",
    email_from = "",
    remind_days = 0,
    id
  ) {
    return this.apiPost(API.NOTIFICATIONS, {
      params: { campaign: id },
      body: {
        event_type,
        active,
        in_app_active,
        in_app_title,
        in_app_body,
        banner_active,
        banner_title,
        banner_body,
        push_active,
        push_title,
        push_body,
        push_image_url,
        email_active,
        email_subject,
        email_body,
        email_from,
        remind_days,
      },
    });
  }

  updateNotificationCampaign(
    event_type = "",
    active = false,
    in_app_active = false,
    in_app_title = "",
    in_app_body = "",
    banner_active = false,
    banner_title = "",
    banner_body = "",
    push_active = false,
    push_title = "",
    push_body = "",
    push_image_url = "",
    email_active = false,
    email_subject = "",
    email_body = "",
    email_from = "",
    remind_days = 0,
    campaign,
    notification_id
  ) {
    return this.apiPut(API.UPDATE_NOTIFICATION, {
      params: { campaign: campaign, notification: notification_id },
      body: {
        event_type,
        active,
        in_app_active,
        in_app_title,
        in_app_body,
        banner_active,
        banner_title,
        banner_body,
        push_active,
        push_title,
        push_body,
        push_image_url,
        email_active,
        email_subject,
        email_body,
        email_from,
        remind_days,
      },
    });
  }

  getConsentInfo(clientId /*user, campaign, consentId*/) {
    var params = { userId: clientId };
    return this.apiGet(API.CONSENT, { query: params });
  }

  getConsents() {
    var params = { clientId: this.settings.clientId };
    return this.apiGet(API.CONSENTS, { query: params });
  }

  checkExternalUrl(url) {
    let newUrl = url?.replace(
      "$$ALTERNATIVESITE$$",
      DOMAIN_CONFIG.ALTERNATIVESITE
    );
    if (this.subscriptionSlug) {
      newUrl = newUrl?.replace(
        "$$SUBSCRIPTION_SLUG$$",
        this.subscriptionSlug === "-" ? "" : this.subscriptionSlug
      );
    }
    if (this.language) {
      newUrl = newUrl?.replace("$$LANGUAGE$$", this.language);
    }
    return newUrl;
  }

  getMessagesCampaign() {
    return this.apiGet(API.BUSINESS_MESSAGES);
  }

  saveMessageCampaign(
    title = "",
    body = "",
    banner = false,
    banner_close_mode = false,
    banner_close_date = format(new Date(), "yyyy-MM-dd"),
    banner_close_number = 1,
    push_notification = false,
    expire_at = "",
    app_link_label = "",
    app_link_uri = "",
    image_url = "",
    link_uri = "",
    campaign_id,
    client_id
  ) {
    console.log(banner_close_mode, banner_close_date, banner_close_number);
    return this.apiPost(API.BUSINESS_MESSAGES, {
      body: {
        title,
        body,
        banner,
        banner_close_mode,
        banner_close_date,
        banner_close_number,
        push_notification,
        expire_at,
        app_link_label,
        app_link_uri,
        image_url,
        link_uri,
        campaign_id,
        client_id,
      },
    });
  }

  updateMessageCampaign(
    title = "",
    body = "",
    banner = false,
    banner_close_mode = false,
    banner_close_date = format(new Date(), "yyyy-MM-dd"),
    banner_close_number = 1,
    push_notification = false,
    expire_at = "",
    app_link_label = "",
    app_link_uri = "",
    image_url = "",
    link_uri = "",
    messageId,
    client_id,
    campaign_id
  ) {
    console.log(banner_close_mode, banner_close_date, banner_close_number);
    return this.apiPut(API.BUSINESS_MESSAGE, {
      params: { message: messageId },
      body: {
        title,
        body,
        banner,
        banner_close_mode,
        banner_close_date,
        banner_close_number,
        push_notification,
        expire_at,
        app_link_label,
        app_link_uri,
        image_url,
        link_uri,
        client_id,
        campaign_id,
      },
    });
  }

  removeMessageCampaign(message) {
    return this.apiDelete(API.BUSINESS_MESSAGE, {
      params: { message: message },
    });
  }

  getFirebaseMessaging() {
    return this.firebase.messaging;
  }

  setFirebaseMessaginNotificationHandler() {
    if (this.firebase.messaging != null) {
      if (this.unsubMessaging != undefined) {
        this.unsubMessaging();
      }

      this.unsubMessaging = this.firebase.messaging.onMessage(
        (notification) => {
          //todo: controllare tipo di notifica e chiamare il callback relativo al tipo (se presente)
          //per ora chiamiamo solo il callback delle notifiche per gli ordini
          if (this.orderNotificationCallback !== null) {
            this.orderNotificationCallback(notification);
          } else {
            console.log(
              "[Error]: Notification received without proper callback initialized"
            );
          }
        },
        (e) => {
          console.log(e);
        }
      );
    }
  }

  async getFirebaseMessagingToken() {
    if (this.firebase.messaging != null) {
      const swFile =
        process.env.PUBLIC_URL + "/sw/" + this.settings.firebase + ".js";
      //console.log("SWFILE: ", swFile);
      const swRegistration = await navigator.serviceWorker.register(swFile);
      //console.log("swRegistration: ", swRegistration);
      return this.firebase.messaging.getToken({
        serviceWorkerRegistration: swRegistration,
        vapidKey: this.firebase.messagingApiKey,
      });
    } else {
      return null;
    }
  }

  setOrderNotificationCallback(callback) {
    this.orderNotificationCallback = callback;
  }

  saveMessagingToken(type, token) {
    return this.apiPost(API.SAVE_MESSAGING_TOKEN, {
      body: {
        business_id: this.getUserInfo().business.id,
        type: type,
        token: token,
      },
    });
  }

  createEventAction(event) {
    return this.apiPost(API.EVENT_ACTION_CREATE, {
      body: {
        ...event,
      },
    });
  }

  getEventActions(params) {
    return this.apiGet(API.EVENT_ACTION_GET, { query: params });
  }

  getEventActionById(params) {
    return this.apiGet(API.EVENT_ACTION_BY_ID_GET, { params: params });
  }

  updateEventAction(event) {
    return this.apiPut(API.EVENT_ACTION_EDIT, {
      params: {
        event: event.id,
      },
      body: {
        ...event,
      },
    });
  }

  deleteEventAction(eventId) {
    return this.apiDelete(API.EVENT_ACTION_DELETE, {
      params: {
        event: eventId,
      },
    });
  }

  createSubscription(sub) {
    return this.apiPost(API.SUBSCRIPTION_CREATE, {
      body: {
        ...sub,
      },
    });
  }

  getAdminSubscriptions(params) {
    return this.apiGet(API.SUBSCRIPTIONS_GET, { query: params });
  }

  createConsent(consent) {
    return this.apiPost(API.CONSENT_CREATE, {
      body: {
        ...consent,
      },
    });
  }

  getConsent(params) {
    return this.apiGet(API.CONSENT_GET, { query: params });
  }

  updateConsent(consent) {
    return this.apiPut(API.CONSENT_EDIT, {
      params: {
        consent: consent.id,
      },
      body: {
        ...consent,
      },
    });
  }

  deleteConsent(consentId) {
    return this.apiDelete(API.CONSENT_DELETE, {
      params: {
        consent: consentId,
      },
    });
  }

  getTenantTestConfig() {
    return this.apiGet(API.TENANT_TEST_CONFIG);
  }

  updateTenantTestConfig(testConfig) {
    return this.apiPut(API.TENANT_TEST_CONFIG, {
      body: {
        ...testConfig,
      },
    });
  }

  cleanPlayByProduct(productId) {
    return this.apiPost(API.TENANT_TEST_CONFIG_CLEANPLAY_BYPRODUCT, {
      params: {
        product: productId,
      },
    });
  }

  cleanTodayPlays(email) {
    return this.apiPost(API.TENANT_TEST_CONFIG_CLEANTODAYPLAYS, {
      params: {
        email: email,
      },
    });
  }

  getProduct(productId) {
    return this.apiGet(API.GET_PRODUCT, { params: { product: productId } });
  }

  getCheckins(params) {
    return this.apiGet(API.CHECKINS_GET, { query: params });
  }

  /*getCheckinDownload(params) {
    return this.apiGet(API.CHECKIN_DOWNLOAD_GET, { query: params });
  }*/

  checkinDowload(pw, params) {
    return this.apiPost(API.CHECKIN_DOWNLOAD, {
      query: {
        ...params,
      },
      body: {
        ...pw,
      },
    });
  }

  getCustomerInfo(params) {
    // id or email
    return this.apiGet(API.CUSTOMER_INFO_GET, { params: params });
  }

  getClients(params) {
    return this.apiGet(API.CLIENTS_GET, { query: params });
  }

  sendPushNotification(notification) {
    return this.apiPost(API.SEND_PUSH_NOTIFICATION, {
      body: {
        ...notification,
      },
    });
  }

  getTasks(params) {
    return this.apiGet(API.TASKS_GET, { query: params });
  }

  getTaskStats(params) {
    return this.apiGet(API.TASK_STATS_GET, { params: params });
  }

  getTaskErrors(params) {
    return this.apiGet(API.TASK_ERRORS_GET, { params: params });
  }

  subscriptionLookup(tenant, friendlyUrl) {
    return this.apiGet(API.SUBSCRIPTION_LOOKUP, {
      params: {
        tenant,
        friendlyUrl,
      },
    });
  }

  subscriptionLookupFast(friendlyUrl) {
    return this.apiGet(API.SUBSCRIPTION_LOOKUP_FAST, {
      params: {
        tenant: this.settings.globalTenantId,
        friendlyUrl,
      },
    });
  }

  getUsersByParams(params) {
    return this.apiGet(API.USERS_GET, { query: params });
  }

  createDistributionList(list) {
    return this.apiPost(API.CREATE_DISTRIBUTION_LIST, {
      body: {
        ...list,
      },
    });
  }

  updateDistributionList(list) {
    return this.apiPut(API.UPDATE_DISTRIBUTION_LIST, {
      params: {
        userlist: list.id,
      },
      body: {
        ...list,
      },
    });
  }

  getDistributionLists(params) {
    return this.apiGet(API.DISTRIBUTION_LISTS_GET, { query: params });
  }

  getDistributionList(params) {
    return this.apiGet(API.DISTRIBUTION_LIST_GET, { params: params });
  }

  getSubscription(subscription) {
    console.log("zzzzxxx", subscription);
    return this.apiGet(API.SUBSCRIPTION, {
      params: { subscription },
    });
  }

  getFairs(params) {
    return this.apiGet(API.FAIRS_GET, { query: params });
  }

  getFairsDates(params) {
    return this.apiGet(API.DATE_FAIRS_GET, { query: params });
  }

  getMerchantByType(params) {
    return this.apiGet(API.GET_MERCHANT_BY_TYPE, { params: params });
  }

  createFair(formData, tenant) {
    return this.apiPost(API.CREATE_FAIR, {
      tenant,
      body: formData,
      headers: {},
      json: false,
    });
  }

  updateFair(
    id,
    title,
    description,
    expiration,
    priority,
    type,
    custom_fields,
    business_id,
    participant_business_ids,
    operation_url,
    tenant
  ) {
    return this.apiPut(API.UPDATE_FAIR, {
      params: {
        tenant: tenant,
        content: id,
      },
      body: {
        id,
        title,
        description,
        expiration,
        priority,
        type,
        custom_fields,
        business_id,
        participant_business_ids,
        operation_url,
      },
    });
  }

  deleteFair(fairId) {
    return this.apiDelete(API.DELETE_FAIR, {
      params: {
        content: fairId,
      },
    });
  }

  updateFairStatus(id, check) {
    return this.apiPut(API.UPDATE_FAIR_STATUS, {
      params: {
        content: id,
        status: check,
      },
    });
  }

  createFairCampaigns(id) {
    return this.apiPut(API.CREATE_FAIR_CAMPAIGNS, {
      params: {
        content: id,
      },
    });
  }

  getUserStatsWithPointsCount(params) {
    return this.apiGet(API.GET_USER_POINTS_COUNT, { query: params });
  }

  getUserStatsWithPoints(params) {
    return this.apiGet(API.GET_USER_POINTS, { query: params });
  }

  getUserStats(params) {
    return this.apiGet(API.GET_USER_STATS, { query: params });
  }

  getPrizesStats(params) {
    return this.apiGet(API.GET_PRIZES_STATS, { query: params });
  }

  getUsersPrizesStats(params) {
    return this.apiGet(API.GET_USER_PRIZES, { query: params });
  }

  getMerchantsStats(params) {
    return this.apiGet(API.GET_MERCHANTS_STATS, { query: params });
  }

  getMerchantsActions(params) {
    return this.apiGet(API.GET_MERCHANTS_ACTIONS, { query: params });
  }

  getMerchantsActionsCount(params) {
    return this.apiGet(API.GET_MERCHANTS_ACTIONS_COUNT, { query: params });
  }

  getReviews(params) {
    return this.apiGet(API.GET_REVIEWS, { query: params });
  }

  updateActionStatus(id, status) {
    return this.apiPut(API.UPDATE_ACTION_STATUS, {
      params: {
        action: id,
        status: status,
      },
    });
  }

  getGenListCategoriesAdmin(params) {
    return this.apiGet(API.GET_GENLIST_CATEGORIES_ADMIN, { query: params });
  }

  updateGenListCategories(list) {
    return this.apiPut(API.UPDATE_GENLIST_CATEGORIES, {
      body: {
        ...list,
      },
    });
  }

  getGenListCategories(params) {
    return this.apiGet(API.GET_GENLIST_CATEGORIES, { query: params });
  }

  getGenListAdmin(listId, businessId) {
    return this.apiGet(API.GET_GENLIST_ADMIN, {
      params: { listId: listId },
      query: { ...(businessId != null && { business: businessId }) },
    });
  }

  updateGenListAdmin(listAllLang, listId, businessId) {
    return this.apiPut(API.UPDATE_GENLIST, {
      body: listAllLang,
      params: { listId: listId },
      query: { ...(businessId != null && { business: businessId }) },
    });
  }

  getActionByQRCode(params) {
    return this.apiGet(API.GET_ACTION_FROM_QRCODE, { params: params });
  }

  getFairByQRCode(params) {
    return this.apiGet(API.GET_FAIR_FROM_QRCODE, { params: params });
  }

  getContent(params) {
    return this.apiGet(API.GET_CONTENT, { params: params });
  }

  getBusinessRewardingAction(params) {
    return this.apiGet(API.GET_BUSINESS_REWARDING_ACTION, { params: params });
  }

  getAllRewardingActions(params) {
    return this.apiGet(API.GET_ALL_REWARDING_ACTIONS, { params: params });
  }

  cancelAction(action_id, bs) {
    return this.apiPut(API.CANCEL_ACTION, {
      params: {
        business: bs,
      },
      body: {
        action_id,
      },
    });
  }

  confirmAction(action_id, bs) {
    return this.apiPut(API.CONFIRM_ACTION, {
      params: {
        business: bs,
      },
      body: {
        action_id,
      },
    });
  }

  sendSupportEmail(mail) {
    return this.apiPost(API.SUPPORT_EMAIL, {
      body: {
        mail,
      },
    });
  }

  disableUser(user, aud) {
    return this.apiPut(API.DISABLE_USER, {
      params: {
        user: user,
        aud: aud,
      },
    });
  }

  enableUser(user, aud) {
    return this.apiPut(API.ENABLE_USER, {
      params: {
        user: user,
        aud: aud,
      },
    });
  }

  getManagerCredentialsByEmail(email) {
    return this.apiPut(API.GET_MANAGER_CREDENTIALS_BY_EMAIL, {
      body: {
        email: email,
      },
    });
  }

  deleteReservation(id) {
    return this.apiDelete(API.DELETE_RESERVATION, {
      params: {
        order: id,
      },
    });
  }

  deleteEventReservation(id) {
    return this.apiPut(API.DELETE_EVENT_RESERVATION, {
      params: {
        product: id,
      },
      body: {
        status: 1,
      },
    });
  }

  getReservationsCsv(params) {
    const res = this.apiGet(API.GET_RESERVATIONS_CSV, {
      query: params,
    });
    return res;
  }

  getCampusReservationsXls(params) {
    const res = this.apiGet(API.GET_CAMPUS_RESERVATIONS_XLS, {
      query: params,
    });
    return res;
  }

  getMainEventPassesCsv(mainEventId, params) {
    const res = this.apiGet(API.GET_MAIN_EVENT_PASSES_CSV, {
      params: {
        campaign: mainEventId,
      },
      query: params,
    });
    return res;
  }

  getCampusMainEventPassesXls(mainEventId, params) {
    const res = this.apiGet(API.GET_CAMPUS_MAIN_EVENT_PASSES_XLS, {
      params: {
        campaign: mainEventId,
      },
      query: params,
    });
    return res;
  }

  postConsentsSign(consentExpressions) {
    return this.apiPost(API.POST_CONSENTS_SIGN, {
      body: {
        ...consentExpressions,
      },
    });
  }

  getConsentsExpressions() {
    var params = { userId: this.getUserInfo().user_id, status: "0" };
    return this.apiGet(API.CONSENTS_EXPRESSIONS, { query: params });
  }

  getOperatorQrCode() {
    //return this.apiGet(API.OPERATOR_QRCODE, { params: { business } });
    return this.apiGet(API.OPERATOR_QRCODE);
  }

  getCampusMatchListCsv(params) {
    const res = this.apiGet(API.GET_CAMPUS_MATCH_LIST_CSV, {
      query: params,
    });
    return res;
  }

  getCampusCertificatesResume() {
    const res = this.apiGet(API.GET_CAMPUS_CERTIFICATES_RESUME, {});
    return res;
  }

  getCampusCertificatesResumeV2() {
    const res = this.apiGet(API.GET_CAMPUS_CERTIFICATES_RESUMEV2, {});
    return res;
  }

  getCampusCertificatesCsv(params) {
    const res = this.apiGet(API.GET_CAMPUS_CERTIFICATES_CSV, {
      query: params,
    });
    return res;
  }

  uploadCampusCertificates(params, formData) {
    const res = this.apiPost(API.UPLOAD_CAMPUS_CERTIFICATES_CSV, {
      body: formData,
      headers: {},
      json: false,
      query: params,
    });
    return res;
  }

  uploadCampusCertificatesV2(params, formData) {
    const res = this.apiPost(API.UPLOAD_CAMPUS_CERTIFICATES_CSV_V2, {
      body: formData,
      headers: {},
      json: false,
      query: params,
    });
    return res;
  }

  processUpload(params) {
    const res = this.apiPost(API.PROCESS_UPLOAD, {
      params: params,
    });
    return res;
  }

  getCheckinParticipants(params) {
    return this.apiGet(API.CHECKIN_PARTICIPANTS, {
      query: {
        ...params,
      },
    });
  }

  getCheckinWinners(params) {
    return this.apiGet(API.CHECKIN_WINNERS, {
      query: {
        ...params,
      },
    });
  }

  sendCheckins(body = {}, params) {
    return this.apiPost(API.CHECKIN_SEND, {
      query: {
        ...params,
      },
      body: {
        ...body,
      },
    });
  }

  getCheckinExchanges(params) {
    return this.apiGet(API.CHECKIN_EXCHANGES, {
      query: {
        ...params,
      },
    });
  }

  getCheckinExchangesFile(params) {
    return this.apiGet(API.CHECKIN_EXCHANGES_FILE, {
      json: false,
      query: {
        ...params,
      },
    });
  }

  updateEventActionCustomStatus(id, customStatus) {
    return this.apiPut(API.EVENT_ACTION_EDIT_CUSTOM_STAUS, {
      params: {
        event: id,
      },
      body: {
        custom_status: customStatus,
      },
    });
  }

  checkCheckinReply(body) {
    return this.apiPut(API.CHECK_CHECKINS_REPLY, {
      body: body,
    });
  }

  updateCheckinWinnerAssignment(body) {
    return this.apiPut(API.CHECKIN_WINNER_ASSIGNMENT, {
      body: body,
    });
  }
}
