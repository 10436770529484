import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import Confirm from "./Confirm";
import { useBmapi } from "../utils/bmapi-context";
import { getErrorMessageString } from "../utils/errors";
import { MenuItem, Select, FormControl, InputLabel } from "@material-ui/core";

export default function CampaignSetJoinType({
  campaign,
  open,
  onSuccess,
  onCancel,
}) {
  const {
    bmapi,
    startLoading,
    stopLoading,
    notifyError,
    notifySuccess,
  } = useBmapi();
  const intl = useIntl();

  const [selectedJoinType, setSelectedJoinType] = React.useState(
    campaign.joinType ?? ""
  );

  const handleSetJoinType = () => {
    startLoading();

    return bmapi
      .setJoinType(campaign.id, selectedJoinType ?? "")
      .then(() => {
        onSuccess();
        notifySuccess(
          intl.formatMessage(
            {
              id: "component.campaignSetJoinType.successMessage",
              defaultMessage:
                "Hai modificato il tipo di adesione alla campagna {name}",
            },
            {
              name: campaign.name,
            }
          )
        );
      })
      .catch((e) => notifyError(getErrorMessageString(e, intl)))
      .finally(stopLoading);
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    onCancel();
  };

  return (
    <Confirm
      open={open}
      onConfirm={handleSetJoinType}
      onCancel={handleCancel}
      title={campaign.name}
      text={
        <>
          <FormattedMessage
            id="component.campaignSetJoinType.confirm"
            defaultMessage="Imposta il tipo di adesione alla campagna"
          />
          <FormControl fullWidth margin="normal">
            <InputLabel shrink={true}>Tipo di adesione</InputLabel>
            <Select
              value={selectedJoinType}
              onChange={(e) => setSelectedJoinType(e.target.value)}
              displayEmpty
            >
              {bmapi.settings.joinTypes.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </>
      }
    ></Confirm>
  );
}
