import React, { useEffect } from "react";

import { useBmapi } from "../utils/bmapi-context";
import { CONSUMER_ROUTES, FEATURES } from "../utils/constants";

export default function Logout() {
  const { baseUrl, bmapi, startLoading, stopLoading } = useBmapi();

  useEffect(() => {
    startLoading();
    bmapi.logout().then(() => {
      if (bmapi.settings.urlAfterLogout) {
        console.info("redirect ##6");
        window.location.href = bmapi.checkExternalUrl(
          bmapi.settings.urlAfterLogout
        );
        return;
      }
      if (bmapi.isManager() && bmapi.can(FEATURES.UNIQUE_SIGNIN)) {
        console.info("redirect ##7");
        return (window.location.href = baseUrl + CONSUMER_ROUTES.HOME.slice(1));
      } else {
        stopLoading();
      }
    });
  }, [baseUrl, bmapi, startLoading, stopLoading]);

  return <div></div>;
}
