export function normalizeDefaultLanguage(
  default_language,
  available_languages
) {
  const dflt = default_language || "";
  const list = available_languages || [];
  console.log("wwww", list);
  if (dflt) {
    console.log("wwww1", list);
    const index = list.indexOf(dflt);
    if (index > -1) {
      console.log("wwww2", list);
      const newList = [...list];
      newList.splice(index, 1);
      return { default_language: dflt, available_languages: newList };
    }
  }
  console.log("wwww3", list);
  return { default_language: dflt, available_languages: list };
}
